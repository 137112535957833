import { cva, VariantProps } from "class-variance-authority";
import { ComponentProps, forwardRef, ReactNode } from "react";
import {
  CheckboxGroup,
  CheckboxGroupProps,
  Checkbox as CheckboxPrimitive,
  CheckboxProps,
  Label as LabelPrimitive,
} from "react-aria-components";
import checkmark from "../assets/checkmark.svg";
import { FieldLabel } from "./FieldLabel";
import { FieldText } from "./FieldText";
import { C1 } from "./Typography";

export const CheckboxGroupField = forwardRef<
  HTMLDivElement,
  Omit<CheckboxGroupProps, "children" | "className"> & {
    children?: ReactNode;
    label?: string;
    labelProps?: ComponentProps<typeof FieldLabel>;
    errorMessage?: string;
  }
>(({ label, errorMessage, labelProps, children, ...props }, ref) => {
  return (
    <CheckboxGroup {...props} className="flex flex-col" ref={ref}>
      {label && (
        <LabelPrimitive
          className="mb-1"
          isRequired={props.isRequired}
          isDisabled={props.isDisabled}
          {...labelProps}
        >
          <C1 $bold>{label}</C1>
        </LabelPrimitive>
      )}
      {children}
      {errorMessage && <FieldText intent="error">{errorMessage}</FieldText>}
    </CheckboxGroup>
  );
});

const checkboxStyles = cva(["flex", "items-center", "gap-2", "py-1"], {
  variants: {
    isDisabled: {
      true: "cursor-not-allowed [&>p]:text-neutral-300",
      false: "cursor-pointer",
    },
  },
  defaultVariants: {
    isDisabled: false,
  },
});

export const Checkbox = ({
  children,
  ...props
}: Omit<CheckboxProps, "className"> & {
  children?: ReactNode;
} & VariantProps<typeof checkboxStyles>) => {
  return (
    <CheckboxPrimitive {...props} className={checkboxStyles({ isDisabled: props.isDisabled })}>
      {({ isSelected }) => (
        <>
          {!isSelected ? (
            <span
              className={`relative size-6 shrink-0 ${props.isDisabled ? "bg-neutral-100" : "bg-neutral-200"}`}
            />
          ) : (
            <span
              className={`relative size-6 shrink-0  flex items-center justify-center ${props.isDisabled ? "bg-neutral-200" : "bg-steelblue"}`}
            >
              <img src={checkmark} alt="Check" />
            </span>
          )}
          <C1>{children}</C1>
        </>
      )}
    </CheckboxPrimitive>
  );
};
