import { LightbulbOutlined } from "@mui/icons-material";
import { cva, VariantProps } from "class-variance-authority";
import { ReactNode } from "react";

const alertStyles = cva(["pl-4", "pr-6", "py-3", "text-sm", "w-fit"], {
  variants: {
    variant: {
      blue: "bg-blue-200 text-builtgreen",
      yellow: "bg-yellow-100 text-builtgreen",
    },
  },
  defaultVariants: {
    variant: "yellow",
  },
});

export const Alert = ({
  header,
  children,
  variant = "yellow",
  icon,
}: VariantProps<typeof alertStyles> & {
  header?: ReactNode;
  children?: ReactNode;
  icon?: ReactNode;
}) => {
  return (
    <div className={alertStyles({ variant })}>
      <div className="grid grid-cols-[24px_auto] gap-2">
        <div>
          {icon}
          {!icon && <LightbulbOutlined className="text-builtgreen" />}
        </div>
        <div className="space-y-1 mt-0.5">
          {header && <div className="font-medium mb-1">{header}</div>}
          {children}
        </div>
      </div>
    </div>
  );
};
