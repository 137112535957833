import { ResponsiveBar } from "@nivo/bar";
import { useTranslation } from "react-i18next";
import { EndOfScale, LciaResults, SourceType } from "../../api/types";
import { tooltipStyles } from "../../components/Tooltip";
import { Text14 } from "../../components/TypographyOld";
import { coreIndicators, filterImpact, mergeImpact } from "../../lib/impact";
import { exists } from "../../util/commonUtil";
import { formatPrecision } from "../../util/format";
import { useImpactColors } from "./useImpactColors";

export const ImpactsByElementaryTypeChart = ({
  lcia,
  endsOfScale,
}: {
  lcia: LciaResults;
  endsOfScale: EndOfScale[];
}) => {
  const { t } = useTranslation();
  const { impacts_per_source: impactsPerSource } = lcia;
  const colors = useImpactColors();

  const scaleMap: Record<string, number> = Object.fromEntries(
    endsOfScale.map((scale) => [scale.indicator_name, scale.value]),
  );

  const getData = (types: SourceType[], category: string) => {
    const filteredImpacts = impactsPerSource.filter((impact) => types.includes(impact.type));
    const totalImpact = filterImpact(
      mergeImpact(filteredImpacts.map((x) => x.impacts_total).filter(exists)),
      coreIndicators,
    );

    return {
      elementaryType: category,
      ...Object.fromEntries(
        totalImpact.flatMap((impact) => [
          [impact.indicator_name, impact.value / scaleMap[impact.indicator_name]],
          [impact.indicator_name + "_unit", impact.unit],
          [impact.indicator_name + "_actual", impact.value],
        ]),
      ),
    };
  };

  const groups = [
    getData(["raw_materials"], t("Raw Materials")),
    getData(["energy_and_fuels"], t("Production Energy")),
    getData(["ancillary_materials"], t("Ancillary Materials")),
    getData(["waste"], t("Waste")),
    getData(["packaging"], t("Packaging")),
  ];

  if (impactsPerSource.some((x) => x.type === "other")) {
    groups.push(getData(["other"], t("Other")));
  }

  const gridAndTickValues = [-1, -0.5, 0, 0.5, 1];

  return (
    <ResponsiveBar
      data={groups}
      keys={Array.from(coreIndicators)}
      indexBy="elementaryType"
      margin={{ top: 5, bottom: 25 }}
      padding={0.1}
      innerPadding={4}
      groupMode="grouped"
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={(x) => colors[x.id]}
      axisTop={null}
      axisRight={null}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        tickValues: gridAndTickValues,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      gridYValues={gridAndTickValues}
      minValue={-1}
      maxValue={1}
      barAriaLabel={(e) => `${e.id}: ${e.formattedValue} in ${e.indexValue}`}
      enableLabel={false}
      tooltip={({ id, data }) => {
        const typedData = data as Record<string, string | number>;
        return (
          <div className={tooltipStyles()}>
            <Text14>
              {id}{" "}
              <strong>
                {formatPrecision(Number(typedData[`${id}_actual`]), 4)} {typedData[`${id}_unit`]}
              </strong>
            </Text14>
          </div>
        );
      }}
    />
  );
};
