import { useMutation, useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { saveAs } from "file-saver";
import {
  useGetDeclaration,
  usePostDeclaration,
  usePostDeclarationVerification,
} from "../api/endpoints/declarations";
import { VerifyDeclarationRequest } from "../api/types";

export const useDeclarations = ({ productId, plantId }: { productId: string; plantId: string }) => {
  const getDeclarations = useGetDeclaration();

  return useSuspenseQuery({
    queryFn: () => getDeclarations({ productId, plantId }),
    queryKey: [plantId, productId, "declarations"],
  });
};

export const useDeclarationVerification = () => {
  const postDeclarationVerification = usePostDeclarationVerification();

  return useMutation({
    mutationFn: async (params: {
      plantId: string;
      productId: string;
      data: VerifyDeclarationRequest;
    }) => {
      const response = await postDeclarationVerification.mutateAsync(params);
      saveAs(response, "EPD.pdf");
    },
  });
};

export const useRequestVerification = () => {
  const queryClient = useQueryClient();
  const postDeclaration = usePostDeclaration();

  return useMutation({
    mutationFn: postDeclaration,
    onSuccess: (_, { plantId, productId }) => {
      queryClient.invalidateQueries({ queryKey: [plantId, "products"] });
      queryClient.invalidateQueries({
        queryKey: [plantId, productId, "declarations"],
      });
    },
  });
};
