import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Document, ProductDetail } from "../../api/types";
import { SidePanel } from "../../components/SidePanel";
import { Label12, Text16 } from "../../components/TypographyOld";
import { useFilterDocumentsByPO } from "../../lib/documents";
import { EPD_NORGE_ID } from "../../lib/programOperator";
import { useDeclarations } from "../../state/declarations";
import { useDocumentComparisonPCF } from "../../state/products";
import { ViewOrDownloadDocument } from "../ViewOrDownloadDocument";

export const ComparisonDocumentsSidePanel = ({
  product1,
  product2,
  open,
  onClose,
}: {
  product1: ProductDetail;
  product2: ProductDetail;
  open: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const { mutateAsync: documentComparisonPCF, isPending } = useDocumentComparisonPCF();
  const [pcfDocument, setPcfDocument] = useState<Document | null>(null);

  // Get declarations for both products
  const { data: declaration1 } = useDeclarations({
    productId: product1.id,
    plantId: product1.plant_id,
  });

  const { data: declaration2 } = useDeclarations({
    productId: product2.id,
    plantId: product2.plant_id,
  });

  const displayDocuments1 = useFilterDocumentsByPO({
    documents: product1.documents,
    programOperatorId: declaration1 ? declaration1.program_operator_id : EPD_NORGE_ID,
  });

  const displayDocuments2 = useFilterDocumentsByPO({
    documents: product2.documents,
    programOperatorId: declaration2 ? declaration2.program_operator_id : EPD_NORGE_ID,
  });

  useEffect(() => {
    const createComparisonPcf = async () => {
      setPcfDocument(null);
      const document = await documentComparisonPCF({
        plantId: product1.plant_id,
        productIds: [product1.id, product2.id],
      });
      setPcfDocument(document);
    };

    if (open) createComparisonPcf();
  }, [documentComparisonPCF, open, product1, product2]);

  return (
    <SidePanel title={t("Documents")} open={open} onClose={onClose}>
      <div className="space-y-8">
        <div className="pt-4 space-y-2">
          <Label12>{t("Comparison Documents")}</Label12>
          <div className="border-neutral-300 flex items-center justify-between gap-2 min-h-12 hover:bg-neutral-100 -mx-4 px-4">
            <Text16 className="font-bold">{t("PCF Comparison")}</Text16>
            <div>
              {isPending && (
                <div className="flex justify-center items-center h-full">
                  <CircularProgress size="24px" />
                </div>
              )}
              {!isPending && pcfDocument && <ViewOrDownloadDocument doc={pcfDocument} />}
            </div>
          </div>
        </div>
        <div>
          <Label12>{`${product1.name} ${t("Documents")}`}</Label12>
          <div className="flex flex-col">
            {displayDocuments1.map((doc) => (
              <div
                key={doc.document.name}
                className="grid grid-cols-[1fr_auto] items-center gap-2 hover:bg-neutral-100 -mx-4 px-4"
              >
                <Text16 className="font-bold">{doc.document.name}</Text16>
                <ViewOrDownloadDocument doc={doc.document} />
              </div>
            ))}
          </div>
        </div>
        <div>
          <Label12>{`${product2.name} ${t("Documents")}`}</Label12>
          <div className="flex flex-col">
            {displayDocuments2.map((doc) => (
              <div
                key={doc.document.name}
                className="grid grid-cols-[1fr_auto] items-center gap-2 hover:bg-neutral-100 -mx-4 px-4"
              >
                <Text16 className="font-bold">{doc.document.name}</Text16>
                <ViewOrDownloadDocument doc={doc.document} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </SidePanel>
  );
};
