import { AddOutlined, CheckOutlined, CloseOutlined, DeleteOutlined } from "@mui/icons-material";
import { ComponentProps, useCallback, useMemo, useState } from "react";
import {
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Silo, SupplierMaterial } from "../../api/types";
import { Button } from "../../components/Button";
import { ModalBody, ModalFooter, ModalHeader } from "../../components/Modal";
import { TabSwitch } from "../../components/TabSwitch";
import { Tooltip, TooltipTrigger } from "../../components/Tooltip";
import { ComboBoxFieldConnected } from "../../form-components/ComboBoxFieldConnected";
import { NumberFieldConnected } from "../../form-components/NumberFieldConnected";
import { TextFieldConnected } from "../../form-components/TextFieldConnected";
import { useMaterials } from "../../state/materials";
import { useAddSilo, useRemoveSilo, useUpdateSilo } from "../../state/silos";
import { exists } from "../../util/commonUtil";

type Fields = {
  name: string;
  years: {
    year: number;
    materials: {
      supplierMaterialId: SupplierMaterial["id"] | null;
      amount: number;
    }[];
  }[];
};

const YearTab = ({
  yearIdx,
  supplierMaterialOptions,
  supplierMaterialsMap,
}: {
  yearIdx: number;
  supplierMaterialOptions: ComponentProps<typeof ComboBoxFieldConnected>["options"];
  supplierMaterialsMap: Record<SupplierMaterial["id"], SupplierMaterial>;
}) => {
  const methods = useFormContext<Fields>();
  const { t } = useTranslation();

  const {
    fields: materialFields,
    append: appendMaterial,
    remove: removeMaterial,
  } = useFieldArray<Fields>({
    control: methods.control,
    name: `years.${yearIdx}.materials`,
  });

  const canDelete = materialFields.length > 2;

  return (
    <div className="space-y-6">
      {materialFields.map((material, idx) => (
        <div key={material.id} className="grid grid-cols-[300px_140px_auto] items-start gap-6">
          <ComboBoxFieldConnected<Fields>
            name={`years.${yearIdx}.materials.${idx}.supplierMaterialId`}
            label={t("Linked supplier material")}
            options={supplierMaterialOptions.filter((m) => {
              const elementaryIdOfOtherFields = materialFields
                .map((_, otherIdx) => {
                  const id = methods.getValues(
                    `years.${yearIdx}.materials.${otherIdx}.supplierMaterialId`,
                  );
                  if (!id) return null;
                  return supplierMaterialsMap[id].elementary_id;
                })
                .filter((_, otherIdx) => otherIdx !== idx)
                .filter(exists)[0];

              const isSameElementaryId =
                !elementaryIdOfOtherFields || // Note here that if other fields have no elementary id yet, it's fine to show the supplier material option
                elementaryIdOfOtherFields === supplierMaterialsMap[m.id].elementary_id;

              const notUsedInAnotherFieldAlready = materialFields.every(
                (_, otherIdx) =>
                  otherIdx === idx ||
                  methods.getValues(`years.${yearIdx}.materials.${otherIdx}.supplierMaterialId`) !==
                    m.id,
              );

              return isSameElementaryId && notUsedInAnotherFieldAlready;
            })}
            isRequired
          />
          <NumberFieldConnected<Fields>
            name={`years.${yearIdx}.materials.${idx}.amount`}
            label={t("Amount")}
            isRequired
            inputProps={{ addonRight: "kg" }}
          />
          <div>
            <TooltipTrigger>
              <Button
                isDisabled={!canDelete}
                intent="secondary"
                danger
                onPress={() => removeMaterial(idx)}
              >
                <CloseOutlined />
              </Button>
              <Tooltip placement="bottom">
                {t("The removal of a material applies to each year")}
              </Tooltip>
            </TooltipTrigger>
          </div>
        </div>
      ))}
      <Button
        intent="secondary"
        onPress={() => appendMaterial({ supplierMaterialId: null, amount: NaN })}
      >
        <AddOutlined />
        {t("Add material")}
      </Button>
    </div>
  );
};

const YearTabLabel = ({ year, yearIdx }: { year: number; yearIdx: number }) => {
  const methods = useFormContext<Fields>();
  const materials = methods.watch(`years.${yearIdx}.materials`);
  const isValidYear =
    materials.length > 1 &&
    materials.every((m) => exists(m.supplierMaterialId) && !isNaN(m.amount));

  return (
    <span className="flex items-center gap-1">
      {year.toString()}
      {isValidYear && (
        <span className="text-neutral-500 [&_svg]:scale-75">
          <CheckOutlined fontSize="small" />
        </span>
      )}
    </span>
  );
};

export const SiloModalBody = ({
  close,
  onCreateSuccess,
  initialMaterials,
  editingSilo,
}: {
  close: () => void;
  onCreateSuccess?: () => void;
  initialMaterials?: SupplierMaterial[];
  editingSilo?: Silo;
}) => {
  const { t } = useTranslation();
  const { rawMaterials, packagingMaterials } = useMaterials();
  const supplierMaterials = useMemo(() => {
    return [
      ...[...rawMaterials, ...packagingMaterials]
        .filter((m) => m.supplier_product)
        .map((m) => m.supplier_product),
      ...rawMaterials
        .filter((m) => m.silo)
        .flatMap((m) => m.silo.items.map((item) => item.supplier_product).filter(exists)),
    ];
  }, [rawMaterials, packagingMaterials]);
  const otherSilos = useMemo(
    () =>
      rawMaterials
        .filter((m) => m.silo)
        .map((m) => m.silo)
        .filter((s) => (editingSilo ? editingSilo.id !== s.id : true)),
    [rawMaterials, editingSilo],
  );

  const supplierMaterialsMap = useMemo(
    () => Object.fromEntries(supplierMaterials.map((m) => [m.id, m])),
    [supplierMaterials],
  );

  const lastYear = new Date().getFullYear() - 1;
  const lastFourYears = Array.from({ length: 4 }, (_, i) => lastYear - i).reverse();

  const methods = useForm<Fields>({
    mode: "onChange",
    defaultValues: {
      name: editingSilo?.name || "",
      years: lastFourYears.map((year) => ({
        year,
        materials: editingSilo
          ? editingSilo.items.flatMap((i) =>
              i.annual_items
                .filter((item) => item.year === year)
                .map((item) => ({
                  supplierMaterialId: i.supplier_product_id,
                  amount: item.amount,
                })),
            )
          : initialMaterials?.map((m) => ({
              supplierMaterialId: m.id,
              amount: NaN,
            })) || [
              { supplierMaterialId: null, amount: NaN },
              { supplierMaterialId: null, amount: NaN },
            ],
      })),
    },
  });

  const [activeTab, setActiveTab] = useState<number>(lastYear);
  const activeTabIdx = lastFourYears.findIndex((y) => y === activeTab);

  const { mutateAsync: addSilo } = useAddSilo();
  const { mutateAsync: updateSilo } = useUpdateSilo();
  const { mutateAsync: removeSilo } = useRemoveSilo();

  const onSubmit: SubmitHandler<Fields> = useCallback(
    async (data) => {
      const flatItems = data.years.flatMap((year) =>
        year.materials.map((m) => ({
          year: year.year,
          supplierMaterialId: m.supplierMaterialId,
          amount: m.amount,
        })),
      );

      const groupedItems = Object.groupBy(
        flatItems,
        (item) => item.supplierMaterialId || "unknown",
      );

      const items = Object.entries(groupedItems)
        .map(([supplierMaterialId, annualItems]) => ({
          supplier_product_id: supplierMaterialId,
          annual_items:
            annualItems
              ?.map((item) => ({
                amount: item.amount,
                year: item.year,
              }))
              .filter(({ amount }) => !isNaN(amount)) || [],
        }))
        .filter(({ supplier_product_id }) =>
          // If users have removed a material in the active tab
          // it might still be filled in other tabs they didnt tab into
          // so
          methods
            .getValues(`years.${activeTabIdx}.materials`)
            .some((m) => m.supplierMaterialId === supplier_product_id),
        );

      if (editingSilo) {
        await updateSilo({
          ...editingSilo,
          name: data.name,
          items,
        });
      } else {
        await addSilo({ name: data.name, items });
        onCreateSuccess?.();
      }
      close();
    },
    [editingSilo, close, methods, activeTabIdx, updateSilo, addSilo, onCreateSuccess],
  );

  const supplierMaterialsOptions = useMemo(() => {
    return supplierMaterials
      .filter(
        (m) =>
          !otherSilos.some((s) => s.items.some((i) => i.supplier_product_id === m.id)) &&
          !packagingMaterials.some((pm) => pm.supplier_product.id === m.id),
      )
      .map((m) => ({
        id: m.id,
        label: m.name,
      }));
  }, [supplierMaterials, otherSilos, packagingMaterials]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <ModalHeader
          title={t("Group materials to silo")}
          subtitle={t("Allows grouping of materials of the same type")}
        />
        <ModalBody>
          <div className="flex flex-col gap-6">
            <TextFieldConnected
              isRequired
              name="name"
              label="Silo name"
              rules={{
                validate: (value) => {
                  return [...supplierMaterials, ...otherSilos].every((s) => s.name !== value)
                    ? true
                    : t("This name is already taken.");
                },
              }}
            />
            <div>
              <TabSwitch
                activeTab={activeTab.toString()}
                setActiveTab={(tab) => {
                  // Ensure, that the next active tab has all the materials from the previous active tab:
                  methods.setValue(
                    "years",
                    methods.getValues("years").map((y) => {
                      if (y.year !== Number(tab)) return y;

                      const currentMaterials = methods.getValues(`years.${activeTabIdx}.materials`);

                      return {
                        year: y.year,
                        materials: currentMaterials.map((currentMaterial) => {
                          const amount = y.materials.find(
                            (mat) => mat.supplierMaterialId === currentMaterial.supplierMaterialId,
                          )?.amount;

                          return {
                            ...currentMaterial,
                            amount: exists(amount) ? amount : NaN,
                          };
                        }),
                      };
                    }),
                  );

                  setActiveTab(Number(tab));
                }}
                options={lastFourYears.map((year, yearIdx) => ({
                  label: <YearTabLabel key={year} year={year} yearIdx={yearIdx} />,
                  value: year.toString(),
                }))}
              />
              <hr className="border-neutral-200 my-0" />
            </div>
            <YearTab
              key={activeTabIdx}
              yearIdx={activeTabIdx}
              supplierMaterialOptions={supplierMaterialsOptions}
              supplierMaterialsMap={supplierMaterialsMap}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="flex flex-row-reverse items-center justify-between w-full gap-6">
            <div className="flex items-center gap-4">
              <Button intent="secondary" onPress={close}>
                Cancel
              </Button>
              <Button intent="primary" type="submit">
                Save
              </Button>
            </div>
            {editingSilo && (
              <Button
                intent="tertiary"
                danger
                onPress={async () => {
                  await removeSilo({
                    siloId: editingSilo.id,
                  });
                  close();
                }}
              >
                <DeleteOutlined />
                {t("Dissolve silo")}
              </Button>
            )}
          </div>
        </ModalFooter>
      </form>
    </FormProvider>
  );
};
