import { HeartBroken, RefreshOutlined, WindowOutlined } from "@mui/icons-material";
import { FallbackRender } from "@sentry/react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { ServerNotReachableError } from "../../api/useApi";
import { PageContainer } from "../../components/PageContainer";
import { TopBar } from "../../components/TopBar";
import { ManageManufacturers } from "../../page-components/company-settings/ManageManufacturers";
import { NoPlantError } from "../../state/plants";
import { AddPlant } from "../AddPlant";
import { ServerNotReachable } from "./ServerNotReachable";

type MyFallbackRender = (
  errorData: Omit<Parameters<FallbackRender>[0], "resetError"> & {
    resetError: () => void;
  },
) => React.ReactElement;

export const ErrorPage: MyFallbackRender = ({ error, resetError }) => {
  const { t } = useTranslation();

  console.error(error);

  // -------------
  // Server can't be reached – maybe the firewall settings don't permit accessing the server
  // or the server is down
  // -------------
  if (error instanceof ServerNotReachableError) {
    return <ServerNotReachable />;
  }

  // -------------
  // Initial manufacturer setup
  // -------------
  // We're doing this as an error case,
  // so that all other components can simply call
  // useActiveManufacturer and it will always be defined.
  if (axios.isAxiosError(error) && error.config?.url === "/v1/me/manufacturers") {
    return (
      <PageContainer className="w-full">
        <TopBar
          icon={<WindowOutlined />}
          title={t("Company Setup")}
          subtitle={t("Please set up your company details.")}
          hideNavigation
        />
        <div className="my-12 w-full flex items-center justify-center">
          <ManageManufacturers onCreateSuccess={resetError} />
        </div>
      </PageContainer>
    );
  }

  // -------------
  // Initial plant setup
  // -------------
  // We're doing this as an error case,
  // so that all other components can simply call
  // useActivePlant and it will always be defined.
  if (error instanceof NoPlantError) {
    return (
      <div className="w-full h-screen overflow-y-auto">
        <AddPlant onCreateSuccess={resetError} />;
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col gap-3 h-screen items-center justify-center">
      <div className="max-w-xl flex flex-col gap-3 items-center text-center">
        <HeartBroken className="text-gray-300" sx={{ fontSize: 100 }} />
        <h2 className="text-xl">{t("Sorry, something didn't work.")}</h2>
        <p className="text-lg">
          {t("But you did nothing wrong. Please refresh the page and try again.")}{" "}
          {t("If this happens again, please contact us at")}{" "}
          <a className="underline" href="mailto:support@emidat.com">
            support@emidat.com
          </a>
          .
        </p>
        <button
          className="flex gap-3 items-center text-xl px-5 py-2 text-neutral-500 font-bold"
          onClick={() => window.location.reload()}
        >
          <RefreshOutlined />
          {t("Refresh")}
        </button>
      </div>
    </div>
  );
};
