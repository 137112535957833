import { AddOutlined, FactoryOutlined } from "@mui/icons-material";
import { GridColDef, GridFilterModel, GridRowParams } from "@mui/x-data-grid-pro";
import { Suspense, useEffect, useMemo, useState } from "react";
import { Button as ButtonPrimitive } from "react-aria-components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { ProductCategory } from "../api/types";
import { useMaxPlantsCount } from "../auth/auth";
import { Badge } from "../components/Badge";
import { DataGrid } from "../components/DataGrid";
import { Link } from "../components/Link";
import { Tooltip, TooltipTrigger } from "../components/Tooltip";
import { TopBar } from "../components/TopBar";
import { DataExport } from "../page-components/company-settings/DataExport";
import { SearchControls } from "../page-components/products/SearchControls";
import { usePlants } from "../state/plants";
import { useProductCategories } from "../state/productCategories";

const PlantCategoryCell = ({
  categoryIds,
  productCategoriesMap,
}: {
  categoryIds: string[];
  productCategoriesMap: Record<string, ProductCategory>;
}) => {
  const { t } = useTranslation();
  const categories = Array.from(new Set(categoryIds.map((c) => productCategoriesMap[c].name)));

  const visibleCategories = categories.slice(0, 3);
  const remainingCount = Math.max(0, categories.length - 3);

  return (
    <div className="h-full flex items-center gap-2">
      {visibleCategories.map((c) => (
        <Badge key={c} size="sm">
          {t(c)}
        </Badge>
      ))}
      {remainingCount > 0 && (
        <TooltipTrigger>
          <ButtonPrimitive className="text-sm text-neutral-500">
            +{remainingCount} {t("more")}
          </ButtonPrimitive>
          <Tooltip>
            <div className="flex flex-wrap gap-2">
              {categories.slice(3).map((c) => (
                <Badge key={c} size="sm" color="white">
                  {t(c)}
                </Badge>
              ))}
            </div>
          </Tooltip>
        </TooltipTrigger>
      )}
    </div>
  );
};

export const ManagePlants = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    data: { productCategoriesMap },
  } = useProductCategories();

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    quickFilterValues: [],
  });

  const { data: plants = [] } = usePlants();
  const maxPlants = useMaxPlantsCount();

  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    setFilterModel((prev) => ({
      ...prev,
      quickFilterValues: searchInput.split(" ") ?? [],
    }));
  }, [searchInput]);

  const columns: GridColDef[] = useMemo(() => {
    return [
      {
        field: "name",
        headerName: t("Name"),
        width: 300,
        renderCell: ({ value }) => (
          <div className="flex items-center gap-2">
            <FactoryOutlined className="text-neutral-500" />
            {value}
          </div>
        ),
      },
      {
        field: "city",
        headerName: t("City"),
        width: 200,
      },
      {
        field: "country",
        headerName: t("Country"),
        width: 200,
      },
      {
        field: "product_categories",
        headerName: t("Category"),
        width: 600,
        renderCell: ({ value }) => {
          return (
            <PlantCategoryCell categoryIds={value} productCategoriesMap={productCategoriesMap} />
          );
        },
      },
    ];
  }, [t, productCategoriesMap]);

  return (
    <>
      <TopBar
        title={t("Settings")}
        subtitle={t("{{count}} plants", { count: plants.length })}
        icon={<FactoryOutlined />}
        input={
          <Link intent="secondary" isDisabled={plants.length >= maxPlants} href="/plants/new">
            <AddOutlined />
            {t("New plant")}
          </Link>
        }
      />

      <Suspense>
        <div className="flex flex-col gap-6 py-8">
          <div className="flex items-end justify-between w-full gap-4">
            <div className="flex-1">
              {maxPlants - plants.length < 2 && (
                <p className="text-sm text-gray-700">
                  {t(
                    "You have {{ plants }} out of {{ maxPlants }} allowed plants. To increase the limit please contact support.",
                    { plants: plants.length, maxPlants },
                  )}
                </p>
              )}
            </div>
            <SearchControls search={searchInput} setSearch={setSearchInput} />
          </div>

          <div className="grow flex flex-col gap-8">
            {plants.length > 0 && (
              <DataGrid
                id="manage-plants"
                rows={plants}
                columns={columns}
                filterModel={filterModel}
                onFilterModelChange={(model) => {
                  setFilterModel({
                    items: model.items,
                    quickFilterValues: model.quickFilterValues ?? [],
                  });
                }}
                onRowClick={(params: GridRowParams) => {
                  navigate(`/plants/${params.row.id}/edit`);
                }}
              />
            )}
          </div>
        </div>
        <DataExport />
      </Suspense>
    </>
  );
};
