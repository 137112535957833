import { useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ComboBoxFieldConnected } from "../../form-components/ComboBoxFieldConnected";
import { NumberFieldConnected } from "../../form-components/NumberFieldConnected";
import { ConcreteScenario } from "./ProductSpecsConcreteScenario";

export type CableTrayScenario = ConcreteScenario & {
  base_layer: ReturnType<typeof useConcreteOrGravelOptions>[number]["id"];
  base_layer_kg: number;
  non_woven_layer: ReturnType<typeof useNonWovenOptions>[number]["id"];
  non_woven_layer_kg: number;
};

const useConcreteOrGravelOptions = () => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      { label: t("Filter concrete 8/16"), id: "Concrete" as const },
      { label: t("Gravel bed 4/8"), id: "Gravel" as const },
    ],
    [t],
  );
};

const useNonWovenOptions = () => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      { label: t("Non-woven PP"), id: "PP" as const },
      { label: t("Non-woven PE"), id: "PE" as const },
      { label: t("Non-woven PA"), id: "PA" as const },
      { label: t("Non-woven PES"), id: "PES" as const },
    ],
    [t],
  );
};

export const CableTrayInstallationFields = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const concreteOrGravelOptions = useConcreteOrGravelOptions();
  const nonWovenOptions = useNonWovenOptions();

  const baseLayerType = useWatch({
    control,
    name: "scenario.base_layer",
  });

  const nonWovenType = useWatch({
    control,
    name: "scenario.non_woven_layer",
  });

  const baseLayerLabel = baseLayerType
    ? concreteOrGravelOptions.find((opt) => opt.id === baseLayerType)?.label
    : null;

  const nonWovenLabel = nonWovenType
    ? nonWovenOptions.find((opt) => opt.id === nonWovenType)?.label
    : null;

  return (
    <div className="col-span-full space-y-6">
      <div className="grid xl:grid-cols-3 grid-cols-2 gap-6">
        <ComboBoxFieldConnected
          name="scenario.base_layer"
          label={t("Solid base layer")}
          isRequired
          options={concreteOrGravelOptions}
        />
        <NumberFieldConnected
          name="scenario.base_layer_kg"
          minValue={0}
          label={baseLayerLabel ? `${t("Amount")} (${baseLayerLabel})` : t("Amount")}
          isRequired
          inputProps={{
            addonRight: "kg",
          }}
          rules={{
            validate: (value) => {
              if (value === 0) {
                return t("Must be greater than 0");
              }
            },
          }}
        />
      </div>
      <div className="grid xl:grid-cols-3 grid-cols-2 gap-6">
        <ComboBoxFieldConnected
          name="scenario.non_woven_layer"
          label={t("Non-woven layer")}
          isRequired
          options={nonWovenOptions}
        />
        <NumberFieldConnected
          name="scenario.non_woven_layer_kg"
          minValue={0}
          label={nonWovenLabel ? `${t("Amount")} (${nonWovenLabel})` : t("Amount")}
          isRequired
          inputProps={{
            addonRight: "kg",
          }}
          rules={{
            validate: (value) => {
              if (value === 0) {
                return t("Must be greater than 0");
              }
            },
          }}
        />
      </div>
    </div>
  );
};
