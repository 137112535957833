import { saveAs } from "file-saver";
import { useMemo } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useGetLcaDocumentation } from "../../api/endpoints/lca-documentation";
import { Button } from "../../components/Button";
import { Heading4 } from "../../components/TypographyOld";
import { ComboBoxFieldConnected } from "../../form-components/ComboBoxFieldConnected";
import { useElementaries } from "../../state/elementaries";
import { exists } from "../../util/commonUtil";

type Fields = {
  elementaryId: string | null;
};

export const GenerateLCI = () => {
  const { t } = useTranslation();
  const { elementaries } = useElementaries();
  const getLcaDocumentation = useGetLcaDocumentation();

  const methods = useForm<Fields>({
    defaultValues: {
      elementaryId: null,
    },
  });
  const elementaryOptions = useMemo(
    () =>
      elementaries
        .filter((e) => exists(e.product_metadata_id))
        .map((e) => ({ label: e.name, value: e.id, id: e.id }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [elementaries],
  );

  const onSubmit: SubmitHandler<Fields> = async ({ elementaryId }) => {
    if (!elementaryId) return;

    const response = await getLcaDocumentation({ elementaryId });
    saveAs(response, "LCI.xlsx");
  };

  return (
    <FormProvider {...methods}>
      <form className="flex flex-col gap-6 py-4" onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="grid grid-cols-2 items-center">
          <Heading4>{t("Generate LCI")}</Heading4>
        </div>

        <div className="space-y-6 max-w-xl">
          <ComboBoxFieldConnected
            name="elementaryId"
            label="Elementary"
            options={elementaryOptions}
          />

          <Button intent="primary" type="submit">
            Generate
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
