import { AddOutlined, SaveOutlined } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { usePatchManufacturer, usePostManufacturer } from "../../api/endpoints/manufacturers";
import { Manufacturer } from "../../api/types";
import { Button } from "../../components/Button";
import { Card } from "../../components/Card";
import { ComboBoxFieldConnected } from "../../form-components/ComboBoxFieldConnected";
import { ComboBoxMultiFieldConnected } from "../../form-components/ComboBoxMultiFieldConnected";
import { TextFieldConnected } from "../../form-components/TextFieldConnected";
import { HARDE_CODED_MANAGEMENT_SYSTEM_OPTIONS } from "../../state/HARD_CODED";
import { useCountryOptions } from "../../util/useCountryOptions";

interface Fields {
  name: string;
  email: string;
  phone: string;
  address: string;
  post_code: string;
  city: string;
  country: string;
  website: string | null;
  management_systems: string[];
  organisation_number: string | null;
}

// TODO: Get the complexity down
// eslint-disable-next-line complexity
export const ManageManufacturers = ({
  activeManufacturer,
  onCreateSuccess,
}: {
  activeManufacturer?: Manufacturer;
  onCreateSuccess?: () => void;
}) => {
  const { t } = useTranslation();
  const countryOptions = useCountryOptions();

  const methods = useForm<Fields>({
    defaultValues: {
      name: activeManufacturer?.name || undefined,
      email: activeManufacturer?.email || undefined,
      phone: activeManufacturer?.phone || undefined,
      address: activeManufacturer?.address || undefined,
      post_code: activeManufacturer?.post_code || undefined,
      city: activeManufacturer?.city || undefined,
      country: activeManufacturer?.country || undefined,
      website: activeManufacturer?.website || undefined,
      organisation_number: activeManufacturer?.organisation_number || undefined,
      management_systems: activeManufacturer?.management_systems || [],
    },
  });

  const { reset } = methods;
  const [prevActiveManufacturer, setPrevActiveManufacturer] = useState(activeManufacturer);
  useEffect(() => {
    if (prevActiveManufacturer !== activeManufacturer) {
      setPrevActiveManufacturer(activeManufacturer);
      if (activeManufacturer) {
        reset({
          name: activeManufacturer.name,
          email: activeManufacturer.email,
          phone: activeManufacturer.phone,
          address: activeManufacturer.address,
          post_code: activeManufacturer.post_code,
          city: activeManufacturer.city,
          country: activeManufacturer.country,
          website: activeManufacturer.website,
          organisation_number: activeManufacturer.organisation_number,
          management_systems: activeManufacturer.management_systems,
        });
      }
    }
  }, [prevActiveManufacturer, setPrevActiveManufacturer, activeManufacturer, reset]);

  const { mutate: postManufacturer, isPending: postPending } = usePostManufacturer({
    onCreateSuccess,
  });
  const { mutate: patchManufacturer, isPending: patchPending } = usePatchManufacturer();
  const onSubmit: SubmitHandler<Fields> = (data) => {
    if (activeManufacturer) {
      patchManufacturer({
        manufacturer: {
          ...activeManufacturer,
          ...data,
        },
      });
    } else {
      postManufacturer(data);
    }
  };

  const loading = postPending || patchPending;

  const headline = activeManufacturer ? t("Company Information") : t("Create Company");

  return (
    <Card title={headline}>
      <FormProvider {...methods}>
        <form
          className="grid grid-cols-2 gap-6"
          onSubmit={methods.handleSubmit(onSubmit)}
          noValidate
        >
          <TextFieldConnected name="name" label={t("Company Name")} isRequired />
          <TextFieldConnected
            name="email"
            rules={{
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: t("Invalid email address"),
              },
            }}
            isRequired
            type="email"
            label={t("Email")}
          />
          <TextFieldConnected name="phone" isRequired type="tel" label={t("Phone")} />
          <TextFieldConnected name="address" isRequired label={t("Address")} />
          <div className="grid grid-cols-[30%_1fr] gap-3 ">
            <TextFieldConnected name="post_code" isRequired label={t("Post code")} />
            <TextFieldConnected name="city" isRequired label={t("City")} />
          </div>
          <ComboBoxFieldConnected
            name="country"
            isRequired
            options={countryOptions}
            label={t("Country")}
          />
          {activeManufacturer && (
            <>
              <TextFieldConnected name="website" label={t("Website")} />
              <TextFieldConnected name="organisation_number" label={t("Organisation number")} />
              <ComboBoxMultiFieldConnected
                name="management_systems"
                label={t("Management Systems")}
                options={HARDE_CODED_MANAGEMENT_SYSTEM_OPTIONS.map((option) => ({
                  id: option,
                  label: option,
                }))}
              />
            </>
          )}
          <div className="col-span-2 flex justify-end">
            <Button type="submit" isDisabled={loading}>
              {loading && <CircularProgress size="24px" />}
              {!activeManufacturer ? <AddOutlined /> : <SaveOutlined />}
              {!activeManufacturer ? t("Create company") : t("Update company")}
            </Button>
          </div>
        </form>
      </FormProvider>
    </Card>
  );
};
