import { AddOutlined, TokenOutlined } from "@mui/icons-material";
import { GridColumnVisibilityModel } from "@mui/x-data-grid-pro";
import { Suspense, useMemo, useState } from "react";
import { MenuTrigger } from "react-aria-components";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router";
import useLocalStorage from "react-use/lib/useLocalStorage";
import { Silo } from "../api/types";
import { Button } from "../components/Button";
import { Menu, MenuItem } from "../components/Menu";
import { Modal } from "../components/Modal";
import { PageContainer } from "../components/PageContainer";
import { TopBar } from "../components/TopBar";
import { Label } from "../components/TypographyOld";
import {
  FiltersSidePanel,
  useFilterSidePanel,
} from "../page-components/suppliers-and-materials/FiltersSidePanel";
import { MaterialsListView } from "../page-components/suppliers-and-materials/MaterialsListView";
import { SiloModalBody } from "../page-components/suppliers-and-materials/SiloModalBody";
import { useElementaries } from "../state/elementaries";
import { useMaterials } from "../state/materials";
import { useSuppliers } from "../state/suppliers";
import { exists } from "../util/commonUtil";

export const SuppliersAndMaterials = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { rawMaterials, packagingMaterials } = useMaterials();
  const { elementaries } = useElementaries();

  const [editingSilo, setEditingSilo] = useState<Silo>();
  const [siloModalOpen, setSiloModalOpen] = useState(false);

  const { data: suppliers } = useSuppliers();

  const input = useMemo(
    () => (
      <>
        <Button intent="secondary" onPress={() => navigate("/bulk-upload/materials")}>
          {t("Upload")}
        </Button>

        <MenuTrigger>
          <Button>
            <AddOutlined />
            {t("New Material")}
          </Button>
          <Menu placement="bottom end">
            <MenuItem
              icon={<AddOutlined />}
              text={t("Add material")}
              onAction={() => navigate("/suppliers-and-materials/new-raw-material")}
            />
            <MenuItem
              icon={<AddOutlined />}
              text={t("Add packaging material")}
              onAction={() => navigate("/suppliers-and-materials/new-packaging")}
            />
            <MenuItem
              icon={<AddOutlined />}
              text={t("Add own product")}
              onAction={() => navigate("/suppliers-and-materials/new-prechain-product")}
            />
            <MenuItem
              icon={<AddOutlined />}
              text={t("Add silo")}
              onAction={() => {
                setEditingSilo(undefined);
                setSiloModalOpen(true);
              }}
            />
          </Menu>
        </MenuTrigger>
      </>
    ),
    [navigate, t],
  );

  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const allMaterials = useMemo(
    () => [...rawMaterials, ...packagingMaterials],
    [rawMaterials, packagingMaterials],
  );

  const filters = useFilterSidePanel();

  const filteredMaterialsByType = useMemo(() => {
    if (type === "supplier")
      return rawMaterials.filter((material) => exists(material.supplier_product));
    if (type === "packaging") return packagingMaterials;
    if (type === "own") return rawMaterials.filter((material) => exists(material.prechain_product));

    return [...rawMaterials, ...packagingMaterials];
  }, [type, rawMaterials, packagingMaterials]);

  const filteredMaterials = useMemo(() => {
    let filtered = filteredMaterialsByType;

    if (filters.byOnlyEpdLinked !== null) {
      filtered = filtered.filter((material) =>
        material.supplier_product
          ? !!material.supplier_product.epd_id === filters.byOnlyEpdLinked
          : false,
      );
    }

    if (filters.byOnlyHazardousSubstances !== null) {
      filtered = filtered.filter((material) =>
        material.supplier_product
          ? filters.byOnlyHazardousSubstances
            ? material.supplier_product.hazardous_substances.length > 0
            : material.supplier_product.hazardous_substances.length === 0
          : false,
      );
    }

    if (filters.bySupplier.size > 0) {
      filtered = filtered.filter((material) =>
        material.supplier_product
          ? filters.bySupplier.has(
              material.supplier_product.supplier_plant?.supplier_company.name ?? "",
            )
          : false,
      );
    }

    if (filters.byMaterialType.size === 0) return filtered;
    return filtered.filter((material) => {
      const materialType = material.supplier_product
        ? elementaries.find((c) => c.id === material.supplier_product.elementary_id)?.name
        : material.prechain_product?.product.material;
      return materialType ? filters.byMaterialType.has(materialType) : false;
    });
  }, [filters, filteredMaterialsByType, elementaries]);

  const title = useMemo(() => {
    if (type === "own") return t("Own Products");
    if (type === "packaging") return t("Packaging");
    return t("Materials");
  }, [type, t]);

  const subtitle = useMemo(() => {
    if (type === "own")
      return (
        <>
          {filteredMaterials.length} {t("own products", { count: filteredMaterials.length })}
        </>
      );
    if (type === "packaging")
      return (
        <>
          {packagingMaterials.length}{" "}
          {t("packaging materials", { count: packagingMaterials.length })}
        </>
      );

    return (
      <>
        {filteredMaterials.length} {t("materials", { count: filteredMaterials.length })}
        {", "}
        {suppliers.length} {t("suppliers", { count: suppliers.length })}
      </>
    );
  }, [type, t, filteredMaterials, packagingMaterials.length, suppliers.length]);

  const DEFAULT_MODEL: GridColumnVisibilityModel = {
    recyclingShare: false,
    hazardousSubstancesString: false,
  };
  const [_columnVisibilityModel, setColumnVisibilityModel] =
    useLocalStorage<GridColumnVisibilityModel>(
      "suppliers-and-materials-column-visibility",
      DEFAULT_MODEL,
    );
  const columnVisibilityModel = _columnVisibilityModel ?? DEFAULT_MODEL;

  return (
    <Suspense>
      <PageContainer>
        <Modal size="lg" isOpen={siloModalOpen} onOpenChange={setSiloModalOpen}>
          {({ close }) => <SiloModalBody close={close} editingSilo={editingSilo} />}
        </Modal>
        <TopBar
          title={title}
          subtitle={subtitle}
          icon={<TokenOutlined />}
          input={filteredMaterials.length > 0 ? input : undefined}
        />
        <div className="grow flex flex-col gap-4 pt-8 pb-16">
          {allMaterials.length === 0 && (
            <div className="grow w-full flex flex-col items-center justify-center gap-3">
              <div className="flex flex-col items-center gap-3">
                <Label className="flex items-center gap-2">{t("No materials added yet")}</Label>
                <div className="flex items-center gap-5">{input}</div>
              </div>
            </div>
          )}
          {allMaterials.length > 0 && (
            <MaterialsListView
              materials={filteredMaterials}
              filters={filters}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={setColumnVisibilityModel}
              onSiloEdit={(silo) => {
                setEditingSilo(silo);
                setSiloModalOpen(true);
              }}
            />
          )}
          <FiltersSidePanel
            filters={filters}
            materials={filteredMaterialsByType}
            elementaries={elementaries}
            columnVisibilityModel={columnVisibilityModel}
          />
        </div>
      </PageContainer>
    </Suspense>
  );
};
