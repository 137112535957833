import { RecyclingOutlined } from "@mui/icons-material";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Alert } from "../../components/Alert";
import { CheckboxField } from "../../components/CheckboxField";
import { NumberFieldConnected } from "../../form-components/NumberFieldConnected";
import { FormFields } from "./types";

export const RecycledContentForm = ({ isEditing }: { isEditing?: boolean }) => {
  const { t } = useTranslation();

  const methods = useFormContext<FormFields>();
  const [hasRecycling, setHasRecycling] = useState<boolean>(
    methods.getValues("secondaryPercentage") !== null,
  );

  return (
    <div className="flex flex-col gap-4">
      {isEditing && (
        <CheckboxField
          isSelected={hasRecycling}
          onChange={(checked) => {
            setHasRecycling(checked);
            if (!checked) {
              methods.setValue("secondaryPercentage", null);
            }
          }}
        >
          {t("Parts of the material are recycled")}
        </CheckboxField>
      )}
      {!isEditing && hasRecycling && (
        <Alert variant="yellow" icon={<RecyclingOutlined />}>
          {t("Parts of the material are recycled")}
        </Alert>
      )}
      {hasRecycling && (
        <NumberFieldConnected<FormFields>
          name="secondaryPercentage"
          isRequired={isEditing}
          isViewOnly={!isEditing}
          minValue={0}
          maxValue={100}
          rules={{
            validate: (value) => {
              if (value === 0) {
                return t("Must be greater than 0");
              }
            },
          }}
          label={t("Recycled share")}
          inputProps={{ addonRight: t("% of mass") }}
        />
      )}
    </div>
  );
};
