import { FactoryOutlined, Logout, StarBorder } from "@mui/icons-material";
import { cva } from "class-variance-authority";
import { Suspense, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useTranslation } from "react-i18next";
import { useAllPages, useNavbarItems } from "../config/useNavbarItems";
import { useAdminMode } from "../state/admin";
import { useNavigation } from "../state/navigation";
import { useActivePlantControl } from "../state/plants";
import { IconContainer } from "./IconContainer";
import { NavbarItem } from "./NavbarItem";
import { PlantSelector, plantSelectorItemStyles } from "./PlantSelector";

const NavbarTopSection = ({
  isPlantSelectorOpen,
  setPlantSelectorOpen,
  collapsed,
  openNavGroup,
  setOpenNavGroup,
}: {
  isPlantSelectorOpen: boolean;
  setPlantSelectorOpen: (open: boolean) => void;
  collapsed: boolean;
  openNavGroup: string | null;
  setOpenNavGroup: (group: string | null) => void;
}) => {
  const navbarItems = useNavbarItems();

  return isPlantSelectorOpen ? (
    <PlantSelector onClose={() => setPlantSelectorOpen(false)} collapsed={collapsed} />
  ) : (
    <div className="flex flex-col gap-2">
      {navbarItems.map((item, i) => {
        return (
          <NavbarItem
            collapsed={collapsed}
            key={`${i}-${item.name}`}
            item={item}
            openNavGroup={openNavGroup}
            setOpenNavGroup={setOpenNavGroup}
          />
        );
      })}
    </div>
  );
};

const navStyles = cva(
  [
    "shrink-0",
    "h-full",
    "flex",
    "flex-col",
    "justify-between",
    "overflow-y-auto",
    "bg-neutral-100",
  ],
  {
    variants: {
      collapsed: {
        false: "w-[var(--navbar-width)]",
      },
    },
    defaultVariants: {
      collapsed: false,
    },
  },
);

export const Navbar = () => {
  const { t } = useTranslation();
  const adminMode = useAdminMode();
  const { collapsed, setCollapsed, showNavigation } = useNavigation();

  const allPages = useAllPages();

  const { activePlant } = useActivePlantControl();
  const [isPlantSelectorOpen, setPlantSelectorOpen] = useState(false);

  const [openNavGroup, setOpenNavGroup] = useState<string | null>(null);

  useHotkeys("alt+shift+c", () => setCollapsed((c) => !c), [setCollapsed]);

  if (!showNavigation) return <div />;

  return (
    <div className={navStyles({ collapsed })}>
      <div className="space-y-8">
        <div className="flex flex-col">
          <div
            onClick={() => {
              setPlantSelectorOpen((o) => !o);
              setCollapsed(false);
            }}
            className={plantSelectorItemStyles({ collapsed, isSelected: true })}
            style={{ paddingTop: "16px", paddingBottom: "16px" }}
          >
            <IconContainer $active>
              <FactoryOutlined />
            </IconContainer>
            {!collapsed && activePlant?.name}
          </div>
          <hr className="border-neutral-300 mb-5" />
          <Suspense>
            <NavbarTopSection
              isPlantSelectorOpen={isPlantSelectorOpen}
              setPlantSelectorOpen={setPlantSelectorOpen}
              collapsed={collapsed}
              openNavGroup={openNavGroup}
              setOpenNavGroup={setOpenNavGroup}
            />
          </Suspense>
        </div>
      </div>
      {!isPlantSelectorOpen && (
        <div className="flex flex-col pb-3">
          <hr className="border-neutral-300 mb-5" />
          {adminMode && (
            <NavbarItem
              collapsed={collapsed}
              item={{
                icon: <StarBorder fontSize="small" />,
                name: t("Admin"),
                path: "/admin",
              }}
              openNavGroup={openNavGroup}
              setOpenNavGroup={setOpenNavGroup}
            />
          )}
          <NavbarItem
            collapsed={collapsed}
            item={{
              ...allPages.companySettings,
              children: [
                {
                  name: t("Company"),
                  path: "/company-settings?tab=company",
                },
                {
                  name: t("Plants"),
                  path: "/company-settings?tab=plants",
                },
                {
                  name: t("Users"),
                  path: "/company-settings?tab=users",
                },
              ],
            }}
            openNavGroup={openNavGroup}
            setOpenNavGroup={setOpenNavGroup}
          />
          <NavbarItem
            collapsed={collapsed}
            item={{
              icon: <Logout fontSize="small" />,
              name: t("Logout"),
              path: "/logout",
            }}
            openNavGroup={openNavGroup}
            setOpenNavGroup={setOpenNavGroup}
          />
        </div>
      )}
    </div>
  );
};
