import { ComponentProps, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useProductionProcesses } from "../../api/endpoints/production-processes";
import { Plant, ProductCategory } from "../../api/types";
import { Alert } from "../../components/Alert";
import { Checkbox, CheckboxGroupField } from "../../components/CheckboxGroupField";
import { Label12 } from "../../components/TypographyOld";
import { useElementaries } from "../../state/elementaries";
import { useProductCategories } from "../../state/productCategories";
import { exists } from "../../util/commonUtil";

// This is essentially just a custom ComboBoxMultiFieldConnected
//
// <ComboBoxMultiFieldConnected
//   name="product_categories"
//   options={options}
//   label={t("what do you produce in this plant?")}
//   wrapValueAt={5}
//   isrequired
// />

const CustomProductCategoryPickerBase = ({
  notRemovableCategories,
  hideHeading,
  layout = "single",
}: {
  notRemovableCategories: Set<ProductCategory["id"]>;
  hideHeading?: boolean;
  layout?: "single" | "double";
}) => {
  const {
    data: { productCategories },
  } = useProductCategories();
  const { watch, setValue, setError, clearErrors, formState } = useFormContext();
  const { t } = useTranslation();
  const selectedCategories: string[] = watch("product_categories");
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (selectedCategories.length === 0) {
      setError("product_categories", { type: "custom", message: t("Required") });
      setHasError(true);
    } else {
      clearErrors("product_categories");
      setHasError(false);
    }
  }, [selectedCategories, setError, clearErrors, t]);

  const groupedCategories = useMemo(() => {
    return Object.groupBy(productCategories, (cat) => cat.group);
  }, [productCategories]);

  return (
    <div className="space-y-6">
      {!hideHeading && (
        <div>
          <Label12 className="text-neutral-400">{t("What do you produce in this plant?")}</Label12>
          <hr className="border-neutral-200 mt-4" />
        </div>
      )}
      {hasError && formState.submitCount > 0 && (
        <span className="text-red-500 text-sm font-bold">
          {t("At least one product category required")}
        </span>
      )}
      <div className="flex flex-col gap-6">
        {Object.entries(groupedCategories).map(([category, items]) => (
          <CheckboxGroupField
            key={category}
            value={selectedCategories.filter((cat) => items?.map((i) => i.id).includes(cat))}
            onChange={(value) => {
              setValue(
                "product_categories",
                Array.from(
                  new Set([
                    ...selectedCategories.filter((cat) => !items?.map((i) => i.id).includes(cat)),
                    ...value,
                  ]),
                ),
              );
            }}
            label={t(category)}
            labelProps={{ style: { color: "var(--builtgreen)" } }}
          >
            <div className={`grid ${layout === "single" ? "grid-cols-1" : "grid-cols-2 gap-x-2"}`}>
              {items!.map((category) => {
                return (
                  <Checkbox
                    key={category.id}
                    value={category.id}
                    isDisabled={notRemovableCategories.has(category.id)}
                  >
                    {t(category.name)}
                  </Checkbox>
                );
              })}
            </div>
          </CheckboxGroupField>
        ))}
      </div>
      {notRemovableCategories.size > 0 && (
        <div className="justify-self-end">
          <Alert>{t("Note: categories with a production process may not be deselected")}</Alert>
        </div>
      )}
    </div>
  );
};

const CustomProductCategoryPickerNewPlant = (
  props: Pick<ComponentProps<typeof CustomProductCategoryPickerBase>, "hideHeading" | "layout">,
) => {
  return <CustomProductCategoryPickerBase notRemovableCategories={new Set()} {...props} />;
};

const CustomProductCategoryPickerExistingPlant = ({
  activePlant,
  ...props
}: { activePlant: Plant } & Pick<
  ComponentProps<typeof CustomProductCategoryPickerBase>,
  "hideHeading" | "layout"
>) => {
  const { elementariesMap } = useElementaries();

  const { data: productionProcesses } = useProductionProcesses({ plantId: activePlant.id });

  const notRemovableCategories: Set<ProductCategory["id"]> = useMemo(
    () =>
      new Set(
        productionProcesses.flatMap((p) =>
          p.elementary_ids.map((e) => elementariesMap[e].product_category_id).filter(exists),
        ),
      ),
    [elementariesMap, productionProcesses],
  );

  return (
    <CustomProductCategoryPickerBase notRemovableCategories={notRemovableCategories} {...props} />
  );
};

export const CustomProductCategoryPicker = ({
  activePlant,
  ...props
}: { activePlant?: Plant } & Pick<
  ComponentProps<typeof CustomProductCategoryPickerBase>,
  "hideHeading" | "layout"
>) => {
  if (!activePlant) return <CustomProductCategoryPickerNewPlant {...props} />;

  return <CustomProductCategoryPickerExistingPlant activePlant={activePlant} {...props} />;
};
