import {
  ArrowBack,
  CompareOutlined,
  DeleteOutlined,
  EditOutlined,
  RotateRightOutlined,
  SettingsOutlined,
} from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { Dispatch, SetStateAction, Suspense, useState } from "react";
import { MenuTrigger } from "react-aria-components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Product, ProductDetail } from "../../api/types";
import { Button } from "../../components/Button";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import { Menu, MenuItem } from "../../components/Menu";
import { TabSwitch } from "../../components/TabSwitch";
import { TopBar } from "../../components/TopBar";
import { Text16 } from "../../components/TypographyOld";
import { useDeclarations } from "../../state/declarations";
import { useRemoveProducts, useUpdateProductLca } from "../../state/products";
import { useGetLinkWithParams } from "../../url/useGetLinkWithParams";
import { showSuccessToast } from "../../util/toasts";
import { useProductStatus } from "../products/ProductStatusIndicator";
import { CompareSidePanel } from "./CompareSidePanel";

export const ProductDetailTopBar = ({
  product,
  activeTab,
  setActiveTab,
  onEdit,
  onOpenSidePanel,
}: {
  product: ProductDetail;
  activeTab: "passport" | "analyse";
  setActiveTab: Dispatch<SetStateAction<"passport" | "analyse">>;
  onEdit: () => void;
  onOpenSidePanel: () => void;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const getLinkWithParams = useGetLinkWithParams();
  const [compareSidePanelOpen, setCompareSidePanelOpen] = useState(false);

  const { mutate: updateLca, isPending: isUpdating } = useUpdateProductLca();
  const onUpdateLca = () => {
    updateLca(
      {
        plantId: product.plant_id,
        productId: product.id,
      },
      {
        onSuccess: () => {
          showSuccessToast(t("Successfully calculated LCA."));
        },
      },
    );
  };

  const nagivateBackLink = getLinkWithParams("/products");

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { mutateAsync: deleteProducts } = useRemoveProducts();
  const onDeleteProduct = async () => {
    await deleteProducts([
      {
        plantId: product.plant_id,
        productId: product.id,
      },
    ]);
    navigate(nagivateBackLink);
    showSuccessToast(
      t("Successfully deleted product {{ productName }}", { productName: product.name }),
    );
  };

  const productHasDeclaration = product.status === "under-review" || product.status === "completed";

  return (
    <TopBar
      variant="concrete"
      icon={
        <Button
          intent="secondary"
          square
          onPress={() => navigate(nagivateBackLink, { viewTransition: true })}
        >
          <ArrowBack />
        </Button>
      }
      title={t("Product details")}
      subtitle={
        <span className="flex items-center gap-2">
          <span>{t("Status")}:</span>
          <span>{useProductStatus(product.status)}</span>
        </span>
      }
      centerSection={
        <TabSwitch
          options={[
            { label: t("Passport"), value: "passport" as const },
            { label: t("Analysis"), value: "analyse" as const },
          ]}
          activeTab={activeTab}
          setActiveTab={(tab) => setActiveTab(tab as "passport" | "analyse")}
        />
      }
      input={
        <>
          {isUpdating && (
            <div className="flex items-center gap-2">
              <CircularProgress size="24px" />
              <Text16>{t("Recalculating")}</Text16>
            </div>
          )}
          <MenuTrigger>
            <Button intent="tertiary">
              <SettingsOutlined /> {t("Edit")}
            </Button>
            <Menu placement="bottom end">
              {productHasDeclaration || (
                <MenuItem
                  text={t("Renew documents")}
                  icon={<RotateRightOutlined />}
                  onAction={onUpdateLca}
                  isDisabled={isUpdating}
                  description={t(
                    "Click here to recalculate the LCA and re-generate all related documents for this product.",
                  )}
                />
              )}
              {(product.status === "draft" || product.status === "incomplete") && (
                <MenuItem
                  text={t("Edit underlying data")}
                  icon={<EditOutlined />}
                  onAction={onEdit}
                />
              )}
              <MenuItem
                text={t("Delete product")}
                icon={<DeleteOutlined />}
                onAction={() => setDeleteModalOpen(true)}
              />
            </Menu>
          </MenuTrigger>
          <Button intent="tertiary" onPress={() => setCompareSidePanelOpen(true)}>
            <CompareOutlined /> {t("Compare")}
          </Button>
          {(product.documents.length > 0 || product.active_jobs > 0 || productHasDeclaration) && (
            <Suspense>
              <MainButton onPress={onOpenSidePanel} product={product} />
            </Suspense>
          )}
          {deleteModalOpen && (
            <ConfirmationModal
              title={t("Delete product")}
              content={t("Are you sure you want to delete this product?")}
              onConfirm={onDeleteProduct}
              onCancel={() => setDeleteModalOpen(false)}
            />
          )}
          <CompareSidePanel
            product={product}
            open={compareSidePanelOpen}
            onClose={() => setCompareSidePanelOpen(false)}
          />
        </>
      }
    />
  );
};

const MainButton = ({ product, onPress }: { product: Product; onPress: () => void }) => {
  const { t } = useTranslation();

  // Pre-laod the declaration
  useDeclarations({
    productId: product.id,
    plantId: product.plant_id,
  });

  return <Button onPress={onPress}>{t("Documents")}</Button>;
};
