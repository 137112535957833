import { useCallback } from "react";
import { RecipeItem } from "../types";
import { useApi } from "../useApi";

export const useGetProductRecipe = () => {
  const api = useApi<RecipeItem[]>();

  return useCallback(
    ({ productId, plantId }: { productId: string; plantId: string }) =>
      api({ url: `/v1/plants/${plantId}/products/${productId}/recipe` }),
    [api],
  );
};

export const usePutProductRecipe = () => {
  const api = useApi({ method: "PUT" });

  return useCallback(
    ({
      productId,
      plantId,
      recipe,
    }: {
      productId: string;
      plantId: string;
      recipe: Omit<RecipeItem, "id">[];
    }) => api({ url: `/v1/plants/${plantId}/products/${productId}/recipe`, data: recipe }),
    [api],
  );
};
