import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import Decimal from "decimal.js";
import { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Alert } from "../../components/Alert";
import { CardDivider } from "../../components/Card";
import { NumberFieldConnected } from "../../form-components/NumberFieldConnected";
import { useActivePlant } from "../../state/plants";
import { exists } from "../../util/commonUtil";
import { ElectricityMixPreview } from "./ElectricityMixPreview";

export interface ElectricityMixForm {
  nuclear: number;
  solar: number;
  wind: number;
  hydropower: number;
  geothermal: number;
  bioenergy: number;
  gas: number;
  oil: number;
  coal: number;
}

const useLabels = (): Record<keyof ElectricityMixForm, string> => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      nuclear: t("Nuclear"),
      solar: t("Solar"),
      wind: t("Wind"),
      hydropower: t("Hydropower"),
      geothermal: t("Geothermal"),
      bioenergy: t("Bioenergy"),
      gas: t("Gas"),
      oil: t("Oil"),
      coal: t("Coal and peat"),
    }),
    [t],
  );
};

const useColors = (): Record<keyof ElectricityMixForm, string> => {
  return useMemo(
    () => ({
      solar: "#EFE457",
      wind: "#FFEFB8",
      nuclear: "#D9E8F5",
      hydropower: "#DEDCCD",
      geothermal: "#F3F2EE",
      bioenergy: "#CFE0DE",
      gas: "#A7C1D3",
      oil: "#D9D9D9",
      coal: "#F8F5EE",
    }),
    [],
  );
};

export const ELECTRICITY_MIX_DEFAULT_VALUES: ElectricityMixForm = {
  nuclear: 0,
  solar: 0,
  wind: 0,
  hydropower: 0,
  geothermal: 0,
  bioenergy: 0,
  gas: 0,
  oil: 0,
  coal: 0,
};

export const useElectricityMixResolver = () => {
  const { t } = useTranslation();

  return useCallback(
    (values: ElectricityMixForm) => {
      const total = Object.values(values).reduce(
        (sum, val) => sum.plus(new Decimal(val)),
        new Decimal(0),
      );

      if (!total.equals(new Decimal(100))) {
        return {
          values: {},
          errors: {
            root: {
              type: "total",
              message: t("The sum of all sources must be 100%"),
            },
          },
        };
      }

      return {
        values,
        errors: {},
      };
    },
    [t],
  );
};

export const ElectricityMix = ({
  averageMix,
  onChangeAverageMix,
  editing,
}: {
  averageMix: boolean;
  onChangeAverageMix?: (newValue: boolean) => void;
  editing?: boolean;
}) => {
  const methods = useFormContext<ElectricityMixForm>();
  const activePlant = useActivePlant();
  const { t } = useTranslation();

  const valueIsValid = (value?: unknown) => {
    // value is a string due to the TextField component
    // ideally: value should be a number right away in the form state
    return exists(value) && !isNaN(Number(value));
  };

  const values = methods.watch();

  const total = useMemo(() => {
    if (!values) return new Decimal(0);

    return Object.values(values).reduce((sum, val) => sum.plus(new Decimal(val)), new Decimal(0));
  }, [values]);

  const error = useMemo(() => {
    if (!activePlant || averageMix) return;

    for (const key of Object.keys(values) as (keyof ElectricityMixForm)[]) {
      if (!valueIsValid(values[key])) {
        return t("Please enter a valid number for all fields");
      }
    }

    if (!total.equals(new Decimal(100))) {
      return t("The sum of all sources must be 100%") + ` (${total}%)`;
    }
  }, [activePlant, averageMix, t, total, values]);

  const labels = useLabels();
  const colors = useColors();

  if (!activePlant) {
    return null;
  }

  return (
    <div>
      <div className="grid grid-cols-[1.681fr_1fr] gap-10 pb-3">
        <div>
          <RadioGroup row>
            <FormControlLabel
              checked={averageMix}
              onChange={() => onChangeAverageMix?.(true)}
              control={<Radio />}
              label={`${t("Average Mix")} (${activePlant.country})`}
              disabled={!editing}
            />
            <FormControlLabel
              checked={!averageMix}
              onChange={() => onChangeAverageMix?.(false)}
              control={<Radio />}
              label={t("Custom Mix")}
              disabled={!editing}
            />
          </RadioGroup>
          <p className="font-medium">
            {averageMix
              ? t("The plant uses the standard electricity mix from the country it's located in.")
              : t(
                  "By selecting this option, I confirm that I have a Guarantee of Origin (GoO) certificate that proves the source of the electricity.",
                )}
          </p>
        </div>
        <div className="justify-self-end">
          <Alert>{t("You can find your energy mix on your energy bill.")}</Alert>
        </div>
      </div>
      {!averageMix && (
        <>
          <CardDivider />
          <div className="grid grid-cols-[1.681fr_1fr] gap-12 mt-3 border-neutral-300">
            <ElectricityMixFields averageMix={averageMix} error={error} isEditMode={editing} />
            <ElectricityMixPreview values={values} labels={labels} colors={colors} />
          </div>
        </>
      )}
    </div>
  );
};

const ElectricityMixFields = ({
  averageMix,
  error,
  isEditMode,
}: {
  averageMix: boolean;
  error?: string;
  isEditMode?: boolean;
}) => {
  const { t } = useTranslation();
  const sharedProps = useMemo(
    () => ({
      isRequired: true,
      minValue: 0,
      maxValue: 100,
      inputProps: { addonRight: "%" },
      isDisabled: averageMix || !isEditMode,
    }),
    [averageMix, isEditMode],
  );

  return (
    <div>
      <div className="grid grid-cols-3 gap-x-8 gap-y-16 mt-8">
        <NumberFieldConnected name="nuclear" label={t("Nuclear")} {...sharedProps} />
        <NumberFieldConnected name="solar" label={t("Solar")} {...sharedProps} />
        <NumberFieldConnected name="wind" label={t("Wind")} {...sharedProps} />
        <NumberFieldConnected name="hydropower" label={t("Hydropower")} {...sharedProps} />
        <NumberFieldConnected name="geothermal" label={t("Geothermal")} {...sharedProps} />
        <NumberFieldConnected name="bioenergy" label={t("Bioenergy")} {...sharedProps} />
        <NumberFieldConnected name="gas" label={t("Gas")} {...sharedProps} />
        <NumberFieldConnected name="oil" label={t("Oil")} {...sharedProps} />
        <NumberFieldConnected name="coal" label={t("Coal and peat")} {...sharedProps} />
      </div>
      <div className="flex gap-3 justify-end mt-6 items-center">
        {error && <p className="text-red-500 font-semibold">{error}</p>}
      </div>
    </div>
  );
};
