import { BarCustomLayerProps, ResponsiveBar } from "@nivo/bar";
import { ComponentProps } from "react";
import { useTranslation } from "react-i18next";
import { ProductDetail } from "../../api/types";
import { Badge } from "../../components/Badge";
import { tooltipStyles } from "../../components/Tooltip";
import { Label12, Label14, Text14 } from "../../components/TypographyOld";
import { formatPrecision } from "../../util/format";

const getGridYValues = (gwp1Value: number, gwp2Value: number) => {
  const maxValue = Math.max(gwp1Value, gwp2Value);

  // Find the magnitude of maxValue (e.g. 100s, 1000s etc)
  const magnitude = Math.pow(10, Math.floor(Math.log10(maxValue)));

  // Round up to next multiple of magnitude/2
  const niceMax = Math.ceil(maxValue / (magnitude / 2)) * (magnitude / 2);

  // Create evenly distributed steps
  return Array.from({ length: 5 }, (_, i) => (niceMax * i) / 4);
};

interface ChartData {
  product: string;
  "GWP-total": number;
}

const BREAKDOWN_INDICATORS = ["GWP-fossil", "GWP-biogenic", "GWP-luluc"];

export const ProductComparisonGWPChart = ({
  product1,
  product2,
}: {
  product1: ProductDetail;
  product2: ProductDetail;
}) => {
  const { t } = useTranslation();
  const styles = getComputedStyle(document.documentElement);
  const co2brown = styles.getPropertyValue("--color-co2brown");
  const neutral300 = styles.getPropertyValue("--color-neutral-300");

  const gwp1 = product1.lca_results?.impact_summary.a1_a3.find(
    (i) => i.indicator_name === "GWP-total",
  );

  const gwp1Breakdown = product1.lca_results?.impact_summary.a1_a3.filter((i) =>
    BREAKDOWN_INDICATORS.includes(i.indicator_name),
  );

  const gwp2 = product2.lca_results?.impact_summary.a1_a3.find(
    (i) => i.indicator_name === "GWP-total",
  );

  const gwp2Breakdown = product2.lca_results?.impact_summary.a1_a3.filter((i) =>
    BREAKDOWN_INDICATORS.includes(i.indicator_name),
  );

  const tooltipData = [gwp1Breakdown, gwp2Breakdown];

  const data = [
    {
      product: product1.name,
      "GWP-total": gwp1?.value || 0,
    },
    {
      product: product2.name,
      "GWP-total": gwp2?.value || 0,
    },
  ];

  const gridYValues = getGridYValues(gwp1?.value || 0, gwp2?.value || 0);

  return (
    <div className="flex flex-col gap-2">
      <Label12>{t("GWP-total")}</Label12>
      <div className="w-full h-60">
        <ResponsiveBar
          data={data}
          keys={["GWP-total"]}
          indexBy="product"
          margin={{ top: 30, right: 0, bottom: 50, left: 60 }}
          padding={0.6}
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          colors={(x) => (x.indexValue === product1.name ? co2brown : neutral300)}
          borderColor={{
            from: "color",
            modifiers: [["darker", 1.6]],
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            renderTick: ({ value, x, y }) => {
              const barData = data.find((d) => d.product === value);
              return (
                <g transform={`translate(${x},${y})`}>
                  <foreignObject x="-70" y="10" width="140" height="60">
                    <div className="flex flex-col items-center">
                      <Label12>{t("GWP-total")}</Label12>
                      <Label14>
                        {formatPrecision(barData?.["GWP-total"] || 0, 4)} {t(gwp1?.unit || "")}
                      </Label14>
                    </div>
                  </foreignObject>
                </g>
              );
            },
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            tickValues: gridYValues,
          }}
          gridYValues={gridYValues}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{
            from: "color",
            modifiers: [["darker", 1.6]],
          }}
          enableLabel={false}
          tooltip={({ index }) => (
            <div className={tooltipStyles()}>
              <Text14>{t("GWP-total Breakdown")}</Text14>
              <hr className="my-2 -mx-3" />
              <div className="flex flex-col gap-1">
                {tooltipData[index]?.map((i) => (
                  <Text14 key={i.indicator_name}>
                    {i.indicator_name}: <br />
                    <strong>
                      {formatPrecision(i.value, 4)} {t(i.unit)}
                    </strong>
                  </Text14>
                ))}
              </div>
            </div>
          )}
          layers={[
            "grid",
            "axes",
            "bars",
            "markers",
            "legends",
            "annotations",
            (props) => <ValueOutside {...props} productNames={data.map((d) => d.product)} />,
          ]}
        />
      </div>
    </div>
  );
};

const ValueOutside = ({
  bars,
  productNames,
}: {
  productNames: string[];
} & BarCustomLayerProps<ChartData>) => {
  const colors: ComponentProps<typeof Badge>["color"][] = ["brown", "gray"];

  return bars.map((bar, index) => {
    const {
      key,
      width,
      x,
      y,
      // data: { value },
    } = bar;
    return (
      <g key={key} transform={`translate(${x}, ${y - 4})`}>
        <foreignObject x={0} y={-25} width={width} height="40">
          <div className="grid items-center justify-center" title={productNames[index]}>
            <Badge size="sm" color={colors[index]}>
              <span className="max-w-[120px] overflow-hidden text-ellipsis">
                {productNames[index]}
              </span>
            </Badge>
          </div>
        </foreignObject>
      </g>
    );
  });
};
