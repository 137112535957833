import { DeleteOutlined } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { useDeletePlant, usePatchPlant } from "../api/endpoints/plants";
import { Plant } from "../api/types";
import { Button } from "../components/Button";
import { CheckboxField } from "../components/CheckboxField";
import { ConfirmationModal } from "../components/ConfirmationModal";
import { PageContainer } from "../components/PageContainer";
import { TopBar } from "../components/TopBar";
import { C2, Heading2 } from "../components/Typography";
import { Text16 } from "../components/TypographyOld";
import { ComboBoxFieldConnected } from "../form-components/ComboBoxFieldConnected";
import { ComboBoxMultiFieldConnected } from "../form-components/ComboBoxMultiFieldConnected";
import { TextFieldConnected } from "../form-components/TextFieldConnected";
import { CustomProductCategoryPicker } from "../page-components/company-settings/CustomProductCategoryPicker";
import { PlantLogoPicker } from "../page-components/company-settings/PlantLogoPicker";
import { HARDE_CODED_MANAGEMENT_SYSTEM_OPTIONS } from "../state/HARD_CODED";
import { useActiveManufacturer } from "../state/manufacturers";
import { useActivePlantControl, usePlants } from "../state/plants";
import { showErrorToast, showSuccessToast } from "../util/toasts";
import { useCountryOptions } from "../util/useCountryOptions";

export type EditPlantFields = Omit<
  Plant,
  "id" | "manufacturer_id" | "electricity_mix" | "waste_treatment"
>;

const usePlantForm = () => {
  const { id } = useParams();
  const { data: plants } = usePlants();
  const plant = plants.find((p) => p.id === id);

  const methods = useForm<EditPlantFields>({
    defaultValues: {
      name: plant?.name,
      city: plant?.city,
      country: plant?.country,
      product_categories: plant?.product_categories,
      company_name: plant?.company_name,
      company_address: plant?.company_address,
      company_post_code: plant?.company_post_code,
      company_email: plant?.company_email,
      company_phone: plant?.company_phone,
      company_organisation_number: plant?.company_organisation_number,
      company_management_systems: plant?.company_management_systems,
      company_image_url: plant?.company_image_url,
    },
  });

  const { activeManufacturer } = useActiveManufacturer();
  const [plantProfileDiffers, _setPlantProfileDiffers] = useState(false);
  const setPlantProfileDiffers = (value: boolean) => {
    if (!plant || !activeManufacturer) return;

    _setPlantProfileDiffers(value);
    if (value) {
      methods.reset({
        ...methods.getValues(),
        company_name: plant.company_name || activeManufacturer.name,
        company_address: plant.company_address || activeManufacturer.address,
        company_post_code: plant.company_post_code || activeManufacturer.post_code,
        company_email: plant.company_email || activeManufacturer.email,
        company_phone: plant.company_phone || activeManufacturer.phone,
        company_organisation_number:
          plant.company_organisation_number || activeManufacturer.organisation_number,
        company_management_systems:
          plant.company_management_systems || activeManufacturer.management_systems,
        company_image_url: plant.company_image_url || activeManufacturer.image_url,
      });
    } else {
      methods.reset({
        ...methods.getValues(),
        company_name: undefined,
        company_address: undefined,
        company_post_code: undefined,
        company_email: undefined,
        company_phone: undefined,
        company_organisation_number: undefined,
        company_management_systems: undefined,
        company_image_url: undefined,
      });
    }
  };

  useEffect(
    function populatePlant() {
      if (plant) {
        methods.reset(plant);

        if (
          plant.company_name ||
          plant.company_address ||
          plant.company_post_code ||
          plant.company_email ||
          plant.company_phone ||
          plant.company_organisation_number ||
          plant.company_management_systems ||
          plant.company_image_url
        ) {
          _setPlantProfileDiffers(true);
        }
      }
    },
    [plant, methods, _setPlantProfileDiffers],
  );

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mutate: patchPlant, isPending } = usePatchPlant();

  const onSubmit = (data: EditPlantFields) => {
    if (!plant) return;

    patchPlant(
      {
        plantId: plant.id,
        plant: {
          ...plant,
          ...data,
          company_name: data.company_name || null,
          company_address: data.company_address || null,
          company_post_code: data.company_post_code || null,
          company_email: data.company_email || null,
          company_phone: data.company_phone || null,
          company_organisation_number: data.company_organisation_number || null,
          company_management_systems: data.company_management_systems || null,
          company_image_url: data.company_image_url || null,
        },
      },
      {
        onSuccess: () => {
          navigate("/company-settings?tab=plants");
        },
        onError: () => {
          showErrorToast(t("We can't remove categories when they're still in use"));
        },
      },
    );
  };

  return {
    methods,
    plant,
    plantProfileDiffers,
    setPlantProfileDiffers,
    onSubmit,
    isPending,
  };
};

const usePlantDeletion = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [deletionStaged, setDeletionStaged] = useState(false);
  const { mutate: deletePlant } = useDeletePlant();

  const { data: plants } = usePlants();
  const { activePlant, setActivePlantId } = useActivePlantControl();

  const nextPlantWithoutDeleted = useMemo(
    () => plants.find((p) => p.id !== activePlant?.id),
    [plants, activePlant?.id],
  );

  const removePlant = () => {
    if (!activePlant) return;

    setDeletionStaged(false);

    deletePlant(
      { plantId: activePlant.id },
      {
        onSuccess: () => {
          if (nextPlantWithoutDeleted) {
            setActivePlantId(nextPlantWithoutDeleted?.id);
          }
          navigate("/company-settings?tab=plants");
          showSuccessToast(
            t("Successfully deleted plant '{{ name }}'", { name: activePlant.name }),
          );
        },
      },
    );
  };

  return {
    deletionStaged,
    removePlant,
    setDeletionStaged,
  };
};

export const EditPlant = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { methods, plant, isPending, onSubmit, plantProfileDiffers, setPlantProfileDiffers } =
    usePlantForm();

  const { deletionStaged, removePlant, setDeletionStaged } = usePlantDeletion();

  const countryOptions = useCountryOptions();

  return (
    <FormProvider {...methods}>
      <PageContainer $as="form" noValidate onSubmit={methods.handleSubmit(onSubmit)}>
        <TopBar
          input={
            <>
              <Button
                type="button"
                intent="secondary"
                isDisabled={isPending}
                onPress={() => navigate("/company-settings?tab=plants")}
              >
                {t("Cancel")}
              </Button>
              <Button type="submit" isDisabled={isPending}>
                {isPending && <CircularProgress size="24px" />}
                {t("Update plant")}
              </Button>
            </>
          }
        />
        <div className="flex flex-col gap-6 py-6">
          <div className="flex flex-col gap-8">
            <Heading2>{plant?.name}</Heading2>
          </div>
          <hr />
          <div className="grid grid-cols-[1fr_2fr] gap-6">
            <div className="space-y-1">
              <C2 $bold>{t("Common information")}</C2>
              <C2>{t("Data related to your plant")}</C2>
            </div>
            <div className="grid grid-cols-2 gap-y-6 gap-x-4">
              <TextFieldConnected
                name="name"
                label={t("Plant Name")}
                isRequired
                placeholder={t("Add name")}
              />
              <div />
              <TextFieldConnected
                name="city"
                label={t("City")}
                isRequired
                placeholder={t("Add city")}
              />
              <ComboBoxFieldConnected
                name="country"
                isRequired
                options={countryOptions}
                label={t("Country")}
                placeholder={t("Add country")}
              />
              <div className="col-span-full">
                <CheckboxField isSelected={plantProfileDiffers} onChange={setPlantProfileDiffers}>
                  {t("My plant profile is different from the company profile")}
                </CheckboxField>
              </div>
              {plantProfileDiffers && (
                <>
                  <div className="col-span-full">
                    <TextFieldConnected name="company_name" label={t("Company name")} />
                  </div>
                  <TextFieldConnected name="company_address" label={t("Address")} />
                  <TextFieldConnected name="company_post_code" label={t("Post code")} />
                  <TextFieldConnected name="company_email" label={t("Email")} />
                  <TextFieldConnected name="company_phone" label={t("Phone")} />
                  <TextFieldConnected
                    name="company_organisation_number"
                    label={t("Organisation number")}
                  />
                  <ComboBoxMultiFieldConnected
                    name="company_management_systems"
                    label={t("Management Systems")}
                    options={HARDE_CODED_MANAGEMENT_SYSTEM_OPTIONS.map((option) => ({
                      id: option,
                      label: option,
                    }))}
                  />
                  {plant && (
                    <div className="col-span-full">
                      <PlantLogoPicker />
                    </div>
                  )}
                </>
              )}
            </div>
            <hr className="col-span-full" />
            <div>
              <C2 $bold>{t("Category")}</C2>
            </div>
            <div>
              <CustomProductCategoryPicker hideHeading layout="double" />
            </div>
            <hr className="col-span-full" />
            <div />
            <div>
              <Button
                type="button"
                intent="tertiary"
                danger
                onPress={() => setDeletionStaged(true)}
              >
                <DeleteOutlined />
                {t("Delete plant")}
              </Button>
            </div>
          </div>
        </div>
      </PageContainer>
      {deletionStaged && (
        <ConfirmationModal
          title={t("Delete Plant")}
          content={
            <>
              <Text16 className="font-bold">{plant?.name}</Text16>
              <Text16>{t("Are you sure you want to delete this plant?")}</Text16>
            </>
          }
          onConfirm={removePlant}
          onCancel={() => setDeletionStaged(false)}
        />
      )}
    </FormProvider>
  );
};
