import { WindowOutlined } from "@mui/icons-material";
import { GridColDef, GridFilterModel, GridRowParams } from "@mui/x-data-grid-pro";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { ProductWithStatus } from "../../api/types";
import { Badge } from "../../components/Badge";
import { DataGrid } from "../../components/DataGrid";
import { Label16 } from "../../components/TypographyOld";
import { useLocale } from "../../localization/useLocale";
import { useActivePlant } from "../../state/plants";
import { formatDate } from "../../util/format";
import { ProductStatusIndicator, useAllProductStatusses } from "./ProductStatusIndicator";
import type { useProductSelection } from "./useProductSelection";

export const ProductsListView = ({
  products,
  searchInput,
  isSelectMode,
  toggleSelect,
  selectedProducts,
  setSelectedProducts,
}: {
  products: ProductWithStatus[];
  searchInput?: string;
} & Pick<
  ReturnType<typeof useProductSelection>,
  "isSelectMode" | "selectedProducts" | "setSelectedProducts" | "toggleSelect"
>) => {
  const activePlant = useActivePlant();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const columnVisibilityModel = useMemo(
    () => ({
      name: true,
      category: true,
      unit: false,
      created_at: true,
      status: true,
      link: false,
    }),
    [],
  );
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    quickFilterValues: [],
  });

  useEffect(() => {
    setFilterModel((value) => ({
      ...value,
      quickFilterValues: searchInput?.split(" ") ?? [],
    }));
  }, [searchInput]);

  const getRow = (product: ProductWithStatus) => ({
    id: product.id,
    name: product.name,
    material: t(product.material),
    status: product.status,
    unit: product.unit,
    lcia_results_timestamp: product.lcia_results_timestamp,
    created_at: new Date(product.created_at),
    gwpTotal: product.gwp_total,
  });

  const locale = useLocale();

  const allMaterials = useMemo(() => {
    return Array.from(new Set(products.map((product) => t(product.material))));
  }, [products, t]);
  const allProductStatusses = useAllProductStatusses();

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "name",
        headerName: t("Name"),
        width: 400,
        renderCell: ({ value }) => (
          <div className="h-full flex items-center gap-2">
            {!isSelectMode && <WindowOutlined />}
            <Label16>{value}</Label16>
          </div>
        ),
      },
      {
        field: "material",
        headerName: t("Material"),
        width: 250,
        filterable: true,
        type: "singleSelect",
        valueOptions: allMaterials,
      },
      {
        field: "unit",
        headerName: t("Unit"),
        width: 120,
      },
      {
        field: "created_at",
        headerName: t("Date added"),
        width: 150,
        renderCell: ({ value }) => (value ? formatDate(value, locale) : "-"),
        filterable: true,
        type: "date",
      },
      {
        field: "status",
        headerName: t("Status"),
        width: 240,
        renderCell: ({ value }) => (
          <div className="h-full flex items-center">
            <ProductStatusIndicator status={value} />
          </div>
        ),
        filterable: true,
        type: "singleSelect",
        getOptionLabel: (value) => allProductStatusses[value as ProductWithStatus["status"]],
        valueOptions: Object.keys(allProductStatusses),
      },
      {
        field: "gwpTotal",
        headerName: t("GWP-total"),
        width: 160,
        sortComparator: (a, b) => {
          if (!a && !b) return 0;
          if (!a) return 1;
          if (!b) return -1;
          return b - a;
        },
        renderCell: ({ value }) =>
          value ? (
            <Badge size="sm" color="brownLight">{`${value.toPrecision(4)} CO2E`}</Badge>
          ) : (
            "-"
          ),
      },
    ],
    [t, allMaterials, allProductStatusses, isSelectMode, locale],
  );

  return (
    <DataGrid
      id={`products-list-${activePlant.id}`}
      rows={products.map(getRow).sort((a, b) => a.name.localeCompare(b.name))}
      columns={columns}
      onRowClick={(params: GridRowParams) => {
        if (isSelectMode) {
          const product = products.find((p) => p.id === params.row.id);
          if (product) toggleSelect(product);
        } else {
          navigate(`/products/${params.row.id}`);
        }
      }}
      filterModel={filterModel}
      onFilterModelChange={setFilterModel}
      initialState={{
        columns: {
          columnVisibilityModel,
        },
      }}
      disableColumnFilter={false}
      checkboxSelection={isSelectMode}
      rowSelectionModel={Array.from(selectedProducts).map((p) => p.id)}
      onRowSelectionModelChange={(newSelectionModel) => {
        setSelectedProducts(new Set(products.filter((p) => newSelectionModel.includes(p.id))));
      }}
    />
  );
};
