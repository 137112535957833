import { CloseOutlined, SearchOutlined } from "@mui/icons-material";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import useDebounce from "react-use/lib/useDebounce";
import { Button } from "../../components/Button";
import { TextField } from "../../components/TextField";

export const SearchControls = memo(function SearchControlsComponent({
  search,
  setSearch,
  placeholder,
}: {
  search: string;
  setSearch: (search: string) => void;
  placeholder?: string;
}) {
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState(search);
  useDebounce(
    () => {
      setSearch(searchInput);
    },
    200,
    [searchInput],
  );

  return (
    <TextField
      aria-label={t("Search")}
      value={searchInput}
      onChange={setSearchInput}
      placeholder={placeholder || t("Search")}
      inputProps={{
        addonLeft: <SearchOutlined />,
        extraRight: (
          <div className="h-10 opacity-50 hover:opacity-100 transition-opacity">
            {searchInput.length > 0 && (
              <Button size="small" intent="tertiary" onPress={() => setSearchInput("")}>
                <CloseOutlined fontSize="small" />
              </Button>
            )}
          </div>
        ),
      }}
    />
  );
});
