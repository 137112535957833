import { ComponentProps, forwardRef, ReactNode } from "react";
import { TextField as TextFieldPrimitive, type TextFieldProps } from "react-aria-components";

import { exists } from "../util/commonUtil";
import { FieldLabel, movingLabelStyles } from "./FieldLabel";
import { FieldText } from "./FieldText";
import { Input } from "./Input";
import { InputWithSubfields } from "./InputWithSubfields";
import { C1 } from "./Typography";

export const TextField = forwardRef<
  HTMLInputElement,
  Omit<TextFieldProps, "children" | "className"> & {
    label?: string;
    labelProps?: ComponentProps<typeof FieldLabel>;
    errorMessage?: string;
    inputProps?: Omit<ComponentProps<typeof Input>, "size">;
    placeholder?: string;
    subfields?: ReactNode;
    isViewOnly?: boolean;
  }
>(({ label, errorMessage, inputProps, labelProps, subfields, placeholder, ...props }, ref) => {
  const _placeholder = exists(label) ? " " : placeholder;

  if (props.isViewOnly) {
    return (
      <div className="min-h-[56px] px-4 flex flex-col justify-center [&_p]:truncate">
        {label && <FieldLabel {...labelProps}>{label}</FieldLabel>}
        <C1>{props.value || props.defaultValue}</C1>
      </div>
    );
  }

  return (
    <TextFieldPrimitive {...props} ref={ref}>
      <div className={movingLabelStyles}>
        {label && (
          <FieldLabel isRequired={props.isRequired} isDisabled={props.isDisabled} {...labelProps}>
            {label}
          </FieldLabel>
        )}
        {subfields ? (
          <InputWithSubfields
            {...inputProps}
            placeholder={_placeholder}
            disabled={props.isDisabled}
            hasError={!!errorMessage}
            subfields={subfields}
            hasLabel={!!label}
          />
        ) : (
          <Input
            {...inputProps}
            placeholder={_placeholder}
            disabled={props.isDisabled}
            hasError={!!errorMessage}
            hasLabel={!!label}
          />
        )}
      </div>
      {errorMessage && <FieldText intent="error">{errorMessage}</FieldText>}
    </TextFieldPrimitive>
  );
});
