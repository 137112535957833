import { Clear, FactoryOutlined } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { Suspense } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { usePostPlant } from "../api/endpoints/plants";
import { Button } from "../components/Button";
import { PageContainer } from "../components/PageContainer";
import { TopBar } from "../components/TopBar";
import {
  ManagePlantFields,
  ManagePlantFieldsT,
} from "../page-components/company-settings/ManagePlantFields";
import { useActiveManufacturer } from "../state/manufacturers";
import { useActivePlantControl, usePlants } from "../state/plants";

const usePlantForm = ({ onCreateSuccess }: { onCreateSuccess?: () => void }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { activeManufacturer } = useActiveManufacturer();
  const { activePlant, setActivePlantId } = useActivePlantControl();

  const methods = useForm<ManagePlantFieldsT>({
    defaultValues: {
      name: undefined,
      city: undefined,
      country: undefined,
      product_categories: [],
    },
  });

  const { data: plants } = usePlants();
  const { mutate: postPlant, isPending } = usePostPlant();

  const onSubmit: SubmitHandler<ManagePlantFieldsT> = (data) => {
    postPlant(
      {
        plant: {
          ...data,
          manufacturer_id: activeManufacturer.id,
          electricity_mix: null,
          waste_treatment: {},
          company_address: null,
          company_email: null,
          company_image_url: null,
          company_management_systems: [],
          company_name: null,
          company_organisation_number: null,
          company_phone: null,
          company_post_code: null,
        },
      },
      {
        onSuccess: (result) => {
          queryClient.setQueryData(["plants"], [...plants, result]);
          setActivePlantId(result.id);
          navigate("/onboarding");
          onCreateSuccess?.();
        },
      },
    );
  };

  return {
    methods,
    activePlant,
    loading: isPending,
    onSubmit,
  };
};

export const AddPlant = ({
  onCreateSuccess,
  disableCancel,
}: {
  onCreateSuccess?: () => void;
  disableCancel?: boolean;
}) => {
  const { t } = useTranslation();
  const { methods, loading, onSubmit } = usePlantForm({ onCreateSuccess });
  const navigate = useNavigate();

  const submitButton = (
    <Button type="submit" isDisabled={loading}>
      {loading && <CircularProgress size="24px" />}
      {t("Add plant")}
    </Button>
  );

  return (
    <FormProvider {...methods}>
      <PageContainer
        $as="form"
        onSubmit={methods.handleSubmit(onSubmit)}
        className="w-full"
        noValidate
      >
        <TopBar
          icon={<FactoryOutlined />}
          title={t("Add plant")}
          input={
            disableCancel ? (
              submitButton
            ) : (
              <Button type="button" intent="tertiary" onPress={() => navigate("/company-settings")}>
                {t("Cancel")}
                <Clear />
              </Button>
            )
          }
          hideNavigation
        />
        <div className="grow flex flex-col gap-8 py-8 px-8">
          <Suspense>
            <ManagePlantFields />
          </Suspense>
        </div>
        {!disableCancel && (
          <div className="fixed bottom-10 left-1/2 -translate-x-1/2 flex items-center justify-center gap-4">
            <Button type="button" intent="secondary" onPress={() => navigate("/company-settings")}>
              {t("Cancel")}
            </Button>
            {submitButton}
          </div>
        )}
      </PageContainer>
    </FormProvider>
  );
};
