import { ArrowDropDownOutlined } from "@mui/icons-material";
import { memo, useMemo } from "react";
import { MenuTrigger } from "react-aria-components";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Button";
import { Menu, MenuItem } from "../../components/Menu";
import { useProductSorting } from "./useProductSorting";

const useSortOptions = () => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      { by: "created_at", direction: "desc" as const, label: t("Recently added") },
      { by: "name", direction: "asc" as const, label: t("Name A-Z") },
      { by: "name", direction: "desc" as const, label: t("Name Z-A") },
      { by: "gwpTotal", direction: "desc" as const, label: t("Highest GWP") },
      { by: "gwpTotal", direction: "asc" as const, label: t("Lowest GWP") },
      { by: "status", direction: "asc" as const, label: t("Status") },
      { by: "material", direction: "asc" as const, label: t("Material A-Z") },
    ],
    [t],
  );
};

export const SortControls = memo(function SortControlsComponent({
  sort,
  setSort,
}: ReturnType<typeof useProductSorting>) {
  const { t } = useTranslation();
  const sortOptions = useSortOptions();

  return (
    <MenuTrigger>
      <Button intent="secondary">
        {t("Sort by")}: {sort.label}
        <ArrowDropDownOutlined />
      </Button>
      <Menu>
        {sortOptions.map((option) => (
          <MenuItem
            size="sm"
            key={`${option.by}-${option.direction}`}
            onAction={() => setSort(option)}
            text={option.label}
          />
        ))}
      </Menu>
    </MenuTrigger>
  );
});
