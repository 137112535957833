import { Add } from "@mui/icons-material";
import { cva } from "class-variance-authority";
import { HTMLProps } from "react";

const styles = cva([
  "flex",
  "items-center",
  "gap-1",
  "py-1.5",
  "px-3",
  "bg-white",
  "hover:bg-neutral-200",
  "text-base",
  "font-sans",
  "text-builtgreen",
  "cursor-pointer",
  "aria-disabled:opacity-50",
  "aria-disabled:pointer-events-none",
  "aria-selected:bg-builtgreen",
  "aria-selected:text-white",
])();

export const ComboBoxItem = ({
  creatable,
  children,
  ...props
}: HTMLProps<HTMLLIElement> & {
  creatable?: boolean;
}) => {
  if (creatable) {
    return (
      <li className={styles} {...props}>
        <Add />
        {children}
      </li>
    );
  }

  return (
    <li className={styles} {...props}>
      {children}
    </li>
  );
};
