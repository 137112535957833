import { VariantProps, cva, cx } from "class-variance-authority";
import { ReactNode } from "react";
import { Button as ButtonPrimitive, ButtonProps } from "react-aria-components";

export const buttonStyles = cva(
  [
    "flex",
    "items-center",
    "justify-center",
    "gap-1",
    "transition-all",
    "outline-hidden",
    "disabled:cursor-not-allowed",
    "font-bold",
  ],
  {
    variants: {
      isDisabled: {
        true: "cursor-not-allowed",
        false: "cursor-pointer",
      },
      intent: {
        primary: [
          "bg-energyyellow",
          "text-builtgreen",
          "border border-transparent",
          "disabled:bg-yellow-50",
          "disabled:text-neutral-300",
        ],
        secondary: ["border", "border-neutral-300", "bg-white", "text-builtgreen"],
        tertiary: ["bg-transparent", "text-builtgreen", "border border-transparent"],
        link: "h-auto inline-flex justify-start! text-bold underline",
      },
      size: {
        small: "text-sm h-10 px-2 min-w-10",
        medium: "text-base h-14 px-4 min-w-14",
        large: "text-lg h-14 px-4 min-w-14",
      },
      square: {
        true: "px-0!",
      },
      danger: {
        true: "disabled:text-neutral-400",
      },
    },
    compoundVariants: [
      {
        intent: "primary",
        size: "medium",
        class: "shadow-e-xs hover:shadow-e-sm disabled:shadow-none disabled:hover:shadow-none",
      },
      {
        intent: "secondary",
        size: "medium",
        class: "hover:shadow-e-xs disabled:shadow-none disabled:hover:shadow-none",
      },
      { danger: true, intent: "secondary", class: "text-red-500" },
      { danger: true, intent: "tertiary", class: "text-red-500" },
      { size: "small", square: true, class: "w-10" },
      { size: "medium", square: true, class: "w-12" },
      { size: "large", square: true, class: "w-14" },
      { intent: "link", class: "px-0!" },
    ],
    defaultVariants: {
      isDisabled: false,
      intent: "primary",
      size: "medium",
      square: false,
    },
  },
);

/**
 * Our main button component, with a few variants for different intents and sizes.
 */
export const Button = ({
  intent,
  size,
  square,
  danger,
  ...props
}: Omit<ButtonProps, "className" | "styles" | "children"> &
  VariantProps<typeof buttonStyles> & {
    children?: ReactNode;
  }) => {
  return (
    <ButtonPrimitive
      className={buttonStyles({ intent, size, square, isDisabled: props.isDisabled, danger })}
      {...props}
    />
  );
};

const toggleButtonStyles = cva([], {
  variants: {
    active: {
      true: "text-builtgreen",
      false: "text-neutral-400",
    },
  },
  defaultVariants: {
    active: false,
  },
});

export const ToggleButton = ({
  intent,
  size,
  square,
  active,
  danger,
  ...props
}: Omit<ButtonProps, "className" | "styles" | "children"> &
  VariantProps<typeof buttonStyles> &
  VariantProps<typeof toggleButtonStyles> & {
    children?: ReactNode;
  }) => {
  return (
    <ButtonPrimitive
      className={cx(
        buttonStyles({ intent, size, square, isDisabled: props.isDisabled, danger }),
        toggleButtonStyles({ active }),
      )}
      {...props}
    />
  );
};
