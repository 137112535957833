import { useMemo } from "react";
import { ProductDetail, ProgramOperator } from "../api/types";

export const useFilterDocumentsByPO = ({
  documents,
  programOperatorId,
}: {
  documents: ProductDetail["documents"];
  programOperatorId: ProgramOperator["id"] | null;
}) => {
  return useMemo(() => {
    const withoutPO = documents.filter((doc) => !doc.program_operator_id);
    if (!programOperatorId) {
      return withoutPO;
    }

    const fromPO = documents.filter((doc) => doc.program_operator_id === programOperatorId);
    return [...withoutPO, ...fromPO];
  }, [documents, programOperatorId]);
};
