import { cva } from "class-variance-authority";
import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react";
import { useMemo } from "react";
import { Button as ButtonPrimitive } from "react-aria-components";

const buttonStyles = cva(["flex", "items-center", "justify-center", "size-14", "outline-none"], {
  variants: {
    isDisabled: {
      true: ["text-neutral-300", "cursor-not-allowed"],
      false: ["cursor-pointer"],
    },
    isActive: {
      true: ["bg-steelblue/5", "text-steelblue"],
    },
  },
  compoundVariants: [
    {
      isDisabled: true,
      isActive: true,
      className: "hover:bg-transparent",
    },
    {
      isDisabled: true,
      isActive: false,
      className: "hover:bg-transparent",
    },
    {
      isDisabled: false,
      isActive: true,
      className: "",
    },
    {
      isDisabled: false,
      isActive: false,
      className: "hover:bg-neutral-50",
    },
  ],
  defaultVariants: {
    isDisabled: false,
    isActive: false,
  },
});

const SIMPLE_PAGINATION_THRESHOLD = 5;

/**
 * Containing pretty funky logic to keep the width constant when skipping through pages.
 *
 * @param page - The current page number, starting from 0
 * @param totalPages - The total number of pages
 * @param onPageChange - The function to call when a page is clicked
 */
export const Pagination = ({
  page,
  totalPages,
  onPageChange,
}: {
  page: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}) => {
  const firstPage = 0;
  const lastPage = totalPages - 1;

  const middlePages = useMemo(() => {
    if (totalPages <= SIMPLE_PAGINATION_THRESHOLD) return [...Array(totalPages).keys()];

    if (page === firstPage || page === firstPage + 1)
      return [page, page + 1, page + 2].filter((page) => page <= lastPage);

    if (page === lastPage || page === lastPage - 1)
      return [page - 2, page - 1, page].filter((page) => page >= firstPage);

    return [page - 1, page, page + 1].filter((page) => page >= firstPage && page <= lastPage);
  }, [page, firstPage, lastPage, totalPages]);

  return (
    <div className="flex items-center divide-x divide-neutral-300 border border-neutral-300">
      <ButtonPrimitive
        className={buttonStyles()}
        isDisabled={page === firstPage}
        onPress={() => onPageChange(page - 1)}
      >
        <ChevronLeftIcon />
      </ButtonPrimitive>
      {page === lastPage && totalPages > SIMPLE_PAGINATION_THRESHOLD && (
        <ButtonPrimitive className={buttonStyles()} onPress={() => onPageChange(0)}>
          1
        </ButtonPrimitive>
      )}
      {page > firstPage && totalPages > SIMPLE_PAGINATION_THRESHOLD && (
        <ButtonPrimitive
          isDisabled={page === lastPage}
          className={buttonStyles({ isDisabled: page === lastPage })}
          onPress={() => onPageChange(0)}
        >
          ...
        </ButtonPrimitive>
      )}
      {middlePages.map((p) => (
        <ButtonPrimitive
          key={p}
          className={buttonStyles({ isActive: page === p })}
          onPress={() => onPageChange(p)}
        >
          {p + 1}
        </ButtonPrimitive>
      ))}
      {page < lastPage && totalPages > SIMPLE_PAGINATION_THRESHOLD && (
        <ButtonPrimitive
          className={buttonStyles({ isDisabled: page === firstPage })}
          onPress={() => onPageChange(lastPage)}
          isDisabled={page === firstPage}
        >
          ...
        </ButtonPrimitive>
      )}
      {page === firstPage && totalPages > SIMPLE_PAGINATION_THRESHOLD && (
        <ButtonPrimitive className={buttonStyles()} onPress={() => onPageChange(lastPage)}>
          {totalPages}
        </ButtonPrimitive>
      )}
      <ButtonPrimitive
        className={buttonStyles()}
        isDisabled={page === lastPage}
        onPress={() => onPageChange(page + 1)}
      >
        <ChevronRightIcon />
      </ButtonPrimitive>
    </div>
  );
};
