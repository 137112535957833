import {
  Business,
  Circle,
  Edit,
  FactoryOutlined,
  Search,
  TokenOutlined,
  WindowOutlined,
} from "@mui/icons-material";
import { ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useProductionProcesses } from "../api/endpoints/production-processes";
import { ProgressIndicatorIcon } from "../components/ProgressIndicatorIcon";
import { useElementaries } from "../state/elementaries";
import { useMaterials } from "../state/materials";
import { useActiveOnboardingStep } from "../state/onboarding";
import { useActivePlant } from "../state/plants";
import { useActivePlantProducts } from "../state/products";
import { exists } from "../util/commonUtil";

interface NavItemBase {
  name: string;
  icon?: ReactNode;
  hideNavbar?: boolean;
  step?: number;
}

export interface NavItemLink extends NavItemBase {
  path: string;
}

interface NavItemButton extends NavItemBase {
  onPress: () => void;
}
interface NavItemGroup {
  name: string;
  icon?: ReactNode;
  children: NavItem[];
}
export type NavItem = NavItemGroup | NavItemLink | NavItemButton;

export const isGroup = (item: NavItem): item is NavItemGroup => {
  return "children" in item;
};
export const isLink = (item: NavItem): item is NavItemLink => {
  return "path" in item;
};
export const isButton = (item: NavItem): item is NavItemButton => {
  return "onPress" in item;
};

export const useAllPages = (): Record<string, NavItem> => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      products: { name: t("Products"), path: "/products", icon: <WindowOutlined /> },
      onboarding: { name: t("Data setup"), path: "/onboarding", icon: <Circle /> },
      plant: { name: t("Production"), path: "/production", icon: <FactoryOutlined /> },
      materials: {
        name: t("Materials"),
        path: "/suppliers-and-materials",
        icon: <TokenOutlined />,
      },
      companySettings: {
        name: t("Settings"),
        path: "/company-settings",
        icon: <Business />,
      },
      edit: {
        name: t("Edit"),
        path: "/edit",
        icon: <Edit />,
        hideNavbar: true,
      },
      editProductDetails: {
        name: t("Create a product profile"),
        path: "/edit/product-details",
        hideNavbar: true,
        step: 1,
      },
      editProductSpecs: {
        name: t("Create a product profile"),
        path: "/edit/product-specs",
        hideNavbar: true,
        step: 2,
      },
      editProductMaterials: {
        name: t("Add ingredients"),
        path: "/edit/product-recipe",
        hideNavbar: true,
        step: 3,
      },
      editProductProductionProcess: {
        name: t("Choose a production process"),
        path: "/edit/product-production-process",
        hideNavbar: true,
        step: 4,
      },
      editProcessFlow: {
        name: t("Model Flow"),
        path: "/edit/process-flow",
        hideNavbar: true,
      },
      editProcessMaterials: {
        name: t("Model Materials"),
        path: "/edit/process-materials",
        hideNavbar: true,
      },
      bulkUpload: {
        name: t("Bulk Upload"),
        path: "/bulk-upload/*",
        hideNavbar: true,
      },
      search: { name: t("Search"), path: "/search", icon: <Search /> },
      newProductionProcess: {
        name: t("Add production process"),
        path: "/production/processes/new",
        hideNavbar: true,
      },
      editProductionProcess: {
        name: t("Add production process"),
        path: "/production/processes/:id/edit",
        hideNavbar: true,
      },
      editPlant: {
        name: t("Edit plant"),
        path: "/plants/:id/edit",
      },
      addPlant: {
        name: t("Add plant"),
        path: "/plants/new",
        hideNavbar: true,
      },
      productDetails: {
        name: t("Product"),
        path: "/products/:id/",
        hideNavbar: true,
      },
      productComparison: {
        name: t("Product comparison"),
        path: "/products/:id/compare/:otherId",
        hideNavbar: true,
      },
    }),
    [t],
  );
};

const useProductsPageChildren = () => {
  const { data: products } = useActivePlantProducts();
  const { elementariesMap } = useElementaries();
  const { t } = useTranslation();

  return useMemo(() => {
    const productsElementaries = new Set(
      products?.map((product) => elementariesMap[product.elementary_id]),
    );

    if (productsElementaries.size < 2) {
      return {};
    }

    return {
      children: [
        {
          name: t("All products"),
          path: "/products",
        },
        ...Array.from(productsElementaries).map((elementary) => ({
          name: t(elementary.name),
          path: `/products?elementary=${elementary.id}`,
        })),
      ],
    };
  }, [products, elementariesMap, t]);
};

const useMaterialsPageChildren = () => {
  const { rawMaterials, packagingMaterials } = useMaterials();
  const { t } = useTranslation();

  return useMemo(() => {
    const ownProducts = rawMaterials.filter((material) => exists(material.prechain_product));
    const supplierProducts = rawMaterials.filter((material) => exists(material.supplier_product));

    const hasAtLeastTwoCategories =
      [supplierProducts, ownProducts, packagingMaterials]
        .map((items) => items.length > 0)
        .filter(Boolean).length > 1;

    if (!hasAtLeastTwoCategories) {
      return {};
    }

    return {
      children: [
        {
          name: t("All materials"),
          path: "/suppliers-and-materials",
        },
        ...(supplierProducts.length > 0
          ? [
              {
                name: t("Supplier materials"),
                path: "/suppliers-and-materials?type=supplier",
              },
            ]
          : []),
        ...(packagingMaterials.length > 0
          ? [
              {
                name: t("Packaging"),
                path: "/suppliers-and-materials?type=packaging",
              },
            ]
          : []),
        ...(ownProducts.length > 0
          ? [
              {
                name: t("Own materials"),
                path: "/suppliers-and-materials?type=own",
              },
            ]
          : []),
      ],
    };
  }, [rawMaterials, packagingMaterials, t]);
};

const useProductionPageChildren = () => {
  const activePlant = useActivePlant();
  const { data: productionProcesses } = useProductionProcesses({ plantId: activePlant.id });
  const { t } = useTranslation();

  return useMemo(() => {
    const countByType: Record<string, number> = {};
    return {
      children: [
        { name: t("Overview"), path: "/production" },
        { name: t("General"), path: "/production?section=general" },
        ...(productionProcesses?.map((process) => {
          const key = process.name;
          countByType[key] = (countByType[key] ?? 0) + 1;
          const count = countByType[key];
          return {
            name: process.name + (count > 1 ? ` (${count})` : ""),
            path: `/production?section=${process.id}`,
          };
        }) ?? []),
      ],
    };
  }, [productionProcesses, t]);
};

export const useNavbarItems = (): NavItem[] => {
  const pages = useAllPages();
  const { step: onboardingStep } = useActiveOnboardingStep();
  const productsPageChildren = useProductsPageChildren();
  const materialsPageChildren = useMaterialsPageChildren();
  const productionPageChildren = useProductionPageChildren();

  const isOnboarding = onboardingStep < 4;

  return useMemo(
    () => [
      ...(isOnboarding
        ? [
            {
              ...pages.onboarding,
              icon: <ProgressIndicatorIcon done={onboardingStep / 4} />,
            },
          ]
        : []),
      ...(onboardingStep > 2 ? [{ ...pages.products, ...productsPageChildren }] : []),
      { ...pages.materials, ...materialsPageChildren },
      ...(onboardingStep > 1 ? [{ ...pages.plant, ...productionPageChildren }] : []),
      ...(!isOnboarding ? [pages.search] : []),
    ],
    [
      isOnboarding,
      pages,
      onboardingStep,
      productsPageChildren,
      materialsPageChildren,
      productionPageChildren,
    ],
  );
};
