import { useParams } from "react-router";
import { PrechainProductForm } from "../page-components/suppliers-and-materials/PrechainProductForm";
import { usePrechainProduct } from "../state/prechainProducts";

export const EditPrechainProduct = () => {
  const { id } = useParams<{ id: string }>();
  const { data: prechainProduct } = usePrechainProduct(id ?? "");

  if (!prechainProduct) return null;

  return <PrechainProductForm prechainProduct={prechainProduct} />;
};
