import { CloseOutlined, DeleteOutlined, GroupWorkOutlined } from "@mui/icons-material";
import { ReactNode, useMemo } from "react";
import { DialogTrigger } from "react-aria-components";
import { useTranslation } from "react-i18next";
import { PlantMaterial } from "../../api/types";
import { Button } from "../../components/Button";
import { CheckboxField } from "../../components/CheckboxField";
import { Modal } from "../../components/Modal";
import { useElementaries } from "../../state/elementaries";
import { DeleteMaterialsModal } from "./DeleteMaterialsModal";
import { SiloModalBody } from "./SiloModalBody";
import { useMaterialsDeletion } from "./useMaterialsDeletion";
import type { useMaterialSelection } from "./useMaterialSelection";

export const MaterialsMultiSelectActionBar = ({
  selectedMaterials,
  materials,
  isSelectMode,
  toggleSelectMode,
  toggleSelectAll,
  resetSelection,
  extraRightSideContent,
}: {
  materials: PlantMaterial[];
  extraRightSideContent?: ReactNode;
} & Pick<
  ReturnType<typeof useMaterialSelection>,
  "selectedMaterials" | "isSelectMode" | "toggleSelectMode" | "toggleSelectAll" | "resetSelection"
>) => {
  const { t } = useTranslation();
  const { removeMaterials } = useMaterialsDeletion();
  const { elementariesMap } = useElementaries();

  const canSiloMaterials = useMemo(() => {
    const mats = [...selectedMaterials];
    return (
      mats.length > 1 &&
      mats.every(
        (material) =>
          !material.prechain_product &&
          material.supplier_product.elementary_id === mats[0].supplier_product.elementary_id,
      ) &&
      elementariesMap[mats[0].supplier_product.elementary_id]?.type === "raw_materials"
    );
  }, [selectedMaterials, elementariesMap]);

  return (
    <div className="flex items-center justify-between gap-4">
      <div className="flex items-center gap-4 h-10">
        <CheckboxField onChange={toggleSelectMode} isSelected={isSelectMode}>
          {isSelectMode
            ? t("{{ count }} selected", { count: selectedMaterials.size })
            : t("Select multiple materials")}
        </CheckboxField>
        {isSelectMode && (
          <Button intent="secondary" onPress={toggleSelectAll}>
            {selectedMaterials.size < materials.length ? t("Select all") : t("Unselect all")}
          </Button>
        )}
      </div>
      <div className="flex items-center gap-2">
        {isSelectMode && canSiloMaterials && (
          <DialogTrigger>
            <Button intent="tertiary">
              {t("Group {{ count }} materials", { count: selectedMaterials.size })}
              <GroupWorkOutlined />
            </Button>
            <Modal size="lg">
              {({ close }) => (
                <SiloModalBody
                  close={close}
                  initialMaterials={Array.from(selectedMaterials).map((m) => m.supplier_product)}
                  onCreateSuccess={resetSelection}
                />
              )}
            </Modal>
          </DialogTrigger>
        )}
        {isSelectMode && selectedMaterials.size > 0 && (
          <DialogTrigger>
            <Button intent="tertiary">
              {t("Delete {{ count }} materials", { count: selectedMaterials.size })}
              <DeleteOutlined />
            </Button>
            <DeleteMaterialsModal
              selectedMaterials={selectedMaterials}
              onSubmit={async (close) => {
                await removeMaterials([...selectedMaterials]);
                close();
                resetSelection();
              }}
            />
          </DialogTrigger>
        )}
        {isSelectMode && (
          <Button intent="tertiary" onPress={toggleSelectMode}>
            {t("Cancel")}
            <CloseOutlined />
          </Button>
        )}
        {extraRightSideContent}
      </div>
    </div>
  );
};
