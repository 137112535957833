import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useDebounce from "react-use/lib/useDebounce";
import { SupplierMaterial } from "../../../api/types";
import { Alert } from "../../../components/Alert";
import { Heading5 } from "../../../components/Typography";
import { useElementaries } from "../../../state/elementaries";
import { useEpd, useSearchEPDs } from "../../../state/epds";
import { FormFields, SecondaryDatasetWithOptions } from "../types";
import { PrimaryData } from "./PrimaryData";
import { SecondaryData } from "./SecondaryData";

const useEnvironmentalDataSearch = ({
  epdId,
  productName,
  supplierName,
  existingMaterial,
}: {
  epdId: string | null;
  productName?: string;
  supplierName?: string;
  existingMaterial?: SupplierMaterial | null;
}) => {
  const { data: epd } = useEpd(epdId || undefined);
  const { data: primaryData = [], isPending, mutate: search } = useSearchEPDs();

  const methods = useFormContext<FormFields>();

  useDebounce(
    () => {
      if (!productName || !supplierName) return;

      search({
        searchTerm: [productName, supplierName].join(" ").trim(),
        language: "en",
        maxResults: 10,
      });

      if (
        !existingMaterial ||
        (existingMaterial.name !== productName &&
          existingMaterial.supplier_plant?.supplier_company.name !== supplierName)
      ) {
        methods.setValue("epdId", "");
      }
    },
    existingMaterial ? 0 : 500,
    [productName, supplierName, existingMaterial],
  );

  // Deduplicated primary data
  const resultingPrimaryData = useMemo(() => {
    const uniqueData = new Map();
    [...(epd ? [epd] : []), ...primaryData].forEach((item) => {
      uniqueData.set(item.id, item);
    });
    return Array.from(uniqueData.values());
  }, [epd, primaryData]);

  return { primaryData: resultingPrimaryData, isPending };
};

const LinkEnvironmentalDataMain = ({
  isEditing,
  existingMaterial,
}: {
  isEditing: boolean;
  existingMaterial?: SupplierMaterial | null;
}) => {
  const { t } = useTranslation();
  const methods = useFormContext<FormFields>();

  const { elementariesMap } = useElementaries();
  const elementaryId = methods.watch("elementaryId");
  const epdId = methods.watch("epdId");

  const materialMatching = elementariesMap[elementaryId]?.matching;

  const productName = methods.watch("productName");
  const supplierCompanyName = methods.watch("supplierCompanyName");

  const supplierName = existingMaterial
    ? existingMaterial.supplier_plant?.supplier_company.name
    : supplierCompanyName;

  const { primaryData } = useEnvironmentalDataSearch({
    epdId,
    productName,
    supplierName,
    existingMaterial,
  });

  const linkedSecondaryData: SecondaryDatasetWithOptions[] = useMemo(
    () =>
      existingMaterial?.secondary_datasets ||
      materialMatching?.map((match) => ({ activity_name: match[0] })) ||
      [],
    [existingMaterial, materialMatching],
  );

  return (
    <>
      {(isEditing || epdId.length > 0) && (
        <PrimaryData isEditing={isEditing} primaryData={primaryData} />
      )}
      {(isEditing || epdId.length === 0) && (
        <SecondaryData isEditing={isEditing} data={linkedSecondaryData} />
      )}
      {!epdId && (
        <Alert header={!isEditing && t("Using conservative secondary data.")}>
          {t(
            "Request an EPD from your supplier to improve the accuracy of environmental impact calculations.",
          )}
        </Alert>
      )}
    </>
  );
};

export const LinkEnvironmentalData = ({
  isEditing,
  existingMaterial,
}: {
  isEditing: boolean;
  existingMaterial?: SupplierMaterial | null;
}) => {
  const { t } = useTranslation();
  const methods = useFormContext<FormFields>();

  const { elementariesMap } = useElementaries();
  const elementaryId = methods.watch("elementaryId");

  if (!elementaryId) {
    return null;
  }

  // An empty matching means: This material is considered without impacts (it is "burden free").
  // It does not make sense to let the user search for EPDs in this case.
  const hasEmptyMatching = elementariesMap[elementaryId]?.matching?.length === 0;

  return (
    <>
      <Heading5>{t("Linked environmental data")}</Heading5>
      {hasEmptyMatching ? (
        <Alert>
          {t(
            "The production of this material is modeled as burden-free. It is not associated with any dataset.",
          )}
        </Alert>
      ) : (
        <LinkEnvironmentalDataMain isEditing={isEditing} existingMaterial={existingMaterial} />
      )}
    </>
  );
};
