import { ArrowBack, DeleteOutlined } from "@mui/icons-material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Elementary, PlantMaterialSupplier, SupplierMaterial } from "../../api/types";
import { Button } from "../../components/Button";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import { InfoTooltip } from "../../components/InfoTooltip";
import { PageContainer } from "../../components/PageContainer";
import { TopBar } from "../../components/TopBar";
import { Heading2, Heading5 } from "../../components/Typography";
import { Text16 } from "../../components/TypographyOld";
import { coreIndicators, filterImpact } from "../../lib/impact";
import { useElementaries } from "../../state/elementaries";
import { useAddMaterial, useMaterials, useUpdateMaterial } from "../../state/materials";
import { useActivePlant } from "../../state/plants";
import {
  useAddSupplier,
  useAddSupplierPlant,
  useSupplierPlants,
  useSuppliers,
} from "../../state/suppliers";
import { useGetLinkWithParams } from "../../url/useGetLinkWithParams";
import { showSuccessToast } from "../../util/toasts";
import { GWPTotalBreakdown } from "./GWPTotalBreakdown";
import { HazardousSubstancesForm } from "./HazardousSubstancesForm";
import { ImpactFactors } from "./ImpactFactors";
import { LinkEnvironmentalData } from "./link-environmental-data/LinkEnvironmentalData";
import { MaterialBasics } from "./MaterialBasics";
import { RecycledContentForm } from "./RecycledContentForm";
import { SupplyRoute } from "./SupplyRoute";
import { FormFields } from "./types";
import { useMaterialsDeletion } from "./useMaterialsDeletion";

// TODO: Get the complexity down by extracting some of the functions
// eslint-disable-next-line complexity
export const SuppliersAndMaterialsForm = ({
  existingMaterial,
  type,
}: {
  existingMaterial: SupplierMaterial | null;
  type?: Elementary["type"];
}) => {
  const [isEditing, setEditing] = useState(!existingMaterial);
  const navigate = useNavigate();
  const getLinkWithParams = useGetLinkWithParams();
  const { t } = useTranslation();

  const { deletionStaged, setDeletionStaged, removeMaterials } = useMaterialsDeletion();

  const { data: suppliers } = useSuppliers();
  const activePlant = useActivePlant();
  const { elementariesMap } = useElementaries();

  const { rawMaterials, packagingMaterials } = useMaterials();

  const [supplierId, setSupplierId] = useState<string | undefined>(
    existingMaterial?.supplier_plant?.supplier_company_id,
  );
  const { data: supplierPlants = [] } = useSupplierPlants(supplierId);
  const [hasSupplyRoute, setHasSupplyRoute] = useState<boolean>(
    existingMaterial?.elementary_id
      ? elementariesMap[existingMaterial.elementary_id].category.toLowerCase() !== "water"
      : false,
  );
  const [canBeHazardous, setCanBeHazardous] = useState<boolean>(
    existingMaterial?.elementary_id
      ? elementariesMap[existingMaterial.elementary_id].can_be_hazardous
      : false,
  );
  const [customizableSecondaryShare, setCustomizableSecondaryShare] = useState<boolean>(
    existingMaterial?.elementary_id
      ? elementariesMap[existingMaterial.elementary_id].customizable_secondary_share
      : false,
  );

  const defaultTransportation = useMemo(() => [{ modality: "truck", distance: null }], []);

  const navigateBack = useCallback(() => {
    navigate(getLinkWithParams("/suppliers-and-materials"));
  }, [navigate, getLinkWithParams]);

  const methods = useForm<FormFields>({
    defaultValues: {
      elementaryId: existingMaterial?.elementary_id || "",
      supplierCompanyName: existingMaterial?.supplier_plant?.supplier_company.name || "",
      productName: existingMaterial?.name || "",
      supplierPlantName: existingMaterial?.supplier_plant?.name || "",
      supplierPlantCountry: existingMaterial?.supplier_plant?.country || "",
      transportation: existingMaterial?.transportation || defaultTransportation,
      epdId: existingMaterial?.epd_id || "",
      hazardousSubstances: existingMaterial?.hazardous_substances || [],
      secondaryPercentage: existingMaterial?.secondary_percentage || null,
    },
  });

  const onElementaryChange = useCallback(() => {
    const elementaryId = methods.getValues("elementaryId");
    const elementary = elementaryId ? elementariesMap[elementaryId] : null;

    if (elementary && elementary.can_be_hazardous) {
      setCanBeHazardous(true);
    } else {
      setCanBeHazardous(false);
      methods.setValue("hazardousSubstances", []);
    }

    setCustomizableSecondaryShare(!!elementary?.customizable_secondary_share);
    methods.setValue("secondaryPercentage", null);

    if (elementary && elementary.category.toLowerCase() === "water") {
      setHasSupplyRoute(false);
      methods.setValue("transportation", []);
      methods.setValue("supplierPlantName", "");
      methods.setValue("supplierCompanyName", "");
      methods.setValue("supplierPlantCountry", "");
    } else {
      setHasSupplyRoute((prev) => {
        if (prev === false) {
          methods.setValue("transportation", defaultTransportation);
        }
        return true;
      });
    }
  }, [methods, elementariesMap, defaultTransportation]);

  const onSupplierCompanyChange = useCallback(() => {
    methods.setValue("supplierPlantName", "");
    methods.setValue("supplierPlantCountry", "");
    methods.setValue("transportation", defaultTransportation);
  }, [methods, defaultTransportation]);

  const onSupplierPlantChange = useCallback(() => {
    const supplierPlantName = methods.getValues("supplierPlantName");
    const existingSupplierPlant = supplierPlants?.find((s) => s.name === supplierPlantName);

    if (!existingSupplierPlant) {
      methods.setValue("supplierPlantCountry", "");
      methods.setValue("transportation", defaultTransportation);
      return;
    }

    methods.setValue("supplierPlantCountry", existingSupplierPlant.country);

    const plantMaterials = [...rawMaterials, ...packagingMaterials]
      .filter((x) => x.supplier_product)
      .map((x) => x.supplier_product)
      .filter((mat) => mat.supplier_plant_id === existingSupplierPlant.id);

    if (plantMaterials.length > 0) {
      const mostRecentMaterial = plantMaterials.at(-1)!;
      methods.setValue("transportation", mostRecentMaterial.transportation);
    } else {
      methods.setValue("transportation", defaultTransportation);
    }
  }, [methods, supplierPlants, rawMaterials, packagingMaterials, defaultTransportation]);

  useEffect(() => {
    const subscription = methods.watch((_, { name }) => {
      if (name === "elementaryId") {
        onElementaryChange();
      } else if (name === "supplierCompanyName") {
        onSupplierCompanyChange();
      } else if (name === "supplierPlantName") {
        onSupplierPlantChange();
      }
    });
    return () => subscription.unsubscribe();
  }, [methods, onElementaryChange, onSupplierCompanyChange, onSupplierPlantChange]);

  const supplierCompanyName = methods.watch("supplierCompanyName");
  const supplierPlantName = methods.getValues("supplierPlantName");

  useEffect(() => {
    const existingSupplier = suppliers.find((s) => s.name === supplierCompanyName);

    if (existingSupplier) {
      setSupplierId(existingSupplier.id);
    } else {
      setSupplierId(undefined);
    }
  }, [supplierCompanyName, suppliers]);

  const { mutate: addSupplier } = useAddSupplier();
  const { mutate: addSupplierPlant } = useAddSupplierPlant();
  const { mutate: addMaterial } = useAddMaterial();
  const { mutate: updateMaterial } = useUpdateMaterial();

  const onSubmit = async (fields: FormFields) => {
    if (!activePlant) return;

    try {
      const materialHasSupplier =
        fields.supplierCompanyName && fields.supplierCompanyName.length > 0;

      let supplier = suppliers.find((s) => s.name === fields.supplierCompanyName);

      if (materialHasSupplier && !supplier) {
        supplier = await new Promise((resolve) =>
          addSupplier({ name: fields.supplierCompanyName }, { onSuccess: (data) => resolve(data) }),
        );
      }

      let supplierPlant = supplierPlants.find((s) => s.name === fields.supplierPlantName);

      if (materialHasSupplier && supplier && !supplierPlant) {
        supplierPlant = await new Promise((resolve) =>
          addSupplierPlant(
            {
              supplierId: supplier.id,
              supplierPlant: {
                name: fields.supplierPlantName,
                country: fields.supplierPlantCountry,
              },
            },
            {
              onSuccess: (data) => resolve(data),
            },
          ),
        );
      }

      const material = {
        id: existingMaterial?.id,
        name: fields.productName,
        elementary_id: fields.elementaryId,
        supplier_plant_id: materialHasSupplier ? (supplierPlant?.id ?? null) : null,
        plant_id: activePlant.id,
        epd_id: fields.epdId || null,
        transportation: hasSupplyRoute
          ? fields.transportation.map((t) => ({
              ...t,
              load: null,
              utilization: null,
              distance: t.distance ?? 0,
            }))
          : [],
        hazardous_substances: fields.hazardousSubstances,
        secondary_percentage: fields.secondaryPercentage,
      };

      if (material.id) {
        updateMaterial(
          {
            material: { ...material, id: material.id },
          },
          {
            onSuccess: () => {
              navigateBack();
              showSuccessToast(t("Material changed"));
            },
          },
        );
      } else {
        addMaterial(
          { material },
          {
            onSuccess: () => {
              navigateBack();
              showSuccessToast(t("New material created"));
            },
          },
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  const isInSilo = useMemo(() => {
    if (!existingMaterial) return false;
    const silos = rawMaterials.filter((m) => m.silo).map((m) => m.silo);
    return silos.some((s) => s.items.some((i) => i.supplier_product?.id === existingMaterial.id));
  }, [rawMaterials, existingMaterial]);

  return (
    <FormProvider {...methods}>
      <PageContainer $as="form" onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        <TopBar
          title={!isEditing && existingMaterial ? t("Back to material overview") : undefined}
          icon={!isEditing && existingMaterial ? <ArrowBack /> : undefined}
          onIconClick={navigateBack}
          input={
            isEditing ? (
              <>
                <Button
                  type="button"
                  intent="tertiary"
                  onPress={() => {
                    if (!existingMaterial) {
                      navigateBack();
                    } else {
                      methods.reset();
                      setEditing(false);
                    }
                  }}
                >
                  {t("Cancel")}
                </Button>
                <Button type="submit" intent="primary">
                  {t("Save material")}
                </Button>
              </>
            ) : (
              <Button intent="secondary" type="button" onPress={() => setEditing(true)}>
                {t("Edit material")}
              </Button>
            )
          }
        />
        <div className="flex flex-col mt-6 mb-8 h-full">
          <Heading2>
            {methods.watch("productName") || (
              <span className="text-neutral-400">{t("New material")}</span>
            )}
          </Heading2>
          <hr className="mt-6 border-neutral-200" />
          <div className="grid grid-cols-2 h-full divide-x">
            <div className="flex flex-col gap-4 py-6 pr-6">
              <Heading5>{t("Material details")}</Heading5>
              <MaterialBasics
                isEditing={isEditing}
                existingMaterial={existingMaterial}
                type={type}
              />
              {hasSupplyRoute && (
                <SupplyRoute
                  isEditing={isEditing}
                  supplierPlants={supplierPlants}
                  suppliers={suppliers}
                  supplierPlantExists={!!supplierPlants.find((s) => s.name === supplierPlantName)}
                />
              )}
              {canBeHazardous && (
                <>
                  <hr className="border-gray-200" />
                  <HazardousSubstancesForm isEditing={isEditing} />
                </>
              )}
              {customizableSecondaryShare && (
                <>
                  <hr className="border-gray-200" />
                  <RecycledContentForm isEditing={isEditing} />
                </>
              )}
              {existingMaterial && isEditing && (
                <>
                  <hr className="border-gray-200" />
                  <div className="flex items-center">
                    <Button
                      type="button"
                      onPress={() => setDeletionStaged(true)}
                      intent="tertiary"
                      danger
                      isDisabled={isInSilo}
                    >
                      <DeleteOutlined />
                      {t("Delete material")}
                    </Button>
                    {isInSilo && (
                      <InfoTooltip>
                        {t(
                          "This material is part of a silo. To delete it, you need to dissolve the silo first.",
                        )}
                      </InfoTooltip>
                    )}
                  </div>
                </>
              )}
            </div>
            <div className="flex flex-col gap-4 py-6 pl-6">
              <LinkEnvironmentalData isEditing={isEditing} existingMaterial={existingMaterial} />
              {!isEditing && existingMaterial?.impacts && (
                <>
                  <GWPTotalBreakdown impacts={existingMaterial.impacts} />
                  <hr className="border-gray-200" />
                  <div className="grid grid-cols-2 gap-4">
                    <ImpactFactors
                      impacts={filterImpact(existingMaterial.impacts, coreIndicators)}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {deletionStaged && existingMaterial && (
          <ConfirmationModal
            title={t("Delete material")}
            content={<Text16>{t("Confirm the deletion")}</Text16>}
            danger
            onConfirm={() =>
              removeMaterials([{ supplier_product: existingMaterial } as PlantMaterialSupplier])
            }
            onCancel={() => setDeletionStaged(false)}
          />
        )}
      </PageContainer>
    </FormProvider>
  );
};
