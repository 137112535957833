import { TextArea as TextAreaPrimitive, TextAreaProps } from "react-aria-components";

import { cva } from "class-variance-authority";
import { forwardRef } from "react";

const styles = cva(
  [
    "flex",
    "px-4",
    "py-2",
    "outline-hidden",
    "w-full",
    "border-b",
    "border-transparent",
    "focus:border-steelblue",
    "bg-neutral-100",
    "text-builtgreen",
    "font-normal",
    "disabled:opacity-50",
  ],
  {
    variants: {
      hasError: {
        true: "border-red-500 focus:border-red-500",
      },
      hasLabel: {
        true: "pt-7",
      },
      isDisabled: {
        true: "cursor-not-allowed text-neutral-400",
      },
    },
  },
);

export const TextArea = forwardRef<
  HTMLTextAreaElement,
  Omit<TextAreaProps, "className"> & {
    isDisabled?: boolean;
    hasError?: boolean;
    hasLabel?: boolean;
  }
>(({ isDisabled, hasError, hasLabel, ...props }, ref) => {
  return (
    <TextAreaPrimitive
      ref={ref}
      rows={6}
      {...props}
      className={styles({ isDisabled, hasError, hasLabel })}
    />
  );
});
