import { ComponentProps, forwardRef, ReactNode } from "react";
import { NumberField as NumberFieldPrimitive, NumberFieldProps } from "react-aria-components";
import { exists } from "../util/commonUtil";
import { FieldLabel, movingLabelStyles } from "./FieldLabel";
import { FieldText } from "./FieldText";
import { Input } from "./Input";
import { InputWithSubfields } from "./InputWithSubfields";
import { C1 } from "./Typography";

const defaultFormatOptions: Intl.NumberFormatOptions = {
  maximumFractionDigits: 10,
};

export const NumberField = forwardRef<
  HTMLInputElement,
  Omit<NumberFieldProps, "children" | "className"> & {
    label?: string | ReactNode;
    labelProps?: ComponentProps<typeof FieldLabel>;
    errorMessage?: string;
    inputProps?: ComponentProps<typeof Input>;
    placeholder?: string;
    subfields?: ReactNode;
    isViewOnly?: boolean;
  }
>(
  (
    { inputProps, label, labelProps, errorMessage, subfields, placeholder, isViewOnly, ...props },
    ref,
  ) => {
    const _placeholder = exists(label) ? " " : placeholder;

    if (isViewOnly) {
      const formattedValue =
        typeof props.value === "number" && isNaN(props.value)
          ? "-"
          : typeof props.value === "number"
            ? new Intl.NumberFormat(undefined, defaultFormatOptions).format(props.value)
            : props.value;

      return (
        <div className="h-[56px] px-4 flex flex-col justify-center">
          {label && <FieldLabel {...labelProps}>{label}</FieldLabel>}
          <div className="flex items-center gap-1 [&_[data-unit]]:text-neutral-400 [&_p]:truncate">
            <C1>{formattedValue}</C1>
            {inputProps?.addonRight && <C1 data-unit>{inputProps.addonRight}</C1>}
          </div>
        </div>
      );
    }

    return (
      <NumberFieldPrimitive {...props} isWheelDisabled formatOptions={defaultFormatOptions}>
        <div className={movingLabelStyles}>
          {label && (
            <FieldLabel isRequired={props.isRequired} isDisabled={props.isDisabled} {...labelProps}>
              {label}
            </FieldLabel>
          )}
          {subfields ? (
            <InputWithSubfields
              ref={ref}
              {...inputProps}
              hasError={!!errorMessage}
              placeholder={_placeholder}
              hasLabel={!!label}
              disabled={props.isDisabled}
              subfields={subfields}
            />
          ) : (
            <Input
              ref={ref}
              {...inputProps}
              placeholder={_placeholder}
              hasLabel={!!label}
              hasError={!!errorMessage}
              disabled={props.isDisabled}
            />
          )}
        </div>
        {errorMessage && <FieldText intent="error">{errorMessage}</FieldText>}
      </NumberFieldPrimitive>
    );
  },
);
