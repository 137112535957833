import { useTranslation } from "react-i18next";
import { ProductionProcess } from "../../api/types";
import { Card } from "../../components/Card";
import { Link } from "../../components/Link";
import { Text14 } from "../../components/TypographyOld";
import { useLocale } from "../../localization/useLocale";
import { formatDate } from "../../util/format";
import { RenderedFlow } from "../product-detail/RenderedFlow";

export const ProductionProcessSummaryCard = ({ process }: { process: ProductionProcess }) => {
  const { t } = useTranslation();
  const locale = useLocale();
  return (
    <Card
      title={process.name}
      input={
        <>
          <Text14>
            {t("Last modified at")}: {formatDate(new Date(process.updated_at), locale)}
          </Text14>
          <Link intent="secondary" size="small" href={`/production?section=${process.id}`}>
            Details
          </Link>
        </>
      }
    >
      <RenderedFlow flow={process} />
    </Card>
  );
};
