import { LaunchOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { InfoTooltip } from "../../../components/InfoTooltip";
import { Link } from "../../../components/Link";
import { Radio } from "../../../components/RadioGroupField";
import { C1, C2 } from "../../../components/Typography";
import { RadioGroupFieldConnected } from "../../../form-components/RadioGroupFieldConnected";
import { SecondaryDatasetWithOptions } from "../types";

const SecondaryDataLabel = ({ item }: { item: SecondaryDatasetWithOptions }) => {
  return (
    <div className="flex items-center gap-2">
      {item.activity_name}
      <Link intent="link" size="small" href={item.source_url} target="_blank">
        {!!item.source_url && <LaunchOutlined className="text-neutral-500" fontSize="small" />}
      </Link>
    </div>
  );
};

export const SecondaryData = ({
  data,
  isEditing,
}: {
  data: SecondaryDatasetWithOptions[];
  isEditing: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="flex items-center gap-2">
        <C1 $bold>{t("Secondary")}</C1>
        <InfoTooltip>
          {t(
            `Secondary data is the life cycle inventory (LCI) data provided by existing databases.`,
          )}
        </InfoTooltip>
      </div>
      <div className="flex flex-col gap-2 mt-4">
        {isEditing ? (
          <RadioGroupFieldConnected name="epdId" aria-label={t("Select")}>
            {data.map((item) => (
              <Radio
                key={item.activity_name}
                value={""}
                description={`${t("Data source")}: Ecoinvent 3.10 ${item.geography ? `(${item.geography})` : ""}`}
              >
                <SecondaryDataLabel item={item} />
              </Radio>
            ))}
          </RadioGroupFieldConnected>
        ) : (
          <div className="flex gap-4 items-center max-w-full">
            <div className="shrink-0 bg-neutral-100 text-builtgreen size-12 font-black flex items-center justify-center">
              {t("2nd")}
            </div>
            <div className="flex flex-col flex-1">
              {data.map((item) => (
                <div key={item.activity_name} className="flex flex-col">
                  <SecondaryDataLabel item={item} />
                  <C2 className="line-clamp-1 text-neutral-400">
                    {t("Data source")}: Ecoinvent 3.10 {item.geography && `(${item.geography})`}
                  </C2>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
