import { RecyclingOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { DynamicFormJSONSchema } from "../../api/types";
import { Label14 } from "../../components/TypographyOld";
import { JSONSchemaFieldConnected } from "../../form-components/JSONSchemaFieldConnected";

export const WasteTreatment = ({
  editing,
  schema,
}: {
  editing: boolean;
  schema: DynamicFormJSONSchema;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Label14 className="flex items-center gap-2">
        <RecyclingOutlined fontSize="small" /> {t("Waste Management")}
      </Label14>
      <div className="space-y-4">
        <div className="grow pt-3">
          <div className="grid xl:grid-cols-4 grid-cols-3 gap-8">
            {Object.entries(schema.properties).map(([fieldname, fieldConfig]) => (
              <JSONSchemaFieldConnected
                key={fieldname}
                schema={schema}
                isDisabled={!editing}
                fieldName={fieldname}
                fieldConfig={fieldConfig}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
