import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  createFilterOptions,
  FilterOptionsState,
  Popper,
  styled,
} from "@mui/material";
import { ComponentProps, forwardRef, SyntheticEvent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { ComboBoxMultiItem } from "./ComboBoxMultiItem";
import { FieldLabel } from "./FieldLabel";
import { TextField } from "./TextField";
import { C1 } from "./Typography";

export type ComboBoxMultiOption = {
  id: string | number;
  label: string;
  isDisabled?: boolean;
  creatable?: boolean;
  group?: string;
};

type ComboBoxFieldProps = Omit<
  ComponentProps<typeof Autocomplete<ComboBoxMultiOption, true, false, false, "div">>,
  "className" | "renderInput" | "renderOption" | "disabled" | "getOptionDisabled"
> & {
  label?: string;
  errorMessage?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  placeholder?: string;
  creatable?: boolean;
  wrapValueAt?: number;
  isViewOnly?: boolean;
};

const StyledPopper = styled(Popper)(() => ({
  "& .MuiAutocomplete-groupLabel": {
    backgroundColor: "#F5F5F5",
    color: "#737373",
    fontFamily: "CircularStd",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
}));

export const ComboBoxMultiField = forwardRef<HTMLDivElement, ComboBoxFieldProps>(
  (
    {
      label,
      options,
      errorMessage,
      isDisabled,
      isRequired,
      placeholder,
      wrapValueAt = 3,
      creatable,
      isViewOnly,
      ...props
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const defaultFilter = createFilterOptions<ComboBoxMultiOption>({
      matchFrom: "start",
    });

    const filterOptions = (
      options: ComboBoxMultiOption[],
      value: FilterOptionsState<ComboBoxMultiOption>,
    ): ComboBoxMultiOption[] => {
      const filtered = defaultFilter(options, value);

      if (!creatable) {
        return filtered;
      }

      const { inputValue } = value;
      // Suggest the creation of a new value
      const isExisting = options.some((option) => inputValue === option.label);
      if (inputValue !== "" && !isExisting) {
        filtered.push({
          label: `Add "${inputValue}"`,
          id: inputValue,
          creatable: true,
        });
      }

      return filtered;
    };

    const getOptionLabel = (option: string | ComboBoxMultiOption) => {
      // Value selected with enter, right from the input
      if (typeof option === "string") {
        return option;
      }
      // Add "xxx" option created dynamically
      if (option.creatable) {
        return String(option.id);
      }
      // Regular option
      return option.label;
    };

    const getOptionKey = useCallback((option: string | ComboBoxMultiOption) => {
      if (typeof option === "string") {
        return option;
      }

      return option.id;
    }, []);

    const onChange = (
      event: SyntheticEvent,
      value: (string | ComboBoxMultiOption)[],
      reason: AutocompleteChangeReason,
      details?: AutocompleteChangeDetails<ComboBoxMultiOption>,
    ) => {
      if (value && typeof value === "string") {
        props.onChange?.(
          event,
          [
            {
              id: value,
              label: value,
              creatable: true,
            },
          ],
          reason,
          details,
        );
      } else {
        props.onChange?.(event, value as ComboBoxMultiOption[], reason, details);
      }
    };

    if (isViewOnly) {
      const selectedValue =
        (props.value?.length || 0) >= wrapValueAt
          ? t("{{ count }} selected", { count: props.value?.length || 0 })
          : props.value?.map((opt) => (typeof opt === "string" ? opt : opt.label)).join(", ");

      return (
        <div className="h-[56px] px-4 flex flex-col justify-center">
          {label && <FieldLabel>{label}</FieldLabel>}
          <C1>{selectedValue}</C1>
        </div>
      );
    }

    return (
      <Autocomplete<ComboBoxMultiOption, true, false, false, "div">
        multiple
        ref={ref}
        {...props}
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        getOptionLabel={getOptionLabel}
        getOptionKey={getOptionKey}
        filterOptions={filterOptions}
        renderTags={() => null}
        // @ts-expect-error we should pass "boolean" to the 4th param of the Autocomplete generic type,
        // and fix the value possibly being string | ComboBoxMultiOption
        // seems to work like this for now through
        freeSolo={creatable}
        onChange={onChange}
        clearOnBlur
        selectOnFocus
        handleHomeEndKeys
        disableCloseOnSelect
        groupBy={(option) => option.group || ""}
        PopperComponent={StyledPopper}
        renderOption={({ className, ...props }, opt) => {
          return (
            <ComboBoxMultiItem {...props} key={opt.id} creatable={opt.creatable}>
              {opt.label}
            </ComboBoxMultiItem>
          );
        }}
        options={options}
        getOptionDisabled={(option) => !!option.isDisabled}
        renderInput={({ InputProps: { ref }, inputProps: { className, ...inputProps } }) => {
          const value = props.value || [];

          const selectedValue =
            value.length >= wrapValueAt
              ? t("{{ count }} selected", { count: value.length })
              : value.map((opt) => (typeof opt === "string" ? opt : opt.label)).join(", ");

          return (
            <TextField
              ref={ref}
              isDisabled={isDisabled}
              inputProps={{
                ...inputProps,
                value: inputProps.value || selectedValue,
                extraRight: (
                  <div
                    className="flex items-center justify-center h-12 bg-neutral-100"
                    onClick={() => {
                      if (isViewOnly || isDisabled) return;
                      setIsOpen((prev) => !prev);
                    }}
                  >
                    <KeyboardArrowDownOutlined />
                  </div>
                ),
              }}
              errorMessage={errorMessage}
              label={label}
              placeholder={placeholder}
              isRequired={isRequired}
            />
          );
        }}
      />
    );
  },
);
