import { ReactNode } from "react";
import tw from "tailwind-styled-components";
import { Label } from "./TypographyOld";

export const CardDivider = () => {
  return <div className="border-t border-neutral-300 -mx-6" />;
};

const CardHeader = tw("div")`
  bg-neutral-100
  flex
  items-center
  justify-between
  px-6
  h-16
`;

const CardFooter = tw("div")`
  bg-neutral-100
  flex
  items-center
  justify-between
  px-6
  h-16
`;

export const Card = ({
  title,
  input,
  children,
  footerSection,
}: {
  title?: ReactNode;
  input?: ReactNode;
  children: ReactNode;
  footerSection?: ReactNode;
}) => {
  return (
    <div className="bg-white border border-neutral-200">
      {title && (
        <CardHeader>
          <Label $as={typeof title === "string" ? "p" : "div"}>{title}</Label>
          {input && <div className="flex items-center gap-6 justify-end">{input}</div>}
        </CardHeader>
      )}
      <div className="px-6 py-5">{children}</div>
      {footerSection && <CardFooter>{footerSection}</CardFooter>}
    </div>
  );
};
