import { WindowOutlined } from "@mui/icons-material";
import { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router";
import useLocalStorage from "react-use/lib/useLocalStorage";
import { Loading } from "../components/Loading";
import { PageContainer } from "../components/PageContainer";
import { TopBar } from "../components/TopBar";
import { DocumentsSidePanel } from "../page-components/products/DocumentsSidePanel";
import { EmptyProductList } from "../page-components/products/EmptyProductList";
import { MultiSelectActionBar } from "../page-components/products/MultiSelectActionBar";
import { ProductsListView } from "../page-components/products/ProductsListView";
import { ProductsTileView } from "../page-components/products/ProductsTileView";
import { SearchControls } from "../page-components/products/SearchControls";
import { SortControls } from "../page-components/products/SortControls";
import { TopBarInput } from "../page-components/products/TopBarInput";
import { useProductSelection } from "../page-components/products/useProductSelection";
import { useProductSorting } from "../page-components/products/useProductSorting";
import { useActiveOnboardingStep } from "../state/onboarding";
import { useActivePlant } from "../state/plants";
import { useActivePlantProducts } from "../state/products";

const useProducts = () => {
  const { data: _products } = useActivePlantProducts();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const elementaryId = searchParams.get("elementary");

  useEffect(
    function maybeClearSearchParamsOnPlantSwitch() {
      if (!elementaryId || _products.length === 0) return;

      const allProductsThisElementaryId = _products.every(
        (product) => product.elementary_id === elementaryId,
      );
      const noProductsHaveThisElementaryId = _products.every(
        (product) => product.elementary_id !== elementaryId,
      );

      if (allProductsThisElementaryId || noProductsHaveThisElementaryId) {
        // clearing the search params
        navigate(`/products`, { replace: true });
      }
    },
    [elementaryId, _products, navigate],
  );

  return useMemo(() => {
    if (!elementaryId) return _products;
    return _products.filter((product) => product.elementary_id === elementaryId);
  }, [_products, elementaryId]);
};

export const Products = () => {
  const activePlant = useActivePlant();

  const { t } = useTranslation();
  const { sort, setSort } = useProductSorting();
  const products = useProducts();

  const {
    isSelectMode,
    selectedProducts,
    setSelectedProducts,
    toggleSelect,
    toggleSelectMode,
    toggleSelectAll,
    resetSelectedProducts,
  } = useProductSelection({ products });

  const [activeTab, setActiveTab] = useLocalStorage("products-view-mode", "grid");

  useEffect(
    function resetSelectionOnPlantChange() {
      resetSelectedProducts();
    },
    [activePlant, resetSelectedProducts],
  );

  const navigate = useNavigate();
  const { step: onboardingStep } = useActiveOnboardingStep();
  useEffect(() => {
    if (onboardingStep < 3) {
      navigate("/onboarding");
    }
  }, [navigate, onboardingStep]);

  const [sidePanelOpen, setSidePanelOpen] = useState(false);
  const toggleSidePanel = useCallback(() => setSidePanelOpen((prev) => !prev), []);

  const [debouncedSearchInput, setDebouncedSearchInput] = useState("");

  return (
    <PageContainer>
      <TopBar
        icon={<WindowOutlined />}
        title={t("Product overview")}
        subtitle={
          <>
            {products.length} {t("products", { count: products.length })}
          </>
        }
        input={
          products.length > 0 ? (
            <TopBarInput
              selectedProducts={selectedProducts}
              isSelectMode={isSelectMode}
              onCreateDocuments={toggleSidePanel}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          ) : undefined
        }
      />
      <div className="grow flex flex-col gap-4 py-8">
        <Suspense fallback={<Loading />}>
          {products.length > 0 && (
            <>
              <DocumentsSidePanel
                selectedProducts={selectedProducts}
                open={sidePanelOpen}
                onClose={() => setSidePanelOpen(false)}
              />
              {activeTab === "grid" ? (
                <>
                  <MultiSelectActionBar
                    products={products}
                    selectedProducts={selectedProducts}
                    isSelectMode={isSelectMode}
                    toggleSelectMode={toggleSelectMode}
                    toggleSelectAll={toggleSelectAll}
                    resetSelectedProducts={resetSelectedProducts}
                    extraRightSideContent={
                      isSelectMode ? null : (
                        <div className="grid grid-cols-1 sm:grid-cols-[220px_auto] gap-4 items-center">
                          <SearchControls
                            search={debouncedSearchInput}
                            setSearch={setDebouncedSearchInput}
                          />
                          <SortControls sort={sort} setSort={setSort} />
                        </div>
                      )
                    }
                  />
                  <ProductsTileView
                    products={products}
                    isSelectMode={isSelectMode}
                    searchInput={debouncedSearchInput}
                    selectedProducts={selectedProducts}
                    toggleSelect={toggleSelect}
                    sort={sort}
                  />
                </>
              ) : (
                <>
                  <MultiSelectActionBar
                    products={products}
                    selectedProducts={selectedProducts}
                    isSelectMode={isSelectMode}
                    toggleSelectMode={toggleSelectMode}
                    toggleSelectAll={toggleSelectAll}
                    resetSelectedProducts={resetSelectedProducts}
                    extraRightSideContent={
                      isSelectMode ? null : (
                        <div className="grid w-full sm:w-[220px]">
                          <SearchControls
                            search={debouncedSearchInput}
                            setSearch={setDebouncedSearchInput}
                          />
                        </div>
                      )
                    }
                  />
                  <div>
                    <ProductsListView
                      products={products}
                      searchInput={debouncedSearchInput}
                      isSelectMode={isSelectMode}
                      selectedProducts={selectedProducts}
                      toggleSelect={toggleSelect}
                      setSelectedProducts={setSelectedProducts}
                    />
                  </div>
                </>
              )}
            </>
          )}
          {products.length === 0 && <EmptyProductList />}
        </Suspense>
      </div>
    </PageContainer>
  );
};
