import { Add, DeleteOutlined } from "@mui/icons-material";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Button";
import { Tooltip, TooltipTrigger } from "../../components/Tooltip";
import { ComboBoxFieldConnected } from "../../form-components/ComboBoxFieldConnected";
import { NumberFieldConnected } from "../../form-components/NumberFieldConnected";
import { FormFields, TransportationFormFields } from "./types";

export const DEFAULT_TRANSPORTATION = [{ modality: "truck", distance: NaN }];

const useModalityOptions = () => {
  const { t } = useTranslation();
  return [
    {
      label: t("On Land"),
      id: "truck",
    },
    {
      label: t("On Rails"),
      id: "train",
    },
    {
      label: t("On Water"),
      id: "ship",
    },
  ];
};

export const TransportationForm = ({ isEditing }: { isEditing?: boolean }) => {
  const { t } = useTranslation();
  const modalityOptions = useModalityOptions();
  const methods = useFormContext<{ transportation: TransportationFormFields[] }>();
  const transportation = methods.watch("transportation");

  return (
    <div className="flex flex-col gap-4">
      {transportation.map((_, index, array) => (
        <div key={index} className="flex flex-col gap-1 items-start">
          <div
            className={`grid ${array.length > 1 && isEditing ? "grid-cols-[1fr_1fr_auto]" : "grid-cols-[1fr_1fr]"} items-start gap-4 w-full`}
          >
            <ComboBoxFieldConnected
              name={`transportation.${index}.modality`}
              isRequired={isEditing}
              isViewOnly={!isEditing}
              options={modalityOptions.filter(
                (opt) =>
                  !array.some((item, itemIndex) => itemIndex !== index && item.modality === opt.id),
              )}
              label={t("Transport")}
            />
            <NumberFieldConnected<FormFields>
              name={`transportation.${index}.distance`}
              isRequired={isEditing}
              isViewOnly={!isEditing}
              minValue={0}
              label={t("Distance")}
              inputProps={{ addonRight: "km" }}
            />
            {array.length > 1 && isEditing && (
              <div className="self-end">
                <TooltipTrigger>
                  <Button
                    intent="secondary"
                    danger
                    onPress={() => {
                      const newArray = array.filter((_, itemIndex) => itemIndex !== index);
                      methods.setValue("transportation", newArray);
                    }}
                  >
                    <DeleteOutlined fontSize="small" />
                  </Button>
                  <Tooltip>{t("Delete")}</Tooltip>
                </TooltipTrigger>
              </div>
            )}
          </div>
        </div>
      ))}
      {transportation.length < modalityOptions.length && isEditing && (
        <div>
          <Button
            intent="tertiary"
            type="button"
            onPress={() => {
              methods.setValue("transportation", [
                ...transportation,
                {
                  modality:
                    modalityOptions.find(
                      (opt) => !transportation.some((x) => x.modality === opt.id),
                    )?.id ?? "",
                  distance: NaN,
                },
              ]);
            }}
          >
            <Add />
            {t("Add transport")}
          </Button>
        </div>
      )}
    </div>
  );
};
