import { useCallback } from "react";
import { Silo, SiloItem, SiloItemAnnualItem } from "../types";
import { useApi } from "../useApi";

export const usePostSilo = () => {
  const api = useApi<Silo>({ method: "POST" });

  return useCallback(
    ({
      plantId,
      data,
    }: {
      plantId: string;
      data: Omit<Silo, "id" | "active" | "items"> & {
        items: (Omit<SiloItem, "id" | "annual_items"> & {
          annual_items: Omit<SiloItemAnnualItem, "id">[];
        })[];
      };
    }) => api({ url: `/v1/plants/${plantId}/silos`, data }),
    [api],
  );
};

export const usePutSilo = () => {
  const api = useApi<Silo>({ method: "PUT" });

  return useCallback(
    ({
      plantId,
      data,
    }: {
      plantId: string;
      data: Omit<Silo, "active" | "items"> & {
        items: (Omit<SiloItem, "id" | "annual_items"> & {
          annual_items: Omit<SiloItemAnnualItem, "id">[];
        })[];
      };
    }) => api({ url: `/v1/plants/${plantId}/silos/${data.id}`, data }),
    [api],
  );
};

export const useDeleteSilo = () => {
  const api = useApi<void>({ method: "DELETE" });

  return useCallback(
    ({ siloId, plantId }: { siloId: string; plantId: string }) =>
      api({ url: `/v1/plants/${plantId}/silos/${siloId}` }),
    [api],
  );
};
