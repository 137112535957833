import { LaunchOutlined, SearchOutlined } from "@mui/icons-material";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useDebounce from "react-use/lib/useDebounce";
import { Alert } from "../../../components/Alert";
import { Link } from "../../../components/Link";
import { ModalBody, ModalFooter, ModalHeader } from "../../../components/Modal";
import { Pagination } from "../../../components/Pagination";
import { Radio } from "../../../components/RadioGroupField";
import { TextField } from "../../../components/TextField";
import { RadioGroupFieldConnected } from "../../../form-components/RadioGroupFieldConnected";
import { useSearchEPDs } from "../../../state/epds";

interface PaginationHook<T> {
  currentPage: number;
  totalPages: number;
  setCurrentPage: (page: number) => void;
  paginatedItems: T[];
}

const usePagination = <T,>(items: T[], pageSize: number): PaginationHook<T> => {
  const [currentPage, setCurrentPage] = useState(0);
  const totalPages = Math.ceil(items.length / pageSize);

  const paginatedItems = useMemo(() => {
    const start = currentPage * pageSize;
    return items.slice(start, start + pageSize);
  }, [items, currentPage, pageSize]);

  return {
    currentPage,
    totalPages,
    setCurrentPage,
    paginatedItems,
  };
};

export const SearchEPDModalContent = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const { data: primaryData = [], mutate: search } = useSearchEPDs();
  const PAGE_SIZE = 5;

  const sortedPrimaryData = useMemo(() => {
    return [...primaryData].sort((a, b) => {
      if (a.can_be_used !== b.can_be_used) {
        return b.can_be_used ? 1 : -1;
      }
      return a.name_en.localeCompare(b.name_en);
    });
  }, [primaryData]);

  const { currentPage, totalPages, setCurrentPage, paginatedItems } = usePagination(
    sortedPrimaryData,
    PAGE_SIZE,
  );

  useDebounce(
    () => {
      search({ searchTerm, language: "en", maxResults: 150 });
    },
    500,
    [searchTerm],
  );

  return (
    <>
      <ModalHeader title={t("Search EPD")} onClose={onClose} />
      <ModalBody>
        <div className="space-y-6">
          <TextField
            autoFocus
            aria-label={t("Search EPD")}
            placeholder={t("Search for a product or supplier to find the right EPD")}
            value={searchTerm}
            onChange={setSearchTerm}
            inputProps={{
              addonLeft: <SearchOutlined />,
            }}
          />
          <div className="space-y-4">
            {sortedPrimaryData.some((item) => !item.can_be_used) && (
              <Alert>
                {t(
                  "Some of the EPDs listed below aren't selectable, because they're not compliant with EN15804+A2, or we don't have enough data on it yet.",
                )}
              </Alert>
            )}
            <RadioGroupFieldConnected name="epdId" aria-label={t("Select")}>
              {paginatedItems.map((item) => (
                <Radio
                  key={item.id}
                  value={item.id}
                  isDisabled={!item.can_be_used}
                  description={`${item.manufacturer}, ${item.reference_year}`}
                >
                  <div className="flex items-center gap-1">
                    {item.name_en}
                    <Link
                      intent="link"
                      size="small"
                      href={item.item_source_url || item.epd_pdf_url}
                      target="_blank"
                    >
                      <LaunchOutlined
                        fontSize="small"
                        className={item.can_be_used ? "text-neutral-500" : "text-neutral-400"}
                      />
                    </Link>
                  </div>
                </Radio>
              ))}
            </RadioGroupFieldConnected>
          </div>
        </div>
      </ModalBody>
      {totalPages > 1 && (
        <ModalFooter>
          <div className="w-full flex justify-center">
            <Pagination page={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />
          </div>
        </ModalFooter>
      )}
    </>
  );
};
