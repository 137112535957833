import { InfoOutlined } from "@mui/icons-material";
import { ComponentProps, ReactNode } from "react";
import { Button as ButtonPrimitive } from "react-aria-components";
import { Tooltip, TooltipTrigger } from "./Tooltip";

export const InfoTooltip = ({
  icon,
  ...props
}: ComponentProps<typeof Tooltip> & {
  icon?: ReactNode;
}) => {
  return (
    <TooltipTrigger>
      <ButtonPrimitive className="flex items-center outline-hidden">
        {icon ? icon : <InfoOutlined fontSize="small" />}
      </ButtonPrimitive>
      <Tooltip {...props} />
    </TooltipTrigger>
  );
};
