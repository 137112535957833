import { useAuth0 } from "@auth0/auth0-react";
import axios, { AxiosRequestConfig, isAxiosError } from "axios";
import { useCallback, useRef } from "react";
import { showErrorToast } from "../util/toasts";

const DEBUGGING = new URLSearchParams(window.location.search).has("debug");

export const BASE_URL =
  (import.meta.env.VITE_EPD_BACKEND_API as string) ||
  (import.meta.env.VITE_EPD_BACKEND_API_DEV as string);

export const apiLink = (path: string) => {
  return `${BASE_URL}${path}`;
};

const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

export const ServerNotReachableError = class extends Error {
  constructor() {
    super("Server not reachable");
  }
};

export const useApi = <T>(config?: AxiosRequestConfig) => {
  const { getAccessTokenSilently } = useAuth0();
  const configRef = useRef(config);
  configRef.current = config;

  return useCallback(
    async (finalConfig?: AxiosRequestConfig) => {
      const token = await getAccessTokenSilently();

      try {
        const response = await api<T>({
          ...configRef.current,
          ...finalConfig,
          headers: {
            ...configRef.current?.headers,
            Authorization: `Bearer ${token}`,
            ...finalConfig?.headers,
          },
          withCredentials: true,
        });

        return response.data;
      } catch (error) {
        if (isAxiosError(error) && !error.response && error.request) {
          throw new ServerNotReachableError();
        }

        if (DEBUGGING && axios.isAxiosError(error)) {
          showErrorToast(error.response?.data?.message || "Something went wrong");
        }

        throw error;
      }
    },
    [getAccessTokenSilently],
  );
};
