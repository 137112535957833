import { useCallback } from "react";
import { useApi } from "../useApi";

export const useGetLcaDocumentation = () => {
  const api = useApi<Blob>({
    responseType: "blob",
  });

  return useCallback(
    ({ elementaryId }: { elementaryId: string }) =>
      api({
        url: `/v1/lca-documentation/lci/${elementaryId}`,
      }),
    [api],
  );
};
