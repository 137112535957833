import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  createFilterOptions,
  FilterOptionsState,
} from "@mui/material";
import {
  ComponentProps,
  forwardRef,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useState,
} from "react";
import { ComboBoxItem } from "./ComboBoxItem";
import { FieldLabel } from "./FieldLabel";
import type { Input } from "./Input";
import { TextField } from "./TextField";
import { C1 } from "./Typography";

type ComboBoxOption = {
  id: string | number;
  label: string;
  isDisabled?: boolean;
  icon?: ReactNode;
  creatable?: boolean;
};

type ComboBoxFieldProps = Omit<
  ComponentProps<typeof Autocomplete<ComboBoxOption, false, false, false, "div">>,
  "className" | "renderInput" | "renderOption" | "disabled" | "getOptionDisabled" | "size"
> & {
  label?: string;
  errorMessage?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  placeholder?: string;
  creatable?: boolean;
  addonLeft?: ComponentProps<typeof Input>["addonLeft"];
  isViewOnly?: boolean;
};

/**
 * A Combo Box Field is a text field with a dropdown that allows users to select an option from a list.
 * Users may type to filter the list.
 */
export const ComboBoxField = forwardRef<HTMLDivElement, ComboBoxFieldProps>(
  (
    {
      label,
      options,
      errorMessage,
      isDisabled,
      isRequired,
      placeholder,
      creatable,
      addonLeft,
      isViewOnly,
      ...props
    },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState(false);

    const defaultFilter = createFilterOptions<ComboBoxOption>({
      matchFrom: "start",
    });

    const filterOptions = (
      options: ComboBoxOption[],
      value: FilterOptionsState<ComboBoxOption>,
    ): ComboBoxOption[] => {
      const filtered = defaultFilter(options, value);

      if (!creatable) {
        return filtered;
      }

      const { inputValue } = value;
      // Suggest the creation of a new value
      const isExisting = options.some((option) => inputValue === option.label);
      if (inputValue !== "" && !isExisting) {
        filtered.push({
          label: `Add "${inputValue}"`,
          id: inputValue,
          creatable: true,
        });
      }

      return filtered;
    };

    const getOptionLabel = (option: string | ComboBoxOption) => {
      // Value selected with enter, right from the input
      if (typeof option === "string") {
        return option;
      }
      // Add "xxx" option created dynamically
      if (option.creatable) {
        return String(option.id);
      }
      // Regular option
      return option.label;
    };

    const getOptionKey = useCallback((option: string | ComboBoxOption) => {
      if (typeof option === "string") {
        return option;
      }

      return option.id;
    }, []);

    const onChange = (
      event: SyntheticEvent,
      value: null | string | ComboBoxOption,
      reason: AutocompleteChangeReason,
      details?: AutocompleteChangeDetails<ComboBoxOption>,
    ) => {
      if (value && typeof value === "string") {
        props.onChange?.(
          event,
          {
            id: value,
            label: value,
            creatable: true,
          },
          reason,
          details,
        );
      } else {
        props.onChange?.(event, value as ComboBoxOption | null, reason, details);
      }
    };

    const renderTags = () => {
      throw new Error("ComboBoxField does not support tags");
    };

    const freeSoloProps = {
      getOptionLabel,
      filterOptions,
      getOptionKey,
      renderTags,
      onChange,
      freeSolo: creatable,
    };

    if (isViewOnly) {
      const selectedValue = props.value?.label;
      return (
        <div className="min-h-[56px] px-4 flex flex-col justify-center [&_p]:truncate">
          {label && <FieldLabel>{label}</FieldLabel>}
          <C1 title={selectedValue}>{selectedValue}</C1>
        </div>
      );
    }

    return (
      // @ts-expect-error we should pass "boolean" to the 4th param of the Autocomplete generic type,
      // and fix the value possibly being string | ComboBoxOption
      // seems to work like this for now through
      <Autocomplete<ComboBoxOption, false, false, false, "div">
        ref={ref}
        {...props}
        {...freeSoloProps}
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        clearOnBlur
        selectOnFocus
        disablePortal
        handleHomeEndKeys
        renderOption={({ className, ...props }, opt) => (
          <ComboBoxItem {...props} key={opt.id} creatable={opt.creatable}>
            {opt.icon}
            {opt.label}
          </ComboBoxItem>
        )}
        options={options}
        getOptionDisabled={(option) => !!option.isDisabled}
        renderInput={({ InputProps: { ref }, inputProps: { className, ...inputProps } }) => {
          return (
            <TextField
              ref={ref}
              isDisabled={isDisabled}
              inputProps={{
                addonLeft,
                ...inputProps,
                extraRight: (
                  <div
                    className="flex items-center justify-center h-12 bg-neutral-100"
                    onClick={() => {
                      if (isViewOnly || isDisabled) return;
                      setIsOpen((prev) => !prev);
                    }}
                  >
                    <KeyboardArrowDownOutlined />
                  </div>
                ),
              }}
              errorMessage={errorMessage}
              label={label}
              placeholder={placeholder}
              isRequired={isRequired}
            />
          );
        }}
      />
    );
  },
);
