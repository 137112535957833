import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "./Button";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "./Modal";

export const ConfirmationModal = ({
  title,
  content,
  onConfirm,
  onCancel,
  danger,
}: {
  title: string;
  content: ReactNode;
  onConfirm: () => void;
  onCancel: () => void;
  danger?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen onOpenChange={onCancel}>
      {({ close }) => (
        <>
          <ModalHeader title={title} onClose={close} />
          <ModalBody>{content}</ModalBody>
          <ModalFooter>
            <Button intent="secondary" onPress={onCancel}>
              {t("Cancel")}
            </Button>
            <Button onPress={onConfirm} danger={danger} intent={danger ? "secondary" : "primary"}>
              {t("Confirm")}
            </Button>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};
