import { cva, VariantProps } from "class-variance-authority";
import { ComponentProps, forwardRef, ReactNode } from "react";
import {
  Label as LabelPrimitive,
  RadioGroup,
  RadioGroupProps,
  Radio as RadioPrimitive,
  RadioProps,
} from "react-aria-components";
import { FieldLabel } from "./FieldLabel";
import { FieldText } from "./FieldText";
import { InfoTooltip } from "./InfoTooltip";
import { C1, C2 } from "./Typography";

export const RadioGroupField = forwardRef<
  HTMLDivElement,
  Omit<RadioGroupProps, "children" | "className"> & {
    children?: ReactNode;
    label?: string;
    labelProps?: ComponentProps<typeof FieldLabel>;
    errorMessage?: string;
    tooltip?: string;
  }
>(({ label, errorMessage, labelProps, children, tooltip, ...props }, ref) => {
  return (
    <RadioGroup {...props} className="flex flex-col gap-2" ref={ref}>
      {label && (
        <LabelPrimitive isRequired={props.isRequired} isDisabled={props.isDisabled} {...labelProps}>
          <C1 $bold className="flex items-center gap-2">
            {label}
            {tooltip && <InfoTooltip>{tooltip}</InfoTooltip>}
          </C1>
        </LabelPrimitive>
      )}
      <div className="flex flex-col gap-2">{children}</div>
      {errorMessage && <FieldText intent="error">{errorMessage}</FieldText>}
    </RadioGroup>
  );
});

const radioStyles = cva(["flex", "items-center", "gap-2"], {
  variants: {
    isDisabled: {
      true: [
        "cursor-not-allowed",
        "[&_[data-circle]]:bg-neutral-50",
        "[&_[data-title]]:text-neutral-400",
        "[&_[data-description]]:text-neutral-300",
      ],
      false: [
        "cursor-pointer",
        "[&_[data-circle]]:bg-neutral-100",
        "[&_[data-description]]:text-neutral-400",
      ],
    },
  },
  defaultVariants: {
    isDisabled: false,
  },
});

export const Radio = ({
  children,
  description,
  ...props
}: Omit<RadioProps, "className"> & {
  children?: ReactNode;
  description?: string;
} & VariantProps<typeof radioStyles>) => {
  return (
    <RadioPrimitive {...props} className={radioStyles({ isDisabled: props.isDisabled })}>
      {({ isSelected }) => (
        <>
          {isSelected ? (
            <span
              data-circle
              className="relative size-6 rounded-full shrink-0 flex items-center justify-center"
            >
              <span className="absolute size-3 rounded-full bg-steelblue" />
            </span>
          ) : (
            <span data-circle className="relative size-6 rounded-full shrink-0" />
          )}
          <span className="flex flex-col">
            <C1 data-title>{children}</C1>
            {description && <C2 data-description>{description}</C2>}
          </span>
        </>
      )}
    </RadioPrimitive>
  );
};
