import {
  DataGridPro as DataGridPrimitive,
  GridColumnMenu,
  GridColumnMenuProps,
  GridPaginationModel,
} from "@mui/x-data-grid-pro";

import { ComponentProps } from "react";
import useLocalStorage from "react-use/lib/useLocalStorage";

type NativeProps = ComponentProps<typeof DataGridPrimitive>;
type Props = Omit<NativeProps, "rows"> &
  Required<Pick<NativeProps, "rows">> & {
    defaultCursorOnHover?: boolean;
  } & {
    id: string;
  };

const INITIAL_PAGE_SIZE = 50;
/**
 * The idea here: your most recent page size change should be applied to all grids,
 * unless you changed the pagination settings for that particular grid
 */
const usePaginationModel = (id: string) => {
  const [_pageSize, setPageSize] = useLocalStorage(`em-grid-pageSize`, INITIAL_PAGE_SIZE);
  const initialPaginationModel = { pageSize: _pageSize || INITIAL_PAGE_SIZE, page: 0 };
  const [_paginationModel, setPaginationModel] = useLocalStorage(
    `em-grid-paginationModel-${id}`,
    initialPaginationModel,
  );
  const paginationModel = _paginationModel || initialPaginationModel;
  const onPaginationModelChange = (paginationModel: GridPaginationModel) => {
    setPaginationModel(paginationModel);
    setPageSize(paginationModel.pageSize);
  };

  return {
    paginationModel,
    onPaginationModelChange,
  };
};

export const DataGrid = ({ id, ...props }: Props) => {
  const paginationModelProps = usePaginationModel(id);

  const hasPagination = props.rows.length > 50;

  return (
    <DataGridPrimitive
      {...props}
      pagination={hasPagination}
      initialState={{
        pagination: {
          paginationModel: {
            page: 0,
            pageSize: paginationModelProps.paginationModel?.pageSize || INITIAL_PAGE_SIZE,
          },
        },
        ...props.initialState,
      }}
      {...paginationModelProps}
      slots={{
        columnMenu: CustomColumnMenu,
      }}
      pageSizeOptions={hasPagination ? [10, 20, 50, 100, 500] : [50]}
      paginationMode="client"
      className="h-full bg-white"
      rowHeight={48}
      density="standard"
      hideFooter={!hasPagination}
      disableVirtualization
      columnHeaderHeight={48}
      sx={{
        fontSize: 16,
        borderRadius: 0,
        fontWeight: "normal",
        color: "var(--builtgreen)",
        "--unstable_DataGrid-overlayBackground": "rgba(255, 255, 255, 1)",
        fontFamily: "CircularStd",
        "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within": {
          outline: "none",
        },
        "& .MuiDataGrid-columnHeader, .MuiDataGrid-filler": {
          backgroundColor: "#F5F5F5",
          textTransform: "uppercase",
          fontSize: 14,
          color: "#737373",
          fontWeight: "bold",
          fontFamily: "CircularStd",
        },
        "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
          padding: "0 10px 0 24px",
        },
        "& .MuiDataGrid-cellEmpty": {
          padding: "0",
        },
        "& .MuiDataGrid-row:hover": {
          cursor: props.defaultCursorOnHover ? "default" : "pointer",
        },
        "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
          display: "none",
        },
      }}
    />
  );
};

function CustomColumnMenu(props: GridColumnMenuProps) {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        columnMenuSortItem: null,
      }}
    />
  );
}
