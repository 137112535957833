import { Button as ButtonPrimitive, Link as LinkPrimitive } from "react-aria-components";
import type { ProductWithStatus } from "../../api/types";
import checkmark from "../../assets/checkmark.svg";
import { Badge } from "../../components/Badge";
import { Heading4, Text16 } from "../../components/TypographyOld";
import { ProductImage } from "../product-detail/ProductImage";
import { ProductStatusIndicator } from "./ProductStatusIndicator";

import { cva } from "class-variance-authority";
import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { exists } from "../../util/commonUtil";

const containerStyles = cva(
  [
    "p-2",
    "group",
    "relative",
    "w-full",
    "outline-hidden",
    "text-left",
    "shadow-e-xs hover:shadow-e-sm active:shadow-e-xs",
    "translate-y-0 hover:translate-y-[-1px] active:translate-y-0",
    "transition-all",
    "[&_img]:transition-opacity",
    "hover:[&_img]:opacity-80",
    "cursor-pointer",
    "flex",
    "flex-col",
    "justify-between",
    "gap-3",
    "bg-white",
    "overflow-hidden",
  ],
  {
    variants: {
      isSelectMode: {
        true: "",
        false: "border border-neutral-300 hover:border-energyyellow",
      },
      isSelected: {
        true: "border border-builtgreen",
        false: "border border-neutral-300",
      },
    },
    defaultVariants: {
      isSelectMode: false,
      isSelected: false,
    },
  },
);

const ProductCardLink = ({
  isSelectMode,
  isSelected,
  children,
  onToggleSelect,
  item,
}: {
  isSelectMode?: boolean;
  isSelected?: boolean;
  children: React.ReactNode;
  onToggleSelect: (product: ProductWithStatus) => void;
  item: ProductWithStatus;
}) => {
  return isSelectMode ? (
    <ButtonPrimitive
      className={containerStyles({
        isSelectMode,
        isSelected,
      })}
      onPress={() => onToggleSelect(item)}
    >
      <div className="absolute size-6 top-2 left-2 z-20 bg-white shadow-[0_0_0_4px_rgb(255,255,255)]">
        {isSelected ? (
          <span className="relative w-full h-full shrink-0 flex items-center justify-center bg-steelblue">
            <img src={checkmark} alt="Check" />
          </span>
        ) : (
          <span className="relative w-full h-full shrink-0 bg-neutral-200" />
        )}
      </div>
      {children}
    </ButtonPrimitive>
  ) : (
    <LinkPrimitive
      href={`/products/${item.id}`}
      style={{
        viewTransitionName: `product-${item.id}`,
      }}
      className={containerStyles({
        isSelectMode,
        isSelected,
      })}
    >
      {children}
    </LinkPrimitive>
  );
};

export const ProductCard = memo(function ProductCardComponent({
  item,
  isSelected,
  isSelectMode,
  onToggleSelect,
}: {
  item: ProductWithStatus;
  isSelected?: boolean;
  isSelectMode?: boolean;
  onToggleSelect: (product: ProductWithStatus) => void;
}) {
  const { t } = useTranslation();

  const content = useMemo(() => {
    return (
      <>
        <ProductImage product={item} disableUpload />
        <div className={item.status === "incomplete" ? "opacity-50" : ""}>
          <Heading4 className="truncate">{item.name}</Heading4>
          <Text16 className="text-neutral-500">{t(item.material)}</Text16>
        </div>
        <div className="flex items-center justify-between gap-2 h-6">
          <ProductStatusIndicator status={item.status} truncate />
          {exists(item.gwp_total) && (
            <Badge size="sm" color="brownLight">
              {item.gwp_total.toPrecision(4)} CO2E
            </Badge>
          )}
        </div>
      </>
    );
  }, [item, t]);

  return (
    <ProductCardLink
      isSelectMode={isSelectMode}
      isSelected={isSelected}
      onToggleSelect={onToggleSelect}
      item={item}
    >
      {content}
    </ProductCardLink>
  );
});
