import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

// Note: This imports all translated strings into the bundle
// which is fine for an app with a few languages and small translations.
// For bigger apps with many languages and translations, it is better
// to load the translations asynchronously from a server.
import de from "./de.json";
import en from "./en.json";
import deExtended from "./extended.de.json";

i18next
  .use(XHR) // Needed for LanguageDetector
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "en",
    debug: true,
    resources: {
      en: { translation: en },
      de: { translation: Object.assign({}, de, deExtended) },
    },
    returnNull: false,
  });

i18next.on("languageChanged", (lng) => {
  document.documentElement.lang = lng;
});

export default i18next;

// This file needs to be imported somewhere to get initialized
// – in our case: in our entry point: `main.tsx`
