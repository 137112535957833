import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useProductionProcesses } from "../../api/endpoints/production-processes";
import { TextField } from "../../components/TextField";
import { ComboBoxFieldConnected } from "../../form-components/ComboBoxFieldConnected";
import { TextAreaFieldConnected } from "../../form-components/TextAreaFieldConnected";
import { TextFieldConnected } from "../../form-components/TextFieldConnected";
import { useElementaries } from "../../state/elementaries";
import { useActivePlant } from "../../state/plants";
import { useProductMetadata } from "../../state/productMetadata";
import { formatUnit } from "../../util/format";
import { ProductDetailsForm } from "./types";

export const ProductDetailsFields = ({ existingProduct }: { existingProduct: boolean }) => {
  const { t } = useTranslation();
  const activePlant = useActivePlant();

  const { data: productionProcesses } = useProductionProcesses({ plantId: activePlant.id });

  const { watch, setValue } = useFormContext<ProductDetailsForm>();

  const elementaryId = watch("elementary_id");
  const category = watch("categoryName");
  const unit = watch("unit");
  const yearUnderReview = watch("year_under_review");

  const { elementaries, elementariesMap } = useElementaries();
  const {
    data: { productMetadataMap },
  } = useProductMetadata();

  if (existingProduct && elementaryId && !category) {
    setValue("categoryName", elementaries.find((x) => x.id === elementaryId)!.category);
  }

  const plantProducesElementaries = useMemo(() => {
    return elementaries.filter((e) =>
      productionProcesses.some((pp) => pp.elementary_ids.includes(e.id)),
    );
  }, [elementaries, productionProcesses]);

  const categoryOptions = useMemo(() => {
    const uniqueOptions = new Set(plantProducesElementaries.map((e) => e.category));
    return Array.from(uniqueOptions)
      .sort((a, b) => a.localeCompare(b))
      .map((category) => ({
        id: category,
        label: t(category),
      }));
  }, [plantProducesElementaries, t]);

  const materialOptions = useMemo(() => {
    return elementaries
      .filter(
        (e) =>
          e.active &&
          e.category === category &&
          plantProducesElementaries.some((x) => x.id === e.id),
      )
      .map((e) => ({
        id: e.id,
        label: t(e.name),
      }))
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      });
  }, [elementaries, category, plantProducesElementaries, t]);

  const unitOptions = useMemo(() => {
    const elementary = elementariesMap[elementaryId];
    if (!elementary) return [];

    const allowUnits =
      productMetadataMap?.[elementary.product_metadata_id ?? ""]?.allowed_units ?? [];

    return allowUnits.map((unit) => ({ id: unit, label: formatUnit(unit) }));
  }, [elementariesMap, elementaryId, productMetadataMap]);

  useEffect(() => {
    if (unitOptions.length === 1 && unit !== unitOptions[0].id) {
      setValue("unit", unitOptions[0].id);
    }
  }, [unitOptions, setValue, unit]);

  const yearOptions = useMemo(() => {
    const processes =
      productionProcesses.filter((pp) => pp.elementary_ids.includes(elementaryId)) ?? [];
    const years = processes.flatMap((pp) => pp.input_output_per_year?.map((io) => io.year) ?? []);
    const uniqueYears = Array.from(new Set(years));
    return uniqueYears.map((year) => ({ id: year, label: year.toString() }));
  }, [elementaryId, productionProcesses]);

  useEffect(() => {
    if (yearOptions.length === 1 && yearUnderReview !== yearOptions[0].id) {
      setValue("year_under_review", yearOptions[0].id);
    }
  }, [yearOptions, setValue, yearUnderReview]);

  return (
    <div className="overflow-x-hidden overflow-y-auto py-2 grid grid-cols-[1fr_1fr] gap-8 max-w-6xl w-full mx-auto">
      <TextFieldConnected name="name" isRequired placeholder="Name" label={t("Product name")} />
      <TextField label={t("Production plant")} isDisabled value={activePlant.name} />

      <ComboBoxFieldConnected
        name="categoryName"
        options={categoryOptions}
        isDisabled={existingProduct}
        label={t("Category")}
        isRequired
      />
      <ComboBoxFieldConnected
        name="elementary_id"
        label={t("Material")}
        isRequired
        options={materialOptions}
        isDisabled={existingProduct || !category}
      />
      <TextAreaFieldConnected name="description" label={t("Product description")} />
      <TextAreaFieldConnected name="use_case" label={t("Application / use case")} />
      <div className="flex flex-row gap-3">
        <ComboBoxFieldConnected
          name="unit"
          label={t("Unit")}
          isRequired
          options={unitOptions}
          isDisabled={existingProduct || !category}
        />
      </div>
      <div className="grid grid-cols-[1fr_1.2fr] gap-3">
        <ComboBoxFieldConnected
          name="year_under_review"
          isRequired
          options={yearOptions}
          isDisabled={existingProduct || !category}
          label={t("Period under review")}
          placeholder="YYYY"
        />
      </div>
    </div>
  );
};
