import { ArrowBack, DeleteOutlined, LaunchOutlined } from "@mui/icons-material";
import { useQueryClient } from "@tanstack/react-query";
import { Suspense, useCallback, useEffect, useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import {
  useCreatePrechainProduct,
  useUpdatePrechainProduct,
} from "../../api/endpoints/prechain-products";
import { Impact, PrechainProduct, Product } from "../../api/types";
import { Button } from "../../components/Button";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import { InfoTooltip } from "../../components/InfoTooltip";
import { Link } from "../../components/Link";
import { PageContainer } from "../../components/PageContainer";
import { Radio, RadioGroupField } from "../../components/RadioGroupField";
import { TextField } from "../../components/TextField";
import { TopBar } from "../../components/TopBar";
import { C1, C2, Heading2, Heading5 } from "../../components/Typography";
import { Text16 } from "../../components/TypographyOld";
import { ComboBoxFieldConnected } from "../../form-components/ComboBoxFieldConnected";
import { TextFieldConnected } from "../../form-components/TextFieldConnected";
import { coreIndicators, filterImpact } from "../../lib/impact";
import { useElementaries } from "../../state/elementaries";
import { useActiveManufacturer } from "../../state/manufacturers";
import { usePrechainProducts } from "../../state/materials";
import { useActivePlant, usePlants } from "../../state/plants";
import { useDeletePrechainProductById } from "../../state/prechainProducts";
import { useProducts } from "../../state/products";
import { useGetLinkWithParams } from "../../url/useGetLinkWithParams";
import { showSuccessToast } from "../../util/toasts";
import { GWPTotalBreakdown } from "./GWPTotalBreakdown";
import { ImpactFactors } from "./ImpactFactors";
import { DEFAULT_TRANSPORTATION, TransportationForm } from "./TransportationForm";
import { TransportationFormFields } from "./types";

interface Fields {
  plantId: string;
  productId: string;
  category: string;
  material: string;
  transportation: TransportationFormFields[];
}

const useOnSubmit = ({
  prechainProduct,
  onSubmitSuccess,
}: {
  prechainProduct: PrechainProduct | null;
  onSubmitSuccess: ({ id }: { id: string }) => void;
}) => {
  const activePlant = useActivePlant();
  const queryClient = useQueryClient();

  const createPrechainProduct = useCreatePrechainProduct();
  const updatePrechainProduct = useUpdatePrechainProduct();

  return useCallback(
    async (data: Fields) => {
      const transportation = data.transportation
        .filter((t) => !!t.distance)
        .map((t) => ({
          load: null,
          utilization: null,
          modality: t.modality,
          distance: t.distance!,
        }));

      let id;
      if (prechainProduct) {
        await updatePrechainProduct({
          plantId: activePlant.id,
          prechainProduct: {
            ...prechainProduct,
            transportation,
          },
        });
        id = prechainProduct.id;
      } else {
        const result = await createPrechainProduct({
          plantId: activePlant.id,
          prechainProduct: {
            product_id: data.productId,
            plant_id: activePlant.id,
            transportation,
          },
        });
        id = result.id;
      }

      queryClient.invalidateQueries({
        queryKey: [activePlant.id, "materials"],
      });
      queryClient.invalidateQueries({
        queryKey: [activePlant.id, "prechainProducts", id],
      });

      onSubmitSuccess({ id });
    },
    [
      activePlant,
      prechainProduct,
      queryClient,
      onSubmitSuccess,
      updatePrechainProduct,
      createPrechainProduct,
    ],
  );
};

const PrechainProductBaseFields = ({
  isEditing,
  prechainProduct,
  productOptions,
}: {
  isEditing: boolean;
  prechainProduct: PrechainProduct | null;
  productOptions: { id: string; label: string }[];
}) => {
  const methods = useFormContext<Fields>();
  const plantId = methods.watch("plantId");
  const { data: plants } = usePlants();
  const plantOptions = plants
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(({ id, name }) => ({ id, label: name }));

  const { activeManufacturer } = useActiveManufacturer();

  const { t } = useTranslation();
  return (
    <div className="grid grid-cols-2 gap-4">
      <div className="col-span-2">
        <TextField
          placeholder={t("Supplier")}
          value={activeManufacturer.name}
          label={t("Supplier")}
          isRequired
          isDisabled={isEditing}
          isViewOnly={!isEditing}
        />
      </div>
      <ComboBoxFieldConnected
        name="plantId"
        isRequired
        options={plantOptions}
        isDisabled={!!prechainProduct}
        isViewOnly={!isEditing}
        placeholder={t("Specify plant")}
        label={t("Your production plant")}
      />
      <ComboBoxFieldConnected
        name="productId"
        isRequired
        options={productOptions}
        isViewOnly={!isEditing}
        isDisabled={!plantId || !!prechainProduct}
        placeholder={t("Name of the product produced in your plant")}
        label={t("Product name")}
      />
      <TextFieldConnected
        name="category"
        isRequired
        placeholder={t("Category")}
        label={t("Category")}
        isDisabled={!!prechainProduct}
        isViewOnly={!isEditing}
      />
      <TextFieldConnected
        name="material"
        isRequired
        placeholder={t("Material")}
        label={t("Material")}
        isDisabled={!!prechainProduct}
        isViewOnly={!isEditing}
      />
    </div>
  );
};

/* eslint-disable complexity */
export const PrechainProductForm = ({
  prechainProduct,
}: {
  prechainProduct: PrechainProduct | null;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const getLinkWithParams = useGetLinkWithParams();

  const [isEditing, setIsEditing] = useState(!prechainProduct);

  const existingProducts = usePrechainProducts();

  const navigateBack = useCallback(() => {
    navigate(getLinkWithParams("/suppliers-and-materials"));
  }, [navigate, getLinkWithParams]);

  const methods = useForm<Fields>({
    defaultValues: {
      plantId: prechainProduct?.product?.plant_id || "",
      productId: prechainProduct?.product_id || "",
      category: "",
      material: "",
      transportation: [
        {
          modality: "truck",
          distance: NaN,
        },
      ],
    },
  });

  useEffect(
    function setInitialValues() {
      if (prechainProduct) {
        // Using setValue instead of reset to trigger useEffect on watch
        methods.setValue("plantId", prechainProduct.product.plant_id);
        methods.setValue("productId", prechainProduct.product.id);
        methods.setValue("transportation", prechainProduct.transportation);
      }
    },
    [prechainProduct, methods],
  );

  const activePlant = useActivePlant();

  // On plant change, fetch products and reset product field
  const plantId = methods.watch("plantId");
  const { data: products = [] } = useProducts({ plantId });

  const productOptions = products
    .filter((x) => x.status !== "incomplete")
    .filter(
      (x) =>
        x.id === prechainProduct?.product_id ||
        existingProducts.every((p) => p.product_id !== x.id),
    ) // Filter out existing products
    .map(({ id, name }) => ({ id, label: name }));

  useEffect(
    function updateTransportation() {
      if (prechainProduct) {
        // Transportation should be initialized already / and users shouldn't be able to
        // change the plant for an existing prechain product.
        return;
      }

      if (activePlant.id === plantId) {
        methods.setValue("transportation", []);
      } else {
        methods.setValue("transportation", DEFAULT_TRANSPORTATION);
      }
    },
    [activePlant, plantId, methods, prechainProduct],
  );

  const { elementariesMap } = useElementaries();

  const productId = methods.watch("productId");
  const name = productOptions.find((p) => p.id === productId)?.label;

  const [product, setProduct] = useState<Product | null>(null);

  useEffect(
    function onProductChange() {
      if (!productId) return;

      const prod = products.find((p) => p.id === productId);
      if (prod) {
        setProduct(prod);
        methods.setValue("category", t(elementariesMap[prod.elementary_id].category));
        methods.setValue("material", t(prod.material));
      } else {
        methods.setValue("category", "");
        methods.setValue("material", "");
      }
    },
    [productId, elementariesMap, methods, t, products],
  );

  const onSubmit = useOnSubmit({
    prechainProduct,
    onSubmitSuccess: ({ id }) => {
      if (prechainProduct) {
        setIsEditing(false);
      } else {
        navigate(`/suppliers-and-materials/edit-prechain-product/${id}`);
      }
      showSuccessToast(
        prechainProduct ? t("Product ingredient changed") : t("New product ingredient created"),
      );
    },
  });

  const { mutateAsync: deletePrechain, isPending: isDeleting } = useDeletePrechainProductById();
  const [confirmingDelete, setConfirmingDelete] = useState(false);

  const handleDelete = async () => {
    if (!prechainProduct) return;
    await deletePrechain(prechainProduct.id);
    navigateBack();
    showSuccessToast(t("Product ingredient deleted"));
  };

  return (
    <FormProvider {...methods}>
      <PageContainer $as="form" onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        <TopBar
          title={prechainProduct ? t("Back to material overview") : undefined}
          icon={!!prechainProduct && <ArrowBack />}
          onIconClick={navigateBack}
          input={
            isEditing ? (
              <>
                <Button
                  intent="secondary"
                  onPress={prechainProduct ? () => setIsEditing(false) : navigateBack}
                >
                  {t("Cancel")}
                </Button>
                <Button type="submit">{t("Save material")}</Button>
              </>
            ) : (
              <Button intent="secondary" type="button" onPress={() => setIsEditing(true)}>
                {t("Edit material")}
              </Button>
            )
          }
        />
        <div className="flex flex-col mt-6 mb-8 h-full">
          <div className="flex flex-row gap-3 w-full pb-6 pr-6 border-b border-neutral-200 [&_h2]:truncate">
            <Heading2>
              {name || <span className="text-neutral-400">{t("Product name")}</span>}
            </Heading2>
          </div>
          <div className="flex h-full divide-x">
            <div className="w-1/2 pr-6 py-6 flex flex-col gap-4">
              <Heading5>{t("Product details")}</Heading5>
              <PrechainProductBaseFields
                isEditing={isEditing}
                prechainProduct={prechainProduct}
                productOptions={productOptions}
              />
              {methods.watch("transportation").length > 0 && (
                <>
                  <hr className="border-neutral-200 w-full" />
                  <TransportationForm isEditing={isEditing} />
                </>
              )}
              {prechainProduct && isEditing && (
                <>
                  <hr className="border-neutral-200 w-full" />
                  <div>
                    <Button
                      type="button"
                      onPress={() => setConfirmingDelete(true)}
                      isDisabled={isDeleting}
                      intent="tertiary"
                      danger
                    >
                      <DeleteOutlined />
                      {t("Delete Product")}
                    </Button>
                  </div>
                </>
              )}
            </div>
            <div className="w-1/2 py-6 px-6 flex flex-col gap-4">
              {product && (
                <>
                  <Heading5>{t("Linked environmental data")}</Heading5>
                  <Suspense>
                    <LinkedProductDetails
                      product={product}
                      isEditing={isEditing}
                      impactsPerKg={prechainProduct?.impacts_per_kg || null}
                    />
                  </Suspense>
                </>
              )}
            </div>
          </div>
        </div>
        {confirmingDelete && (
          <ConfirmationModal
            title={t("Delete product ingredient")}
            content={<Text16>{t("Confirm the deletion")}</Text16>}
            danger
            onConfirm={handleDelete}
            onCancel={() => setConfirmingDelete(false)}
          />
        )}
      </PageContainer>
    </FormProvider>
  );
};

const LinkedProductDetails = ({
  product,
  isEditing,
  impactsPerKg,
}: {
  product: Product;
  impactsPerKg: Impact[] | null;
  isEditing: boolean;
}) => {
  const { t } = useTranslation();
  const productLink = window.location.origin + "/products/" + product.id;

  if (!product.gwp_total) {
    return t("Couldn't find linked data for this product");
  }

  return (
    <div className="flex flex-col gap-4 h-full">
      <div className="flex gap-2 items-center">
        <C1 $bold>{t("Own product")}</C1>
        <InfoTooltip>
          {t(
            "This product was modeled using primary data, which is used to calculate environmental impacts of other products containing this product in the recipe.",
          )}
        </InfoTooltip>
      </div>

      <div className="flex gap-4 items-center">
        {isEditing ? (
          <RadioGroupField value="own" isDisabled aria-label="Own product">
            <Radio value="own" isDisabled />
          </RadioGroupField>
        ) : (
          <div className="shrink-0 bg-energyyellow text-builtgreen size-12 font-black flex items-center justify-center">
            Prod
          </div>
        )}
        <div className="w-full overflow-hidden">
          <div className="w-full flex items-center gap-2 [&_p]:truncate">
            <C1>{product.name}</C1>
            <Link intent="link" size="small" href={productLink} target="_blank">
              <LaunchOutlined className="text-neutral-500" fontSize="small" />
            </Link>
          </div>
          <C2 className="text-neutral-400">{t(product.material)}</C2>
        </div>
      </div>
      {!isEditing && impactsPerKg && (
        <>
          <GWPTotalBreakdown impacts={impactsPerKg} />
          <hr className="border-neutral-200 w-full" />
          <div className="grid grid-cols-2 gap-4">
            <ImpactFactors impacts={filterImpact(impactsPerKg, coreIndicators)} />
          </div>
        </>
      )}
    </div>
  );
};
