import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ComboBoxFieldConnected } from "../form-components/ComboBoxFieldConnected";
import { TextFieldConnected } from "../form-components/TextFieldConnected";

export const SearchFields = () => {
  const { t } = useTranslation();

  return (
    <div className="grid grid-cols-[150px_1fr_1fr] gap-x-6">
      <ComboBoxFieldConnected
        label={t("Language")}
        name="language"
        options={useMemo(
          () => [
            { id: "en", label: "English" },
            { id: "de", label: "German" },
          ],
          [],
        )}
      />
      <TextFieldConnected name="productName" label={t("Product Name")} />
      <TextFieldConnected name="manufacturer" label={t("Manufacturer")} />
    </div>
  );
};
