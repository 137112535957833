import { useTranslation } from "react-i18next";
import tw from "tailwind-styled-components";
import { Elementary } from "../../api/types";
import { apiLink } from "../../api/useApi";
import { Heading4, Text16 } from "../../components/TypographyOld";
import { useElementaries } from "../../state/elementaries";
import { useProductMetadata } from "../../state/productMetadata";
import { exists } from "../../util/commonUtil";

export const ProductDefaultImages = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-6 py-4">
      <div className="grid grid-cols-2 items-center">
        <Heading4>{t("Product default images")}</Heading4>
      </div>
      <ImageList />
    </div>
  );
};

const ImageList = () => {
  const { elementaries } = useElementaries();
  const {
    data: { productMetadata },
  } = useProductMetadata();

  const data = elementaries
    .filter((e) => exists(e.product_metadata_id))
    .reduce(
      (acc, elementary) => {
        const meta = productMetadata.find((pm) => pm.id === elementary.product_metadata_id);
        if (!meta) return acc;
        if (!acc[meta.img_url]) acc[meta.img_url] = [];
        acc[meta.img_url].push(elementary);
        return acc;
      },
      {} as Record<string, Elementary[]>,
    );

  return (
    <div>
      <Content>
        {Object.entries(data).map(([imgUrl, elementaries]) => (
          <ImageCard key={imgUrl} imgUrl={imgUrl} elementaries={elementaries} />
        ))}
      </Content>
    </div>
  );
};

const Content = tw("div")`
  grid
  gap-5
  grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 3xl:grid-cols-5 4xl:grid-cols-6
`;

const Card = tw("div")`
  p-2
  shadow-e-xs hover:shadow-e-sm active:shadow-e-xs
  translate-y-0 hover:translate-y-[-1px] active:translate-y-0
  transition-all
  [&_img]:transition-opacity
  hover:[&_img]:opacity-80
  flex
  flex-col
  gap-3
  border border-neutral-300 hover:border-energyyellow
`;

const ImageCard = ({ imgUrl, elementaries }: { imgUrl: string; elementaries: Elementary[] }) => {
  const { t } = useTranslation();
  return (
    <Card>
      <div className="shrink-0  bg-white relative w-full pb-[100%]">
        <img
          className="w-full h-full absolute left-0 top-0 z-10 object-cover select-none pointer-events-none"
          src={apiLink(`/static${imgUrl}`)}
          alt="Product"
        />
      </div>
      <Heading4>
        {elementaries.length} {elementaries.length === 1 ? "Elementary" : "Elementaries"}
      </Heading4>
      <Text16 className="text-neutral-500">{elementaries.map((e) => t(e.name)).join(", ")}</Text16>
    </Card>
  );
};
