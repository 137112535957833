import { useTranslation } from "react-i18next";
import { Impact } from "../../api/types";
import { TextField } from "../../components/TextField";
import { useExtrasPerImpactName } from "../../lib/impact";
import { formatPrecision } from "../../util/format";

export const ImpactFactors = ({ impacts }: { impacts: Impact[] }) => {
  const { t } = useTranslation();
  const extrasPerImpactName = useExtrasPerImpactName();
  return (
    <>
      {impacts.map((impact) => {
        const unitSplit = t(impact.unit).split(" ");
        const shortUnit = unitSplit[0].replace(",", "");
        const restUnit = unitSplit.slice(1).join(" ");
        const labelUnit = restUnit.length > 0 ? restUnit : t(impact.unit);

        const indicatorExtra =
          extrasPerImpactName[impact.indicator_name as keyof typeof extrasPerImpactName];

        return (
          <TextField
            key={impact.indicator_name}
            isViewOnly
            label={`${indicatorExtra.niceName} (${indicatorExtra.indicatorName}) in ${labelUnit}`}
            value={`${formatPrecision(impact.value, 4)} ${shortUnit}`}
          />
        );
      })}
    </>
  );
};
