import { cva } from "class-variance-authority";
import { Decimal } from "decimal.js";
import { Button as ButtonPrimitive } from "react-aria-components";
import { useTranslation } from "react-i18next";
import { Fragment } from "react/jsx-runtime";
import { Impact, SourceImpacts } from "../../api/types";
import { Tooltip, TooltipTrigger } from "../../components/Tooltip";
import { Label12, Label14 } from "../../components/TypographyOld";
import { coreIndicators } from "../../lib/impact";
import { useImpactColors } from "./useImpactColors";

const cellStyles = cva([
  "border-r",
  "border-b",
  "border-neutral-300",
  "px-2",
  "py-4",
  "line-clamp-1",
  "h-full",
  "flex",
  "items-center",
  "relative",
])();

export type Totals = Record<Impact["indicator_name"], number>;

const preciseFormat = (value: Decimal, precision: number) => {
  if (value.isZero()) {
    return "0";
  }
  const threshold = new Decimal(1).dividedBy(Math.pow(10, precision));
  if (value.abs().greaterThanOrEqualTo(threshold)) {
    return value.toFixed(precision);
  } else if (value.greaterThan(0)) {
    // Value is in the range [0, threshold]
    return `< ${threshold.toString()}`;
  } else {
    // Value is in the range [-threshold, 0]
    return "< 0";
  }
};

export const BreakdownPerMaterialChart = ({
  impactsPerSource,
  totals,
}: {
  impactsPerSource: SourceImpacts[];
  totals: Totals;
}) => {
  const { t } = useTranslation();

  const colors = useImpactColors();

  const getGwpTotal = (sourceImpacts: SourceImpacts) =>
    new Decimal(
      sourceImpacts.impacts_total.find((x) => x.indicator_name === "GWP-total")?.value ?? 0,
    );

  return (
    <div className="grid grid-cols-[300px_repeat(8,1fr)] border-b border-t border-neutral-300 items-center pb-10">
      <div className={cellStyles}>{t("Source")}</div>
      {Array.from(coreIndicators).map((indicator) => (
        <div className={cellStyles} key={indicator}>
          <div
            className="w-2 h-2 rounded-full shrink-0 mr-2"
            style={{ backgroundColor: colors[indicator] }}
          />
          <Label12 className="line-clamp-1" title={indicator}>
            {indicator}
          </Label12>
        </div>
      ))}
      {impactsPerSource
        .sort((a, b) => getGwpTotal(b).minus(getGwpTotal(a)).toNumber())
        .map((impactPerSource) => {
          const data = Array.from(coreIndicators).map((indicator) => {
            const totalImpact = impactPerSource.impacts_total.find(
              (x) => x.indicator_name === indicator,
            );

            const totalValue = new Decimal(totalImpact?.value ?? 0);
            const unit = totalImpact?.unit ?? "";

            const transportValue = new Decimal(
              impactPerSource.impacts_transport?.find((x) => x.indicator_name === indicator)
                ?.value ?? 0,
            );

            const shares =
              totals[indicator] > 0
                ? {
                    totalShare: totalValue.dividedBy(totals[indicator]).times(100),
                    transportShare: transportValue.dividedBy(totals[indicator]).times(100),
                    elementShare: totalValue
                      .minus(transportValue)
                      .dividedBy(totals[indicator])
                      .times(100),
                  }
                : {
                    // In the rare case when the total impact is negative,
                    // it does not make sense to report the share of the total impact,
                    // so we just display 0.
                    totalShare: new Decimal(0),
                    transportShare: new Decimal(0),
                    elementShare: new Decimal(0),
                  };

            return {
              indicatorName: indicator,
              transportApplies: !!impactPerSource.impacts_transport,
              total: totalValue,
              ...shares,
              unit,
            };
          });

          return (
            <Fragment key={impactPerSource.name}>
              <div className={cellStyles} key={impactPerSource.name + "header"}>
                <Label14 title={impactPerSource.name} className="line-clamp-1">
                  {impactPerSource.name}
                </Label14>
              </div>
              {data.map((cell, index) => {
                return (
                  <div className={cellStyles} key={impactPerSource.name + index}>
                    <TooltipTrigger>
                      <ButtonPrimitive className="h-full w-full flex justify-start items-center gap-1">
                        <div
                          className="h-full flex items-center"
                          style={{
                            width: `${cell.totalShare.abs().toNumber()}%`,
                          }}
                        >
                          <div
                            className="h-full"
                            style={{
                              width: `${cell.elementShare.dividedBy(cell.totalShare).times(100).toNumber()}%`,
                              backgroundColor: colors[cell.indicatorName],
                            }}
                          />
                          <div
                            className="h-full opacity-50"
                            style={{
                              width: `${cell.transportShare.dividedBy(cell.totalShare).times(100).toNumber()}%`,
                              backgroundColor: colors[cell.indicatorName],
                            }}
                          />
                        </div>
                        <Label12 className="text-right shrink-0">
                          {preciseFormat(cell.totalShare, 1)}%
                        </Label12>
                      </ButtonPrimitive>
                      <Tooltip>
                        <strong>
                          {cell.indicatorName}: {preciseFormat(cell.totalShare, 5)}% (
                          {preciseFormat(cell.total, 5)} {cell.unit})
                        </strong>
                        {cell.transportApplies && (
                          <ul className="list-disc pl-4">
                            <li>
                              {t("Element share")}: {preciseFormat(cell.elementShare, 5)}%
                            </li>
                            <li>
                              {t("Transport share")}: {preciseFormat(cell.transportShare, 5)}%
                            </li>
                          </ul>
                        )}
                      </Tooltip>
                    </TooltipTrigger>
                  </div>
                );
              })}
            </Fragment>
          );
        })}
    </div>
  );
};
