import { Suspense } from "react";
import { PrechainProductForm } from "../page-components/suppliers-and-materials/PrechainProductForm";
import { SuppliersAndMaterialsForm } from "../page-components/suppliers-and-materials/SuppliersAndMaterialsForm";

export const NewMaterial = ({ type }: { type: "raw_materials" | "packaging" }) => {
  return (
    <Suspense>
      <SuppliersAndMaterialsForm existingMaterial={null} type={type} />
    </Suspense>
  );
};

export const NewPrechainProduct = () => {
  return <PrechainProductForm prechainProduct={null} />;
};
