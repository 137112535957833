import { SearchOutlined } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button } from "../components/Button";
import { PageContainer } from "../components/PageContainer";
import { TopBar } from "../components/TopBar";
import { C1 } from "../components/Typography";
import { SearchFields } from "../page-components/SearchFields";
import { SearchResults } from "../page-components/SearchResults";
import { useSearchEPDs } from "../state/epds";

interface SearchFormValues {
  productName: string;
  manufacturer: string;
  language?: string;
}

export const Search = () => {
  const [searchedLanguage, setSearchedLanguage] = useState<string | undefined>(undefined);
  const { mutate: search, isPending, data: searchResults } = useSearchEPDs();
  const { t } = useTranslation();

  const handleSubmit = (values: SearchFormValues) => {
    const language = values.language?.toLowerCase();

    setSearchedLanguage(language);

    search({
      searchTerm: [values.productName, values.manufacturer].join(" ").trim(),
      language,
      maxResults: 1000,
    });
  };

  const methods = useForm<SearchFormValues>({
    defaultValues: {
      productName: "",
      manufacturer: "",
      language: "en",
    },
  });

  const keyToFixPagination = useMemo(() => {
    // Somehow, when the search results change, the pagination stops working.
    // so we're using a key to remount the results
    return JSON.stringify(searchResults?.map((r) => r.id));
  }, [searchResults]);

  return (
    <FormProvider {...methods}>
      <PageContainer $as="form" onSubmit={methods.handleSubmit(handleSubmit)} noValidate>
        <TopBar
          title={t("Search")}
          icon={<SearchOutlined />}
          input={
            <Button type="submit" isDisabled={isPending}>
              {isPending && <CircularProgress size="24px" />}
              {t("Search")}
            </Button>
          }
        />
        <div className="grid grid-rows-[auto_1fr] pt-8 pb-20 space-y-8">
          <SearchFields />
          {searchResults && (
            <SearchResults
              key={keyToFixPagination}
              results={searchResults || []}
              searchedLanguage={searchedLanguage}
            />
          )}
          {!searchResults && (
            <div className="flex items-center justify-center py-20">
              <C1>{t("Enter a search term to browse the Emidat database.")}</C1>
            </div>
          )}
        </div>
      </PageContainer>
    </FormProvider>
  );
};
