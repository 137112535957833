import { cva } from "class-variance-authority";
import { forwardRef } from "react";
import { Label as LabelPrimitive, LabelProps } from "react-aria-components";

// For field components to use within a container div
export const movingLabelStyles = cva([
  "relative",
  "[&>label]:absolute",
  "[&>label]:z-10",
  "[&>label]:left-4",
  "[&>label]:text-base",
  "[&>label]:transition-all",
  "[&>label]:duration-200",
  "[&>label]:top-1/2 [&:has(textarea)>label]:top-6.5",
  "[&>label]:-translate-y-1/2",
  "[&:has(input:focus)>label]:top-1.5",
  "[&:has(input:focus)>label]:translate-y-0!",
  "[&:has(input:focus)>label]:text-sm!",
  "[&:has(input:not(:placeholder-shown))>label]:top-1.5",
  "[&:has(input:not(:placeholder-shown))>label]:translate-y-0!",
  "[&:has(input:not(:placeholder-shown))>label]:text-sm!",
  "[&:has(textarea:focus)>label]:top-1.5",
  "[&:has(textarea:focus)>label]:translate-y-0!",
  "[&:has(textarea:focus)>label]:text-sm!",
  "[&:has(textarea:not(:placeholder-shown))>label]:top-1.5",
  "[&:has(textarea:not(:placeholder-shown))>label]:translate-y-0!",
  "[&:has(textarea:not(:placeholder-shown))>label]:text-sm!",
])();

const styles = cva("text-sm text-neutral-400", {
  variants: {
    isDisabled: {
      true: "text-neutral-400",
    },
  },
});

/**
 * Displays a label for a form field.
 * It is used to label input, select, combobox elements.
 */
export const FieldLabel = forwardRef<
  HTMLLabelElement,
  Omit<LabelProps, "className" | "styles"> & {
    isRequired?: boolean;
    isDisabled?: boolean;
  }
>(({ children, isRequired, isDisabled, ...props }, ref) => {
  return (
    <LabelPrimitive className={styles({ isDisabled })} {...props} ref={ref}>
      <span className={isRequired ? "after:ml-0.5 after:content-['*']" : ""}>{children}</span>
    </LabelPrimitive>
  );
});
