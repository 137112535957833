import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  useDeletePrechainProduct,
  useGetPrechainProduct,
} from "../api/endpoints/prechain-products";
import { useActivePlant } from "./plants";

export const usePrechainProduct = (id: string) => {
  const activePlant = useActivePlant();
  const getPrechainProduct = useGetPrechainProduct();

  return useQuery({
    queryKey: [activePlant.id, "prechainProducts", id],
    queryFn: () =>
      getPrechainProduct({
        plantId: activePlant.id,
        prechainProductId: id,
      }),
  });
};

export const useDeletePrechainProductById = () => {
  const activePlant = useActivePlant();
  const queryClient = useQueryClient();
  const deletePrechainProduct = useDeletePrechainProduct();

  return useMutation({
    mutationFn: (id: string) => {
      return deletePrechainProduct({
        plantId: activePlant.id,
        prechainProductId: id,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [activePlant.id, "materials"] });
    },
  });
};
