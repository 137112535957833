import { useMemo } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ProductWithStatus } from "../../api/types";
import { Button } from "../../components/Button";
import { Heading4 } from "../../components/TypographyOld";
import { ComboBoxFieldConnected } from "../../form-components/ComboBoxFieldConnected";
import { TextFieldConnected } from "../../form-components/TextFieldConnected";
import { useDeclarationVerification as useVerifiyDeclaration } from "../../state/declarations";
import { useActivePlant } from "../../state/plants";
import { useProducts } from "../../state/products";
import { showErrorToast, showSuccessToast } from "../../util/toasts";

type Fields = {
  productId: string | null;
  developer: string;
  reviewer: string;
};

export const VerifyDeclaration = () => {
  const { t } = useTranslation();
  const activePlant = useActivePlant();
  const { mutateAsync: verifyDeclaration, isPending } = useVerifiyDeclaration();

  const methods = useForm<Fields>({
    defaultValues: {
      productId: null,
      developer: "",
      reviewer: "",
    },
  });

  const { data: products } = useProducts({
    plantId: activePlant.id,
  });

  const filteredProducts = useMemo(
    () => products?.filter((p: ProductWithStatus) => p.status === "under-review"),
    [products],
  );

  const productOptions = useMemo(
    () => filteredProducts?.map((p) => ({ label: p.name, value: p.id, id: p.id })) || [],
    [filteredProducts],
  );

  const onSubmit: SubmitHandler<Fields> = async (formData) => {
    if (!formData.productId) return;

    const token = prompt("Please enter your token:");
    if (!token) return;

    await verifyDeclaration(
      {
        plantId: activePlant.id,
        productId: formData.productId,
        data: {
          developer: formData.developer,
          reviewer: formData.reviewer,
          token,
        },
      },
      {
        onSuccess: () => {
          showSuccessToast("Success.");
        },
        onError: () => {
          showErrorToast("Failed.");
        },
      },
    );
  };

  return (
    <FormProvider {...methods}>
      <form className="flex flex-col gap-6 py-4" onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="grid grid-cols-2 items-center">
          <Heading4>{t("Verify declaration")}</Heading4>
        </div>

        <div className="flex flex-col gap-6">
          <ComboBoxFieldConnected name="productId" label="Product" options={productOptions} />
          <div className="grid items-center grid-cols-2 gap-6">
            <TextFieldConnected name="developer" label="Developer of the EPD" />
            <TextFieldConnected name="reviewer" label="Internal Reviewer" />
          </div>
          <div>
            <Button intent="primary" type="submit" isDisabled={isPending}>
              {isPending ? "In progress ..." : "Generate Final EPD and send to EPD-Norge"}
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
