import {
  ClearOutlined,
  Commit,
  FilterListOutlined,
  GroupWorkOutlined,
  HomeWorkOutlined,
  Inventory2Outlined,
  TokenOutlined,
} from "@mui/icons-material";
import {
  GridColDef,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridRowModel,
  GridRowParams,
} from "@mui/x-data-grid-pro";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Button as ButtonPrimitive } from "react-aria-components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { HazardousShare, Impact, PlantMaterial, Silo } from "../../api/types";
import { Badge } from "../../components/Badge";
import { Button } from "../../components/Button";
import { DataGrid } from "../../components/DataGrid";
import { StatusIndicator } from "../../components/StatusIndicator";
import { Tooltip, TooltipTrigger } from "../../components/Tooltip";
import { C1 } from "../../components/Typography";
import { Label16, Text16 } from "../../components/TypographyOld";
import { useElementaries } from "../../state/elementaries";
import { useActiveManufacturer } from "../../state/manufacturers";
import { useActivePlant, usePlants } from "../../state/plants";
import { useGetLinkWithParams } from "../../url/useGetLinkWithParams";
import { exists } from "../../util/commonUtil";
import { formatPrecision } from "../../util/format";
import { SearchControls } from "../products/SearchControls";
import { useFilterSidePanel } from "./FiltersSidePanel";
import { MaterialsMultiSelectActionBar } from "./MaterialsMultiSelectActionBar";
import { useMaterialSelection } from "./useMaterialSelection";

const GwpBadge = ({ impacts }: { impacts: Impact[] }) => {
  const gwp = impacts.find((impact) => impact.indicator_name === "GWP-total")?.value;

  const formatted = (() => {
    if (gwp === undefined) return "";
    if (gwp === 0) return "0";
    if (gwp < 0) return "< 0";
    if (gwp < 0.001) return "< 0.001";

    return formatPrecision(gwp, 3);
  })();

  return (
    <Badge color="brownLight" size="sm">
      {formatted} kg CO2e
    </Badge>
  );
};

const HazardousSubstancesCell = ({
  hazardousSubstances,
}: {
  hazardousSubstances: HazardousShare[];
}) => {
  const { t } = useTranslation();
  const visibleSubstances = hazardousSubstances.slice(0, 1);
  const remainingCount = Math.max(0, hazardousSubstances.length - 1);

  if (visibleSubstances.length === 0) return null;

  return (
    <div className="h-full flex items-center gap-2">
      {visibleSubstances.map((hazardousSubstance) => (
        <Badge size="sm" color="brownLight" key={hazardousSubstance.cas_number}>
          {hazardousSubstance.cas_number} ({hazardousSubstance.percentage} %)
        </Badge>
      ))}
      {remainingCount > 0 && (
        <TooltipTrigger>
          <ButtonPrimitive className="text-sm text-neutral-500">
            +{remainingCount} {t("more")}
          </ButtonPrimitive>
          <Tooltip>
            <div className="flex flex-wrap gap-2">
              {hazardousSubstances.slice(1).map((hazardousSubstance) => (
                <Badge key={hazardousSubstance.cas_number} size="sm" color="white">
                  {hazardousSubstance.cas_number} ({hazardousSubstance.percentage} %)
                </Badge>
              ))}
            </div>
          </Tooltip>
        </TooltipTrigger>
      )}
    </div>
  );
};

export const MaterialsListView = ({
  materials,
  filters,
  columnVisibilityModel,
  onColumnVisibilityModelChange,
  onSiloEdit,
}: {
  materials: PlantMaterial[];
  filters: ReturnType<typeof useFilterSidePanel>;
  columnVisibilityModel: GridColumnVisibilityModel;
  onColumnVisibilityModelChange: (model: GridColumnVisibilityModel) => void;
  onSiloEdit: (silo: Silo) => void;
}) => {
  const navigate = useNavigate();
  const activePlant = useActivePlant();
  const getLinkWithParams = useGetLinkWithParams();
  const { activeManufacturer } = useActiveManufacturer();

  const { data: plants } = usePlants();
  const { t } = useTranslation();

  const { elementaries } = useElementaries();

  const {
    isSelectMode,
    selectedMaterials,
    setSelectedMaterials,
    toggleSelect,
    toggleSelectMode,
    toggleSelectAll,
    resetSelection,
  } = useMaterialSelection({ materials });

  const getRow = useCallback(
    (input: PlantMaterial, group?: string): GridRowModel => {
      if (input.silo) {
        const elementary = elementaries.find(
          (c) => c.id === input.silo.items[0].supplier_product?.elementary_id,
        );
        return [
          {
            canSelect: false,
            id: input.silo.id,
            group: [input.silo.name],
            category: t(elementary?.category ?? ""),
            materialName: t(elementary?.name ?? ""),
            supplierCompany: null,
            plant: null,
            epdLinked: undefined,
            isPrechainProduct: false,
            isPackaging: false,
            isSiloRoot: true,
            recyclingShare: null,
            hazardousSubstances: [],
            hazardousSubstancesString: "",
            material: input,
          },
          ...input.silo.items
            .filter((it) => !!it.supplier_product)
            .map(
              (item) =>
                ({
                  supplier_product: item.supplier_product,
                  silo: undefined,
                  prechain_product: undefined,
                }) as PlantMaterial,
            )
            .map((m) => getRow(m, input.silo.name)),
        ];
      }

      if (input.supplier_product) {
        const item = input.supplier_product;
        const elementary = elementaries.find((c) => c.id === item.elementary_id);
        return {
          canSelect: !group,
          id: item.id,
          group: [...(group ? [group] : []), `${item.name}`],
          category: t(elementary!.category),
          materialName: t(elementary!.name),
          supplierCompany: item.supplier_plant?.supplier_company.name,
          plant: item.supplier_plant?.name,
          epdLinked: !!item.epd_id,
          isPrechainProduct: false,
          isPackaging: elementary?.type === "packaging",
          isSiloRoot: false,
          recyclingShare: item.secondary_percentage,
          hazardousSubstancesString: item.hazardous_substances
            .map((h) => `${h.cas_number} (${h.percentage}%)`)
            .join(", "),
          hazardousSubstances: item.hazardous_substances,
          material: input,
        };
      }

      if (!input.prechain_product) {
        throw new Error("Invalid input");
      }

      const item = input.prechain_product;
      const elementary = elementaries.find((c) => c.id === item.product.elementary_id);
      return {
        canSelect: true,
        id: item.id,
        group: [t(item.product.name)],
        category: t(elementary!.category),
        materialName: elementary!.name,
        supplierCompany: activeManufacturer.name,
        plant: plants.find((p) => p.id === item.product.plant_id)?.name,
        epdLinked: false,
        isPrechainProduct: true,
        isPackaging: false,
        isSiloRoot: false,
        recyclingShare: null,
        hazardousSubstances: [],
        hazardousSubstancesString: "",
        material: input,
      };
    },
    [t, activeManufacturer.name, plants, elementaries],
  );

  const columns: GridColDef[] = useMemo(() => {
    return [
      {
        field: "category",
        headerName: t("Category"),
        minWidth: 300,
        renderCell: ({ value }) => {
          return (
            <div className="flex items-center gap-2 h-full">
              <Text16>{value}</Text16>
            </div>
          );
        },
      },
      {
        field: "materialName",
        headerName: t("Material"),
        minWidth: 300,
        renderCell: ({ value }) => (
          <div className="flex items-center gap-2 h-full">
            <Label16>{value}</Label16>
          </div>
        ),
      },
      {
        field: "supplierCompany",
        headerName: t("Supplier"),
        minWidth: 250,
        renderCell: ({ value, row }) => (
          <div className="h-full flex items-center gap-3">
            {row.isPrechainProduct && <HomeWorkOutlined />}
            {value}
          </div>
        ),
      },
      {
        field: "plant",
        headerName: t("Supplier plant"),
        minWidth: 200,
        renderCell: ({ value, row }) => (
          <div className="h-full flex items-center gap-3">
            {row.isPrechainProduct && <HomeWorkOutlined />}
            {value}
          </div>
        ),
      },
      {
        field: "gwpTotal",
        headerName: t("GWP-total"),
        description: t("Global Warming Potential for production and transport of 1 kg of material"),
        minWidth: 150,
        renderCell: ({ row }) =>
          row.material.supplier_product ? (
            <GwpBadge impacts={row.material.supplier_product.impacts ?? []} />
          ) : row.isPrechainProduct ? (
            <GwpBadge impacts={row.material.prechain_product.impacts_per_kg ?? []} />
          ) : null,
      },
      {
        field: "epdLinked",
        headerName: t("Linked data"),
        minWidth: 200,
        align: "center",
        renderCell: ({ value, row }) => (
          <div className="h-full flex items-center">
            {row.isPrechainProduct ? (
              <div className="flex items-center gap-1">
                <Commit fontSize="small" className="-translate-x-0.5" />
                <Label16>{t("Own Product")}</Label16>
              </div>
            ) : value === undefined ? null : (
              <StatusIndicator
                text={value ? "EPD" : t("Secondary Data")}
                done={!!value}
                missing={!value}
              />
            )}
          </div>
        ),
      },
      {
        field: "recyclingShare",
        headerName: t("Recycled share"),
        minWidth: 200,
        renderCell: ({ value }) =>
          value ? (
            <Badge size="sm" color="brownLight">
              {value}%
            </Badge>
          ) : null,
      },
      {
        field: "hazardousSubstancesString",
        headerName: t("Hazardous substances"),
        minWidth: 250,
        renderCell: ({ value, row }) =>
          value && value.length > 0 ? (
            <HazardousSubstancesCell hazardousSubstances={row.hazardousSubstances} />
          ) : null,
      },
    ];
  }, [t]);

  const [debouncedSearchInput, setDebouncedSearchInput] = useState("");

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    quickFilterValues: [],
  });

  useEffect(() => {
    setFilterModel((value) => ({
      ...value,
      quickFilterValues: debouncedSearchInput.split(" ") ?? [],
    }));
  }, [debouncedSearchInput]);

  const rows = useMemo(() => {
    const allRows = materials.flatMap((m) => getRow(m));

    // Count occurrences of each group path
    const groupCounts = new Map<string, number>();
    allRows.forEach((row) => {
      if (row.group) {
        const groupPath = row.group.join(" > ");
        groupCounts.set(groupPath, (groupCounts.get(groupPath) || 0) + 1);
      }
    });

    // Keep track of how many times we've seen each group path
    const groupSeen = new Map<string, number>();

    return allRows
      .map((row) => {
        if (!row.group) return row;

        const groupPath = row.group.join(" > ");
        const count = groupCounts.get(groupPath)!;
        if (count > 1) {
          const seen = (groupSeen.get(groupPath) || 0) + 1;
          groupSeen.set(groupPath, seen);
          return {
            ...row,
            group: [...row.group.slice(0, -1), `${row.group[row.group.length - 1]} (${seen})`],
          };
        }
        return row;
      })
      .sort(
        (a, b) =>
          a.category.localeCompare(b.category) || a.materialName.localeCompare(b.materialName),
      );
  }, [getRow, materials]);

  return (
    <div className="flex flex-col gap-4">
      <MaterialsMultiSelectActionBar
        materials={materials}
        selectedMaterials={selectedMaterials}
        isSelectMode={isSelectMode}
        toggleSelectMode={toggleSelectMode}
        toggleSelectAll={toggleSelectAll}
        resetSelection={resetSelection}
        extraRightSideContent={
          isSelectMode ? null : (
            <div className="flex items-center gap-2">
              <div className="grid w-full sm:w-[220px]">
                <SearchControls search={debouncedSearchInput} setSearch={setDebouncedSearchInput} />
              </div>
              <Button
                intent={filters.hasFilters ? "primary" : "secondary"}
                onPress={() => filters.setPanelOpen(true)}
              >
                <FilterListOutlined />
                {filters.panelOpen ? t("Close filter") : t("Filter")}
              </Button>
              {filters.hasFilters && (
                <TooltipTrigger>
                  <Button intent="secondary" onPress={filters.onClearFilters}>
                    <ClearOutlined />
                  </Button>
                  <Tooltip>{t("Clear filters")}</Tooltip>
                </TooltipTrigger>
              )}
            </div>
          )
        }
      />
      <DataGrid
        treeData
        getTreeDataPath={(row) => row.group}
        groupingColDef={{
          headerName: t("Name"),
          minWidth: 300,
          renderCell: ({ value, row: { isPackaging, isSiloRoot }, rowNode }) => {
            const depth = rowNode?.depth ?? 0;
            const indent = depth * 33;
            return (
              <div
                className="relative flex items-center gap-2 h-full"
                style={{ paddingLeft: indent }}
              >
                {depth > 0 && (
                  <>
                    <div className="absolute left-3 top-0 w-px h-full bg-neutral-300" />
                    <div className="absolute left-3 top-1/2 w-2 h-px -translate-y-1/2 bg-neutral-300" />
                  </>
                )}
                {depth === 0 &&
                  (isPackaging ? (
                    <Inventory2Outlined />
                  ) : isSiloRoot ? (
                    <GroupWorkOutlined />
                  ) : (
                    <TokenOutlined />
                  ))}
                <C1 $bold>{value}</C1>
              </div>
            );
          },
        }}
        id={`suppliers-and-materials-${activePlant.id}`}
        disableColumnFilter
        initialState={{
          columns: {
            columnVisibilityModel,
          },
          filter: {
            filterModel,
          },
        }}
        defaultGroupingExpansionDepth={1}
        isRowSelectable={({ row }) => row.canSelect}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={onColumnVisibilityModelChange}
        filterModel={filterModel}
        onFilterModelChange={setFilterModel}
        rows={rows}
        columns={columns}
        onRowClick={(params: GridRowParams) => {
          if (isSelectMode && !params.row.canSelect) return;
          if (isSelectMode) {
            toggleSelect(params.row.material);
            return;
          }
          if (params.row.material.silo) {
            onSiloEdit(params.row.material.silo);
            return;
          }
          if (params.row.isPrechainProduct) {
            navigate(
              getLinkWithParams(`/suppliers-and-materials/edit-prechain-product/${params.row.id}`),
            );
          } else {
            navigate(getLinkWithParams(`/suppliers-and-materials/edit/${params.row.id}`));
          }
        }}
        checkboxSelection={isSelectMode}
        onRowSelectionModelChange={(newSelectionModel) => {
          const selectedRows = rows.filter((row) => newSelectionModel.includes(row.id));
          setSelectedMaterials(new Set(selectedRows.map((row) => row.material)));
        }}
        rowSelectionModel={[...selectedMaterials]
          .map((m) => m.supplier_product?.id || m.prechain_product?.id)
          .filter(exists)}
      />
    </div>
  );
};
