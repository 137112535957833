import { useCallback } from "react";
import { BulkUploadResult, PlantMaterial, SupplierMaterial } from "../types";
import { useApi } from "../useApi";

export const useGetPlantMaterials = () => {
  const api = useApi<PlantMaterial[]>({ method: "GET" });

  return useCallback(
    ({ plantId, type }: { plantId: string; type?: "raw_materials" | "packaging" }) => {
      const searchParams = new URLSearchParams();

      if (type) {
        searchParams.set("type", type);
      }

      return api({
        url: `/v1/plants/${plantId}/inputs?${searchParams.toString()}`,
      });
    },
    [api],
  );
};

export const useGetMaterial = () => {
  const api = useApi<SupplierMaterial>({ method: "GET" });

  return useCallback(
    ({ plantId, materialId }: { plantId: string; materialId: string }) =>
      api({
        url: `/v1/plants/${plantId}/supplier-products/${materialId}`,
      }),
    [api],
  );
};

export const usePostMaterials = () => {
  const api = useApi<SupplierMaterial>({ method: "POST" });

  return useCallback(
    ({
      plantId,
      material,
    }: {
      plantId: string;
      material: Omit<SupplierMaterial, "id" | "epd_id" | "secondary_datasets" | "impacts"> & {
        epd_id: string | null;
      };
    }) =>
      api({
        url: `/v1/plants/${plantId}/supplier-products`,
        data: material,
      }),
    [api],
  );
};

export const usePutMaterial = () => {
  const api = useApi<SupplierMaterial>({ method: "PUT" });

  return useCallback(
    ({
      plantId,
      material,
    }: {
      plantId: string;
      material: Omit<SupplierMaterial, "epd_id" | "secondary_datasets" | "impacts"> & {
        epd_id: string | null;
      };
    }) =>
      api({
        url: `/v1/plants/${plantId}/supplier-products/${material.id}`,
        data: material,
      }),
    [api],
  );
};

export const useGetMaterialsBulkUploadSheet = () => {
  const api = useApi<Blob>({ method: "POST" });

  return useCallback(
    ({ plantId }: { plantId: string }) =>
      api({
        url: `/v1/bulk-upload-sheets/materials`,
        data: { plant_id: plantId },
        responseType: "blob",
      }),
    [api],
  );
};

export const usePostMaterialsBulkUpload = () => {
  const api = useApi<BulkUploadResult>({
    method: "POST",
    headers: { "Content-Type": "multipart/form-data" },
  });

  return useCallback(
    ({ plantId, file }: { plantId: string; file: File }) => {
      const formData = new FormData();
      formData.append("file", file);

      return api({
        url: `/v1/plants/${plantId}/supplier-products/bulk`,
        data: formData,
      });
    },
    [api],
  );
};

export const useDeleteMaterial = () => {
  const api = useApi<SupplierMaterial>({ method: "DELETE" });

  return useCallback(
    ({ plantId, materialId }: { plantId: string; materialId: string }) =>
      api({
        url: `/v1/plants/${plantId}/supplier-products/${materialId}`,
      }),
    [api],
  );
};
