import { useCallback, useEffect, useState } from "react";
import { apiLink, useApi } from "../useApi";

export const useUploadImage = () => {
  const api = useApi<string>({
    method: "POST",
    headers: { "Content-Type": "multipart/form-data" },
  });

  return useCallback(
    ({ file }: { file: File }) => {
      const formData = new FormData();
      formData.append("file", file);

      return api({ url: `/v1/media/image`, data: formData });
    },
    [api],
  );
};

const useImageAccessLink = () => {
  const api = useApi<string>({ method: "POST" });

  return useCallback(
    async ({ path }: { path: string }) => {
      const token = await api({
        url: `/v1/media/image/access-token?path=${encodeURIComponent(path)}`,
      });

      return apiLink(`/v1/media/image?path=${encodeURIComponent(path)}&token=${token}`);
    },
    [api],
  );
};

export const useAuthenticatedImgSrc = ({ imageUrl }: { imageUrl?: string }) => {
  const getImageAccessLink = useImageAccessLink();

  const [imageSrc, setImageSrc] = useState<string | undefined>(undefined);
  useEffect(() => {
    const getImageSrc = async () => {
      if (!imageUrl) return;
      try {
        const img = await getImageAccessLink({
          path: imageUrl,
        });
        setImageSrc(img);
      } catch (error) {
        console.error("Error generating image url:", error);
      }
    };

    getImageSrc();
  }, [imageUrl, getImageAccessLink]);

  return imageSrc;
};
