import { useCallback, useEffect } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useTranslation } from "react-i18next";

export const useLanguageSwitch = () => {
  const { i18n } = useTranslation();
  const toggleLanguage = useCallback(() => {
    i18n.changeLanguage(i18n.language === "de" ? "en" : "de");
  }, [i18n]);

  useEffect(() => {
    document.documentElement.lang = i18n.language;
    // Also check the i18next.ts for the language change event
  }, [i18n.language]);

  useHotkeys("alt+shift+l", toggleLanguage, [toggleLanguage]);
};
