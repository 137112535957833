import { UploadOutlined } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { Dispatch, SetStateAction, useMemo } from "react";
import { FileTrigger } from "react-aria-components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { BulkUploadMessage, BulkUploadResult } from "../../api/types";
import { Badge } from "../../components/Badge";
import { Button } from "../../components/Button";
import { Dropzone } from "../../components/Dropzone";
import { Text16 } from "../../components/TypographyOld";
import { useMaterialBulkUpload } from "../../state/materials";
import { useProductsBulkUpload } from "../../state/products";
import { showSuccessToast } from "../../util/toasts";
import { useBulkUploadDomain } from "./useBulkUploadDomain";

const useSummaryText = () => {
  const { t } = useTranslation();
  return (input: BulkUploadResult) => {
    let text =
      input.imported > 0
        ? `${t("Items imported successfully")}: ${input.imported}.`
        : `${t("No items imported")}.`;
    if (input.skipped > 0) {
      text += ` ${t("Skipped duplicates")}: ${input.skipped}`;
    }
    return text;
  };
};

export const UploadFile = ({
  setMessages,
}: {
  setMessages: Dispatch<SetStateAction<BulkUploadMessage[] | null | undefined>>;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { navigateBackLink, type } = useBulkUploadDomain();
  const summaryText = useSummaryText();

  const handleCompleteBulkUpload = (result: BulkUploadResult) => {
    if (result.failed > 0) {
      setMessages(result.messages);
    } else {
      setMessages(null);
      showSuccessToast(summaryText(result));
      navigate(navigateBackLink || "");
    }
  };

  const materialsUpload = useMaterialBulkUpload({
    onSuccess: handleCompleteBulkUpload,
  });

  const productsUpload = useProductsBulkUpload({
    onSuccess: handleCompleteBulkUpload,
  });

  const { mutateAsync: upload, isPending: uploadLoading } = useMemo(() => {
    if (type === "materials") {
      return materialsUpload;
    } else if (type === "products") {
      return productsUpload;
    } else {
      console.warn("Unexpected bulk upload type. Fallback to materials upload");
      return materialsUpload;
    }
  }, [materialsUpload, productsUpload, type]);

  return (
    <Dropzone onDrop={upload}>
      <div className="h-full w-full flex flex-col items-center justify-between gap-12">
        <Text16 className="text-neutral-500">{t("Drag & drop your Excel file here")}</Text16>
        <div className="w-full flex flex-col items-center gap-10">
          <div className="relative w-full flex items-center justify-center [&>div]:z-10 [&>div]:border-8 [&>div]:border-white">
            <hr className="absolute w-full border-t border-concrete" />
            <Badge color="gray">{t("Or")}</Badge>
          </div>
          <BrowseFileButton upload={upload} isLoading={uploadLoading} />
        </div>
      </div>
    </Dropzone>
  );
};

const BrowseFileButton = ({
  upload,
  isLoading,
}: {
  upload: (file: File) => void;
  isLoading: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <FileTrigger
      onSelect={(files) => {
        if (!files?.[0]) return;
        upload(files[0]);
      }}
    >
      <Button isDisabled={isLoading}>
        {isLoading ? <CircularProgress size="24px" /> : <UploadOutlined />}
        {t("Browse File")}
      </Button>
    </FileTrigger>
  );
};
