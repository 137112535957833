import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { DynamicFormJSONSchema, Plant } from "../types";
import { useApi } from "../useApi";

export const useGetPlants = () => {
  const api = useApi<Plant[]>({
    method: "GET",
  });

  return useCallback(() => {
    return api({ url: `/v1/plants` });
  }, [api]);
};

export const useGetWasteTreatmentSchema = () => {
  const api = useApi<DynamicFormJSONSchema>({
    method: "GET",
  });

  return useCallback(
    ({ plantId }: { plantId: string }) => {
      return api({
        url: `/v1/plants/${plantId}/waste-treatment/schema`,
      });
    },
    [api],
  );
};

export const usePostPlant = () => {
  const queryClient = useQueryClient();
  const api = useApi<Plant>({
    method: "POST",
  });

  const mutationFn = useCallback(
    ({ plant }: { plant: Omit<Plant, "id" | "input_output_schema" | "waste_treatment_schema"> }) =>
      api({ url: `/v1/plants`, data: plant }),
    [api],
  );

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["plants"],
        exact: true,
      });
    },
  });
};

export const usePatchPlant = () => {
  const queryClient = useQueryClient();
  const api = useApi<Plant>({
    method: "PUT",
  });

  const mutationFn = useCallback(
    ({ plantId, plant }: { plantId: string; plant: Plant }) =>
      api({ url: `/v1/plants/${plantId}`, data: plant }),
    [api],
  );

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["plants"],
        exact: true,
      });
    },
  });
};

export const useDeletePlant = () => {
  const queryClient = useQueryClient();
  const api = useApi<Plant>({
    method: "DELETE",
  });

  const mutationFn = useCallback(
    ({ plantId }: { plantId: string }) => api({ url: `/v1/plants/${plantId}` }),
    [api],
  );

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["plants"],
        exact: true,
      });
    },
  });
};
