import { useEffect, useMemo, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { ProductWithStatus } from "../../api/types";
import { Alert } from "../../components/Alert";
import { Button } from "../../components/Button";
import { Radio } from "../../components/RadioGroupField";
import { SidePanel } from "../../components/SidePanel";
import { ComboBoxFieldConnected } from "../../form-components/ComboBoxFieldConnected";
import { RadioGroupFieldConnected } from "../../form-components/RadioGroupFieldConnected";
import { useAreProductsComparable } from "../../lib/product";
import { useActivePlant, usePlants } from "../../state/plants";
import { useProducts } from "../../state/products";
import { SearchControls } from "../products/SearchControls";

type Fields = {
  plant: string | null;
  product: string | null;
};

export const CompareSidePanel = ({
  product,
  open,
  onClose,
}: {
  product: ProductWithStatus;
  open: boolean;
  onClose: () => void;
}) => {
  const { data: plants } = usePlants();
  const activePlant = useActivePlant();
  const areProductsComparable = useAreProductsComparable();

  const { t } = useTranslation();

  const methods = useForm<Fields>({
    defaultValues: {
      plant: activePlant.id,
      product: null,
    },
  });

  const plantOptions = useMemo(
    () => plants?.map((plant) => ({ label: plant.name, value: plant.id, id: plant.id })),
    [plants],
  );

  const selectedPlant = methods.watch("plant");
  useEffect(() => {
    methods.setValue("product", null);
  }, [selectedPlant, methods]);

  const { data: products } = useProducts({
    plantId: selectedPlant || activePlant.id,
  });

  const productOptions = useMemo(
    () =>
      products
        ?.filter((p) => areProductsComparable(p, product))
        .map((p) => ({ label: p.name, value: p.id, id: p.id })) || [],
    [products, product, areProductsComparable],
  );

  const navigate = useNavigate();
  const onSubmit: SubmitHandler<Fields> = (data) => {
    navigate(`/products/${product.id}/compare/${data.product}`);
  };

  const [debouncedSearch, setDebouncedSearch] = useState("");
  const filteredProductOptions = useMemo(() => {
    return productOptions.filter((p) =>
      p.label.toLowerCase().includes(debouncedSearch.toLowerCase()),
    );
  }, [productOptions, debouncedSearch]);

  const isDisabled = filteredProductOptions.length === 0;

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        <SidePanel
          open={open}
          onClose={onClose}
          title="Compare"
          footer={
            <Button type="submit" isDisabled={isDisabled}>
              {t("Compare products")}
            </Button>
          }
        >
          <div className="space-y-8">
            <ComboBoxFieldConnected name="plant" label={t("Plant")} options={plantOptions} />
            {productOptions.length > 10 && (
              <SearchControls
                placeholder={t("Search a specific product")}
                search={debouncedSearch}
                setSearch={setDebouncedSearch}
              />
            )}
            <div className="flex flex-col gap-2">
              {filteredProductOptions.length > 0 && (
                <RadioGroupFieldConnected
                  name="product"
                  isRequired
                  label={t("Select a product within the same category and unit")}
                  aria-label={t("Select a product")}
                >
                  {filteredProductOptions.map((option) => (
                    <Radio key={option.id} value={option.value}>
                      {option.label}
                    </Radio>
                  ))}
                </RadioGroupFieldConnected>
              )}
              {isDisabled && (
                <div className="flex justify-center">
                  <Alert>
                    {t(
                      "No products within the same category and declaration unit in the {{plant}} plant.",
                      {
                        plant: selectedPlant
                          ? plantOptions?.find((p) => p.id === selectedPlant)?.label
                          : activePlant.name,
                      },
                    )}
                  </Alert>
                </div>
              )}
            </div>
          </div>
        </SidePanel>
      </form>
    </FormProvider>
  );
};
