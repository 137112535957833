import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { Manufacturer } from "../types";
import { useApi } from "../useApi";

export const useGetMe = () => {
  return useApi<Manufacturer>({ url: `/v1/me/manufacturers` });
};

export const usePostManufacturer = ({ onCreateSuccess }: { onCreateSuccess?: () => void }) => {
  const api = useApi<Manufacturer>({
    url: `/v1/me/manufacturers`,
    method: "POST",
  });

  const mutationFn = useCallback(
    (data: Omit<Manufacturer, "id" | "owner" | "input_output_schema" | "image_url">) =>
      api({ data }),
    [api],
  );

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getMe"], exact: true });
      onCreateSuccess?.();
    },
  });
};

export const usePatchManufacturer = () => {
  const api = useApi<Manufacturer>({
    method: "PUT",
  });

  const mutationFn = useCallback(
    ({ manufacturer }: { manufacturer: Omit<Manufacturer, "owner"> }) =>
      api({ url: `/v1/me/manufacturers`, data: manufacturer }),
    [api],
  );

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getMe"], exact: true });
    },
  });
};
