import { Add, FactoryOutlined } from "@mui/icons-material";
import { Suspense } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { DynamicFormJSONSchema, ProductionProcess } from "../../api/types";
import { Button } from "../../components/Button";
import { Card, CardDivider } from "../../components/Card";
import { Link } from "../../components/Link";
import { TopBar } from "../../components/TopBar";
import { useActivePlant } from "../../state/plants";
import { ELECTRICITY_MIX_DEFAULT_VALUES, ElectricityMix } from "./ElectricityMix";
import { ProductionProcessList } from "./ProductionProcessList";
import { WasteTreatment } from "./WasteTreatment";

export const ProductionOverviewPage = ({
  processes,
  wasteTreatmentSchema,
}: {
  processes: ProductionProcess[];
  wasteTreatmentSchema: DynamicFormJSONSchema;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const activePlant = useActivePlant();

  return (
    <>
      <TopBar
        icon={<FactoryOutlined />}
        title={t("Production")}
        subtitle={activePlant.name}
        input={
          <Button intent="primary" onPress={() => navigate("/production/processes/new")}>
            {t("Add production process")}
            <Add />
          </Button>
        }
      />
      <Suspense>
        <div className="flex flex-col gap-8 py-8">
          <Card
            title={t("General")}
            input={
              <Link href="/production?section=general" intent="secondary" size="small">
                {t("Details")}
              </Link>
            }
          >
            <div className="space-y-6">
              <FormProvider
                {...useForm({
                  defaultValues: activePlant.electricity_mix || ELECTRICITY_MIX_DEFAULT_VALUES,
                })}
              >
                <ElectricityMix averageMix={!activePlant.electricity_mix} />
              </FormProvider>
              <CardDivider />
              <FormProvider {...useForm({ defaultValues: activePlant.waste_treatment })}>
                <WasteTreatment schema={wasteTreatmentSchema} editing={false} />
              </FormProvider>
            </div>
          </Card>
          <Suspense>
            <ProductionProcessList processes={processes} />
          </Suspense>
        </div>
      </Suspense>
    </>
  );
};
