import { ArrowBackOutlined, StarBorder } from "@mui/icons-material";
import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import tw from "tailwind-styled-components";
import { Alert } from "../../components/Alert";
import { Button } from "../../components/Button";
import { PageContainer } from "../../components/PageContainer";
import { TopBar } from "../../components/TopBar";
import { Heading3, Label } from "../../components/TypographyOld";
import { useAdminMode } from "../../state/admin";
import { GenerateLCI } from "./GenerateLCI";
import { PcrOverview } from "./PcrOverview";
import { ProductDefaultImages } from "./ProductDefaultImages";
import { VerifyDeclaration } from "./VerifyDeclaration";

type Feature = "product-default-images" | "generate-lci" | "verify-declaration" | "pcr-overview";

export const AdminHome = () => {
  const { t } = useTranslation();
  const adminMode = useAdminMode();
  const navigate = useNavigate();
  const [feature, setFeature] = useState<Feature | null>(null);

  if (!adminMode) {
    navigate("/");
  }

  return (
    <PageContainer>
      <TopBar
        title={t("Internal")}
        icon={
          feature ? (
            <Button onPress={() => setFeature(null)} intent="secondary" square>
              <ArrowBackOutlined />
            </Button>
          ) : (
            <StarBorder />
          )
        }
        centerSection={<Alert>{t("Emidat-internal use.")}</Alert>}
      />
      <div className="flex flex-col gap-6 py-4">
        <div className="grid grid-cols-2 items-center">
          <Heading3>{t("Emidat internal features")}</Heading3>
        </div>
        {feature ? <AdminFeature feature={feature} /> : <Options setFeature={setFeature} />}
      </div>
    </PageContainer>
  );
};

const AdminFeature = ({ feature }: { feature: Feature }) => {
  switch (feature) {
    case "product-default-images":
      return <ProductDefaultImages />;
    case "generate-lci":
      return <GenerateLCI />;
    case "verify-declaration":
      return <VerifyDeclaration />;
    case "pcr-overview":
      return <PcrOverview />;
  }
};

const Options = ({ setFeature }: { setFeature: (feature: Feature | null) => void }) => {
  const { t } = useTranslation();
  return (
    <div className="grid grid-cols-2 gap-4">
      <ClickableCard
        title={t("Product default images")}
        onClick={() => setFeature("product-default-images")}
      >
        <div>{t("Overview over all product default images, assigned to Elementaries.")}</div>
      </ClickableCard>
      <ClickableCard title={t("Generate LCI")} onClick={() => setFeature("generate-lci")}>
        <div>{t("Generate LCI for an Elementary.")}</div>
      </ClickableCard>
      <ClickableCard
        title={t("Verify declaration")}
        onClick={() => setFeature("verify-declaration")}
      >
        <div>
          {t("Approving an EPD draft. Generates the final EPD document and sends it to EPD-Norge.")}
        </div>
      </ClickableCard>
      <ClickableCard title={t("PCR Overview")} onClick={() => setFeature("pcr-overview")}>
        <div>{t("Check the PCR for each PO and product categroy")}</div>
      </ClickableCard>
    </div>
  );
};

const ClickableCardHeader = tw("div")`
  bg-neutral-100
  flex
  items-center
  justify-between
  px-6
  h-16
`;

const ClickableCardContainer = tw("div")`
  bg-white
  border
  border-neutral-300
  hover:border-energyyellow
  shadow-e-xs
  hover:shadow-e-sm
  active:shadow-e-xs
  translate-y-0
  hover:translate-y-[-1px]
  active:translate-y-0
  transition-all
  cursor-pointer
`;

const ClickableCard = ({
  title,
  children,
  onClick,
}: {
  title: string;
  children: ReactNode;
  onClick: () => void;
}) => (
  <ClickableCardContainer onClick={onClick}>
    <ClickableCardHeader>
      <Label>{title}</Label>
    </ClickableCardHeader>
    <div className="px-6 py-5">{children}</div>
  </ClickableCardContainer>
);
