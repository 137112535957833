import { useTranslation } from "react-i18next";
import { Plant, ProductCategory } from "../../api/types";
import { Heading4 } from "../../components/TypographyOld";
import { ComboBoxFieldConnected } from "../../form-components/ComboBoxFieldConnected";
import { TextFieldConnected } from "../../form-components/TextFieldConnected";
import { useCountryOptions } from "../../util/useCountryOptions";
import { CustomProductCategoryPicker } from "./CustomProductCategoryPicker";

export interface ManagePlantFieldsT {
  name: string;
  city: string;
  country: string;
  product_categories: ProductCategory["id"][];
}

export const ManagePlantFields = ({ activePlant }: { activePlant?: Plant }) => {
  const { t } = useTranslation();
  const countryOptions = useCountryOptions();
  return (
    <div className="flex flex-col justify-center max-w-sm mx-auto pt-8 pb-20">
      <div className="text-center mb-12">
        <Heading4>{t("Plant Setup")}</Heading4>
      </div>
      <div className="grid gap-4 mb-20">
        <TextFieldConnected
          name="name"
          label={t("Plant Name")}
          isRequired
          placeholder={t("Add name")}
        />
        <TextFieldConnected name="city" label={t("City")} isRequired placeholder={t("Add city")} />
        <ComboBoxFieldConnected
          name="country"
          isRequired
          options={countryOptions}
          label={t("Country")}
          placeholder={t("Add country")}
        />
      </div>
      <CustomProductCategoryPicker activePlant={activePlant} />
    </div>
  );
};
