import { BusinessOutlined } from "@mui/icons-material";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router";
import { PageContainer } from "../components/PageContainer";
import { TopBar } from "../components/TopBar";
import { LanguageSwitch } from "../localization/LanguageSwitch";
import { ManageCompanyLogo } from "../page-components/company-settings/ManageCompanyLogo";
import { ManageManufacturers } from "../page-components/company-settings/ManageManufacturers";
import { useActiveManufacturer } from "../state/manufacturers";
import { ManagePlants } from "./ManagePlants";
import { ManageUsers } from "./ManageUsers";

const CompanyTab = () => {
  const { t } = useTranslation();
  const { activeManufacturer } = useActiveManufacturer();

  return (
    <>
      <TopBar
        title={t("Settings")}
        subtitle={t("Company details")}
        icon={<BusinessOutlined />}
        input={<LanguageSwitch />}
      />
      <Suspense>
        <div className="flex flex-col gap-12 py-8">
          <ManageManufacturers activeManufacturer={activeManufacturer} />
          <ManageCompanyLogo />
        </div>
      </Suspense>
    </>
  );
};

export const CompanySettings = () => {
  const [searchParams] = useSearchParams();
  const activeTab = searchParams.get("tab") || "company";

  return (
    <PageContainer>
      {activeTab === "company" && <CompanyTab />}
      {activeTab === "plants" && <ManagePlants />}
      {activeTab === "users" && <ManageUsers />}
    </PageContainer>
  );
};
