import { useMutation } from "@tanstack/react-query";
import { useCallback } from "react";
import {
  BulkPcfDocuments,
  BulkUploadResult,
  Document,
  EndOfScale,
  ProductDetail,
  ProductWithStatus,
} from "../types";
import { apiLink, useApi } from "../useApi";

export const useGetProducts = () => {
  const api = useApi<ProductWithStatus[]>();

  return useCallback(
    ({ plantId }: { plantId: string }) => api({ url: `/v1/plants/${plantId}/products` }),
    [api],
  );
};
export const useGetProduct = () => {
  const api = useApi<ProductDetail>();

  return useCallback(
    ({ id, plantId }: { id: string; plantId: string }) =>
      api({ url: `/v1/plants/${plantId}/products/${id}` }),
    [api],
  );
};

export const useGetProductImpactScale = () => {
  const api = useApi<EndOfScale[]>();

  return useCallback(
    ({ id, plantId }: { id: string; plantId: string }) =>
      api({ url: `/v1/plants/${plantId}/products/${id}/impact-scale` }),
    [api],
  );
};

export const usePostProduct = () => {
  const api = useApi<ProductDetail>({ method: "POST" });

  return useCallback(
    ({
      plantId,
      product,
    }: {
      plantId: string;
      product: Omit<
        ProductWithStatus,
        | "id"
        | "plant_id"
        | "manufacturer_id"
        | "production_process_id"
        | "tech_specs_schema"
        | "mass"
        | "recipe"
        | "declarations"
        | "status"
        | "plant"
        | "manufacturer"
        | "image_url"
        | "declaration"
        | "documents"
        | "gwp_total"
        | "lcia_results_timestamp"
        | "created_at"
        | "material"
        | "metadata"
        | "active_jobs"
      >;
    }) => api({ url: `/v1/plants/${plantId}/products`, data: product }),
    [api],
  );
};

export const usePatchProduct = () => {
  // TODO: MOVE TO ACTUAL PATCHING / PARTIAL UPDATING
  const api = useApi<ProductDetail>({ method: "PUT" });

  return useCallback(
    ({
      plantId,
      productId,
      product,
    }: {
      plantId: string;
      productId: string;
      product: Partial<Omit<ProductWithStatus, "plant" | "manufacturer" | "active_jobs">>;
    }) =>
      api({
        url: `/v1/plants/${plantId}/products/${productId}`,
        data: product,
      }),
    [api],
  );
};

export const useDeleteProduct = () => {
  const api = useApi({ method: "DELETE" });

  return useCallback(
    ({ plantId, productId }: { plantId: string; productId: string }) =>
      api({ url: `/v1/plants/${plantId}/products/${productId}` }),
    [api],
  );
};

export const usePostUpdateProductLca = () => {
  const api = useApi<ProductDetail>({ method: "POST" });

  return useCallback(
    ({ plantId, productId }: { plantId: string; productId: string }) =>
      api({
        url: `/v1/plants/${plantId}/products/${productId}/lca-update`,
      }),
    [api],
  );
};

export const useGetProductExcelExport = () => {
  const api = useApi<Blob>({ method: "GET" });

  return useCallback(
    () =>
      api({
        url: `/v1/products/export`,
        responseType: "blob",
      }),
    [api],
  );
};

export const useGetProductsBulkUploadSheet = () => {
  const api = useApi<Blob>({ method: "POST" });

  return useCallback(
    ({ plantId, elementaryId }: { plantId: string; elementaryId: string }) =>
      api({
        url: `/v1/bulk-upload-sheets/products`,
        data: { plant_id: plantId, elementary_id: elementaryId },
        responseType: "blob",
      }),
    [api],
  );
};

export const usePostProductsBulkUpload = () => {
  const api = useApi<BulkUploadResult>({
    method: "POST",
    headers: { "Content-Type": "multipart/form-data" },
  });

  return useCallback(
    ({ file }: { file: File }) => {
      const formData = new FormData();
      formData.append("file", file);

      return api({
        url: `/v1/products/bulk`,
        data: formData,
      });
    },
    [api],
  );
};

const useDocumentAccessToken = () => {
  const api = useApi<string>({ method: "POST" });

  return useCallback(
    async ({ documentId }: { documentId: string }) =>
      await api({
        url: `/v1/documents/${documentId}/access-token`,
      }),
    [api],
  );
};

export const useDownloadDocument = () => {
  const tokenResolver = useDocumentAccessToken();

  return useMutation({
    mutationFn: async ({
      documentId,
      documentName,
      openInNewTab,
    }: {
      documentId: string;
      documentName: string;
      openInNewTab: boolean;
    }) => {
      const token = await tokenResolver({
        documentId,
      });

      const disposition = openInNewTab ? "inline" : "attachment";

      const url = apiLink(`/v1/documents/${documentId}?token=${token}&disposition=${disposition}`);

      if (openInNewTab) {
        const newTab = window.open();
        if (newTab) {
          newTab.location.href = url;
        } else {
          console.error("Failed to open new tab");
        }
      } else {
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", documentName);

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
      }
    },
  });
};

export const usePostDocumentBulkPCF = () => {
  const api = useApi<BulkPcfDocuments>({ method: "POST" });

  return useCallback(
    ({ plantId, productIds }: { plantId: string; productIds: string[] }) => {
      return api({
        url: `/v1/plants/${plantId}/products/bulk-pcf`,
        data: { product_ids: productIds },
      });
    },
    [api],
  );
};

export const usePostDocumentComparisonPCF = () => {
  const api = useApi<Document>({ method: "POST" });

  return useCallback(
    ({ plantId, productIds }: { plantId: string; productIds: string[] }) => {
      return api({
        url: `/v1/plants/${plantId}/products/comparison-pcf`,
        data: { product_ids: productIds },
      });
    },
    [api],
  );
};
