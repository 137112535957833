import { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Elementary, SupplierMaterial } from "../../api/types";
import { ComboBoxField } from "../../components/ComboBoxField";
import { ComboBoxFieldConnected } from "../../form-components/ComboBoxFieldConnected";
import { TextFieldConnected } from "../../form-components/TextFieldConnected";
import { useElementaries } from "../../state/elementaries";
import { useMaterials } from "../../state/materials";
import { useActivePlant } from "../../state/plants";
import { useProductMetadata } from "../../state/productMetadata";
import { FormFields } from "./types";

export const MaterialBasics = ({
  isEditing,
  existingMaterial,
  type,
}: {
  existingMaterial?: SupplierMaterial | null;
  type?: Elementary["type"];
  isEditing?: boolean;
}) => {
  const { t } = useTranslation();
  const methods = useFormContext<FormFields>();

  const { rawMaterials, packagingMaterials } = useMaterials();

  const { elementaries, elementariesMap } = useElementaries();

  const existingProductNames = useMemo(() => {
    const allMaterials = [...rawMaterials, ...packagingMaterials];
    return new Set(
      allMaterials
        .map((m) => m.supplier_product?.name || m.silo?.name || m.prechain_product?.product.name)
        .filter(Boolean),
    );
  }, [rawMaterials, packagingMaterials]);

  const {
    data: { productMetadataMap },
  } = useProductMetadata();

  const activePlant = useActivePlant();

  const [category, setCategory] = useState<string | null>(null);

  const typeFilter =
    type ?? (existingMaterial ? elementariesMap[existingMaterial.elementary_id].type : null);

  const canProduceElementaries = useMemo(() => {
    return elementaries.filter(
      (e) =>
        e.product_category_id && activePlant.product_categories.includes(e.product_category_id),
    );
  }, [activePlant, elementaries]);

  const allowedIngredients: Set<Elementary["id"]> = useMemo(() => {
    return new Set(
      canProduceElementaries.flatMap((e) =>
        e.product_metadata_id ? productMetadataMap?.[e.product_metadata_id].ingredients : [],
      ),
    );
  }, [canProduceElementaries, productMetadataMap]);

  const availableElementaries = useMemo(() => {
    return elementaries.filter((e) => {
      return (
        e.active &&
        (!typeFilter || e.type === typeFilter) &&
        !e.prechain_only &&
        (typeFilter === "packaging" || allowedIngredients.has(e.id))
      );
    });
  }, [elementaries, typeFilter, allowedIngredients]);

  const categoryOptions = useMemo(() => {
    return [...new Set(availableElementaries.map((el) => el.category))]
      .map((category) => ({ id: category, label: t(category) }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [availableElementaries, t]);

  useEffect(() => {
    if (elementariesMap && !category) {
      const defaultValue = elementariesMap[methods.getValues().elementaryId]?.category;
      setCategory(defaultValue ?? categoryOptions[0]?.id ?? null);
    }
  }, [methods, elementariesMap, setCategory, categoryOptions, category]);

  const materialOptions = useMemo(() => {
    return availableElementaries
      .filter((elementary) => elementary.category === category)
      .map((elementary) => ({
        id: elementary.id,
        label: t(elementary.name),
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [availableElementaries, category, t]);

  return (
    <div className="grid grid-cols-2 gap-4">
      <TextFieldConnected
        name="productName"
        isRequired={isEditing}
        isViewOnly={!isEditing}
        placeholder={t("Product name")}
        label={t("Product name")}
        rules={{
          validate: (value) => {
            if (!value || existingMaterial?.name === value) return true;
            return !existingProductNames.has(value) || t("This product name already exists");
          },
        }}
      />
      <div />
      <ComboBoxField
        value={categoryOptions.find((opt) => opt.id === category) || null}
        onChange={(_, opt) => {
          if (opt) setCategory(opt.id as string);
        }}
        options={categoryOptions}
        label={t("Category")}
        isDisabled={!!existingMaterial || categoryOptions.length === 1}
        isViewOnly={!isEditing}
      />
      <ComboBoxFieldConnected
        name="elementaryId"
        isRequired={isEditing}
        isViewOnly={!isEditing}
        options={materialOptions}
        isDisabled={!!existingMaterial || !category}
        label={t("Material")}
      />
    </div>
  );
};
