import { DownloadOutlined, VisibilityOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useDownloadDocument } from "../api/endpoints/products";
import { Document } from "../api/types";
import { Button } from "../components/Button";
import { Tooltip, TooltipTrigger } from "../components/Tooltip";

export const ViewOrDownloadDocument = ({ doc }: { doc: Document }) => {
  const { t } = useTranslation();

  const { mutate: downloadDocument, isPending } = useDownloadDocument();

  return (
    <div className="flex items-center">
      {doc.name.endsWith(".pdf") && (
        <>
          <TooltipTrigger>
            <Button
              intent="tertiary"
              isDisabled={isPending}
              onPress={() =>
                downloadDocument({
                  documentId: doc.id,
                  documentName: doc.name,
                  openInNewTab: true,
                })
              }
            >
              <VisibilityOutlined />
            </Button>
            <Tooltip>{t("Preview in a new tab")}</Tooltip>
          </TooltipTrigger>
          <div className="w-[1px] h-6 bg-neutral-300" />
        </>
      )}
      <TooltipTrigger>
        <Button
          intent="tertiary"
          isDisabled={isPending}
          onPress={() =>
            downloadDocument({
              documentId: doc.id,
              documentName: doc.name,
              openInNewTab: false,
            })
          }
        >
          <DownloadOutlined />
        </Button>
        <Tooltip>{t("Download file")}</Tooltip>
      </TooltipTrigger>
    </div>
  );
};
