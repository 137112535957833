import { ToastContainer } from "react-toastify";

import { Outlet } from "react-router";

import { ErrorBoundary } from "@sentry/react";
import { QueryErrorResetBoundary } from "@tanstack/react-query";
import { Suspense } from "react";
import { usePlausibleTracking } from "../analytics/usePlausibleTracking";
import { Footer } from "../components/Footer";
import { Loading } from "../components/Loading";
import { Navbar } from "../components/Navbar";
import { SplashSuccess } from "../components/SplashSuccess";
import { Banner } from "../page-components/Banner";
import { VersionInfo } from "../page-components/VersionInfo";
import { ActivePlantProvider } from "../state/plants";
import { ErrorPage } from "./special/ErrorPage";

const Main = () => {
  usePlausibleTracking();

  return (
    <div className="w-full grow grid grid-cols-[auto_1fr] overflow-hidden">
      <VersionInfo />
      <Navbar />
      <div className="px-6 overflow-y-auto bg-white flex flex-col">
        <Outlet />
        <Footer />
      </div>
    </div>
  );
};

export const Index = () => {
  return (
    <>
      <Banner />
      <SplashSuccess />
      <QueryErrorResetBoundary>
        {({ reset }) => (
          <ErrorBoundary fallback={ErrorPage} onReset={reset}>
            <Suspense fallback={<Loading lazy />}>
              <ActivePlantProvider>
                <Main />
              </ActivePlantProvider>
            </Suspense>
          </ErrorBoundary>
        )}
      </QueryErrorResetBoundary>
      <ToastContainer position="bottom-right" draggable pauseOnHover closeButton hideProgressBar />
    </>
  );
};
