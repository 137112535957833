import { AddOutlined, BoltOutlined, DeleteOutlined } from "@mui/icons-material";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { HazardousShare } from "../../api/types";
import { Alert } from "../../components/Alert";
import { Button } from "../../components/Button";
import { CheckboxField } from "../../components/CheckboxField";
import { Tooltip, TooltipTrigger } from "../../components/Tooltip";
import { ComboBoxFieldConnected } from "../../form-components/ComboBoxFieldConnected";
import { NumberFieldConnected } from "../../form-components/NumberFieldConnected";
import { HARDCODED_CAS_NUMBERS } from "../../state/HARD_CODED";
import { FormFields } from "./types";

const CAS_OPTIONS = HARDCODED_CAS_NUMBERS.map((casNumber) => ({
  label: casNumber,
  id: casNumber,
}));

export const HazardousSubstancesForm = ({ isEditing }: { isEditing?: boolean }) => {
  const { t } = useTranslation();
  const methods = useFormContext<{ hazardousSubstances: HazardousShare[] }>();

  const substances = methods.watch("hazardousSubstances");

  const [isHazardous, setIsHazardous] = useState(substances.length > 0);

  return (
    <div className="space-y-4">
      {isEditing && (
        <CheckboxField
          name="isHazardous"
          isSelected={isHazardous}
          onChange={() => {
            if (!isHazardous) {
              if (substances.length === 0) {
                const newCasNumber = CAS_OPTIONS.find(
                  (opt) => !substances.some((x) => x.cas_number === opt.id),
                );
                if (newCasNumber) {
                  methods.setValue("hazardousSubstances", [
                    {
                      cas_number: newCasNumber.id,
                      percentage: 0,
                    },
                  ]);
                }
              }
              setIsHazardous(true);
            } else {
              methods.setValue("hazardousSubstances", []);
              setIsHazardous(false);
            }
          }}
        >
          {t("Is this material hazardous?")}
        </CheckboxField>
      )}
      {isHazardous && (
        <>
          <div className="space-y-4">
            {!isEditing && <Alert icon={<BoltOutlined />}>{t("This material is hazardous")}</Alert>}
            {substances.map((_, index, array) => (
              <div key={index} className="flex flex-col items-start">
                <div
                  className={`grid ${array.length > 1 ? "grid-cols-[1fr_1fr_auto]" : "grid-cols-[1fr_1fr]"} items-start gap-4 w-full`}
                >
                  <ComboBoxFieldConnected<FormFields>
                    name={`hazardousSubstances.${index}.cas_number`}
                    isRequired={isEditing}
                    isViewOnly={!isEditing}
                    options={CAS_OPTIONS.filter(
                      (opt) =>
                        !array.some(
                          (item, itemIndex) => itemIndex !== index && item.cas_number === opt.id,
                        ),
                    )}
                    label={t("CAS Number")}
                  />
                  <NumberFieldConnected<FormFields>
                    name={`hazardousSubstances.${index}.percentage`}
                    rules={{
                      validate: (value) => {
                        if (value === 0) {
                          return t("Must be greater than 0");
                        }
                      },
                    }}
                    isRequired={isEditing}
                    isViewOnly={!isEditing}
                    minValue={0}
                    maxValue={100}
                    label={t("Percentage of mass")}
                    inputProps={{ addonRight: "%" }}
                  />
                  {array.length > 1 && isEditing && (
                    <div className="self-end">
                      <TooltipTrigger>
                        <Button
                          intent="secondary"
                          danger
                          onPress={() => {
                            const newArray = array.filter((_, itemIndex) => itemIndex !== index);
                            methods.setValue("hazardousSubstances", newArray);
                          }}
                        >
                          <DeleteOutlined fontSize="small" />
                        </Button>
                        <Tooltip>{t("Delete")}</Tooltip>
                      </TooltipTrigger>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
          {isEditing && (
            <div>
              <Button
                intent="tertiary"
                type="button"
                onPress={() => {
                  const newCasNumber = CAS_OPTIONS.find(
                    (opt) => !substances.some((x) => x.cas_number === opt.id),
                  );
                  if (newCasNumber) {
                    methods.setValue("hazardousSubstances", [
                      ...substances,
                      {
                        cas_number: newCasNumber.id,
                        percentage: 0,
                      },
                    ]);
                  }
                }}
              >
                <AddOutlined />
                {t("Add hazardous content")}
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};
