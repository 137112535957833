import { useTranslation } from "react-i18next";
import { Impact } from "../../api/types";
import { TextField } from "../../components/TextField";
import { formatPrecision } from "../../util/format";

export const GWPTotalBreakdown = ({ impacts }: { impacts: Impact[] }) => {
  const { t } = useTranslation();

  const gwpTotal = impacts.find((impact) => impact.indicator_name === "GWP-total");

  if (!gwpTotal) return null;

  return (
    <>
      <hr className="border-gray-200" />
      <div className="bg-neutral-100 p-4">
        <TextField
          label={t("Global Warming Potential (GWP-total) per 1kg")}
          value={`${formatPrecision(gwpTotal.value, 4)} kg CO2e`}
          isViewOnly
        />
      </div>
    </>
  );
};
