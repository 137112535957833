import { ArrowDropDownOutlined } from "@mui/icons-material";
import { cva } from "class-variance-authority";
import { forwardRef, ReactNode } from "react";
import {
  Button as ButtonPrimitive,
  DialogTrigger,
  Input as InputPrimitive,
  InputProps,
} from "react-aria-components";
import { useTranslation } from "react-i18next";
import { exists } from "../util/commonUtil";
import { Popover } from "./Popover";

const containerStyles = cva(
  [
    "flex items-center gap-4",
    "bg-neutral-100",
    "px-4",
    "h-[56px]",
    "relative",
    "w-full",
    "border-b",
    "border-transparent has-[input:focus]:border-steelblue",
    "text-builtgreen",
    "group",
  ],
  {
    variants: {
      hasError: {
        true: "border-red-500! has-[input:focus]:border-red-500!",
      },
      hasLabel: {
        true: "pt-5",
      },
      align: {
        left: "text-left",
        right: "text-right",
      },
      size: {
        medium: "text-base font-normal",
      },
      isDisabled: {
        true: "cursor-not-allowed text-neutral-400",
      },
    },
    defaultVariants: {
      align: "left",
      size: "medium",
    },
  },
);

const addonLeftStyles = cva(
  ["absolute", "left-0", "top-1/2", "-translate-y-1/2", "pointer-events-none"],
  {
    variants: {
      pale: {
        true: "text-neutral-400",
      },
    },
  },
);

const addonRightStyles = cva(
  [
    "pointer-events-none",
    "shrink-0",
    "opacity-0",
    "group-[:has(input:focus)]:opacity-100",
    "group-[:has(input:not(:placeholder-shown))]:opacity-100",
    "transition-opacity",
    "duration-200",
  ],
  {
    variants: {
      pale: {
        true: "text-neutral-400",
      },
    },
  },
);

const inputStyles = cva(
  ["w-full", "outline-hidden", "grow", "bg-transparent", "placeholder-neutral-400"],
  {
    variants: {
      hasAddonLeft: {
        true: "pl-8",
      },
    },
  },
);

export const InputWithSubfields = forwardRef<
  HTMLInputElement,
  Omit<InputProps, "className"> & {
    subfields: ReactNode;
    hasError?: boolean;
    addonLeft?: ReactNode;
    addonRight?: ReactNode;
    align?: "left" | "right";
    hasLabel?: boolean;
  }
>(({ align, addonLeft, addonRight, hasError, disabled, subfields, hasLabel, ...props }, ref) => {
  const hasValue = exists(props.value);
  const { t } = useTranslation();

  return (
    <div
      className={containerStyles({
        hasError,
        align,
        isDisabled: disabled,
        hasLabel,
      })}
    >
      {addonLeft && (
        <span className={addonLeftStyles({ pale: disabled || !hasValue })}>{addonLeft}</span>
      )}
      <InputPrimitive
        className={inputStyles({
          hasAddonLeft: !!addonLeft,
        })}
        ref={ref}
        disabled={disabled}
        {...props}
      />
      <DialogTrigger>
        <ButtonPrimitive slot={null} className="flex items-center outline-hidden text-sm font-bold">
          {t("more")} <ArrowDropDownOutlined />
        </ButtonPrimitive>
        <Popover>{subfields}</Popover>
      </DialogTrigger>
      {addonRight && (
        <span className={addonRightStyles({ pale: disabled || !hasValue })}>{addonRight}</span>
      )}
    </div>
  );
});
