import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useElementaries } from "../../state/elementaries";
import { useProductMetadata } from "../../state/productMetadata";
import { exists } from "../../util/commonUtil";

type AggregatedData = {
  [metadataId: string]: {
    productName: string[];
    poEntries: {
      po: string;
      pcr: string;
    }[];
  };
};

export const PcrOverview = () => {
  const { t } = useTranslation();
  const { elementaries } = useElementaries();
  const {
    data: { productMetadata },
  } = useProductMetadata();

  // Create a lookup map for product metadata by ID
  const metadataMap = Object.fromEntries(productMetadata.map((pm) => [pm.id, pm]));

  // Aggregate data by product_metadata_id
  const aggregatedData: AggregatedData = elementaries
    //.filter((e) => exists(e.product_metadata_id))
    .reduce((acc: AggregatedData, elementary) => {
      const metadataId = elementary.product_metadata_id;
      if (!exists(metadataId)) return acc;
      const meta = metadataMap[metadataId];
      if (!meta) return acc;

      // Initialize group if it does not exist
      if (!acc[metadataId]) {
        acc[metadataId] = {
          productName: [],
          poEntries: [],
        };
      }

      // Collect product names (avoid duplicates)
      if (!acc[metadataId].productName.includes(elementary.name)) {
        acc[metadataId].productName.push(elementary.name);
      }

      // Process PO availability from product metadata
      meta.po_availability?.forEach((poItem) => {
        const poEntry = {
          po: poItem.po?.short_name || "N/A",
          pcr: Array.isArray(poItem.pcr) ? poItem.pcr.join("\n") : "N/A",
        };

        // Avoid duplicate PO/PCR entries
        const exists = acc[metadataId].poEntries.some(
          (entry) => entry.po === poEntry.po && entry.pcr === poEntry.pcr,
        );
        if (!exists) {
          acc[metadataId].poEntries.push(poEntry);
        }
      });

      return acc;
    }, {});

  return (
    <TableContainer component={Paper} sx={{ maxWidth: "100%", marginTop: 2 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold", width: 300 }}>{t("Product Name")}</TableCell>
            <TableCell sx={{ fontWeight: "bold", width: 200 }}>{t("PO")}</TableCell>
            <TableCell sx={{ fontWeight: "bold", width: 400 }}>{t("PCR")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(aggregatedData).map(([metadataId, { productName, poEntries }]) =>
            poEntries.map((poEntry, index) => (
              <TableRow key={`${metadataId}-${index}`}>
                {index === 0 && (
                  <TableCell rowSpan={poEntries.length} sx={{ fontWeight: "bold" }}>
                    {productName.join(", ")}
                  </TableCell>
                )}
                <TableCell>{poEntry.po}</TableCell>
                <TableCell sx={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
                  {poEntry.pcr}
                </TableCell>
              </TableRow>
            )),
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
