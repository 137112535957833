import { AddOutlined, GroupOutlined } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { Suspense, useMemo, useState } from "react";
import { DialogTrigger } from "react-aria-components";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Profile } from "../api/types";
import { Button } from "../components/Button";
import { DataGrid } from "../components/DataGrid";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "../components/Modal";
import { TopBar } from "../components/TopBar";
import { Heading3, Label, Text16 } from "../components/TypographyOld";
import { TextFieldConnected } from "../form-components/TextFieldConnected";
import {
  useInviteUser,
  useMyProfile,
  useProfiles,
  useRemoveUser,
  useTransferOwnership,
} from "../state/profiles";
import { showErrorToast, showSuccessToast } from "../util/toasts";
import { ManageProfile } from "./ManageProfile";

export const ManageUsers = () => {
  return (
    <Suspense>
      <ManageUsersInner />
    </Suspense>
  );
};

const ManageUsersInner = () => {
  const { t } = useTranslation();

  const [isInvitationModelOpen, setIsInvitationModelOpen] = useState(false);

  const { data: profiles } = useProfiles();
  const { data: myProfile } = useMyProfile();

  const count = (profiles?.length ?? 0) + (myProfile ? 1 : 0);

  return (
    <>
      <TopBar
        title={t("Settings")}
        subtitle={t("{{count}} users", { count })}
        icon={<GroupOutlined />}
        input={
          <Suspense>
            <InviteButton onPress={() => setIsInvitationModelOpen(true)} />
          </Suspense>
        }
      />
      <Suspense>
        <div className="flex flex-col gap-12 py-8">
          <div className="grow flex flex-col gap-8">
            <div className="flex items-end justify-between gap-3">
              <div>
                <Label>{t("User management")}</Label>
              </div>
            </div>
            <Suspense>
              <UserProfiles profiles={profiles} myProfile={myProfile} />
            </Suspense>
          </div>
          {isInvitationModelOpen && (
            <InviteUserModal
              onConfirm={() => {
                setIsInvitationModelOpen(false);
              }}
              onCancel={() => setIsInvitationModelOpen(false)}
            />
          )}
        </div>
        <ManageProfile />
      </Suspense>
    </>
  );
};

const InviteButton = ({ onPress }: { onPress?: () => void }) => {
  const { data: myProfile } = useMyProfile();
  const { t } = useTranslation();

  if (!myProfile.is_owner) {
    return null;
  }

  return (
    <div className="flex gap-6">
      <Button intent="secondary" onPress={onPress}>
        <AddOutlined />
        {t("Invite user")}
      </Button>
    </div>
  );
};

const UserProfiles = ({ profiles, myProfile }: { profiles: Profile[]; myProfile: Profile }) => {
  const { t } = useTranslation();

  const columns: GridColDef[] = useMemo(() => {
    const baseColumns: GridColDef[] = [
      {
        field: "email",
        headerName: t("User"),
        width: 400,
        renderCell: ({ value }) => (
          <div className="flex items-center gap-3">
            <PersonOutlineIcon />
            {value}
          </div>
        ),
      },
      {
        field: "is_owner",
        headerName: t("Role"),
        width: 200,
        hideSortIcons: true,
        valueGetter: (value) => (value ? t("Owner") : t("User")),
      },
    ];

    if (myProfile.is_owner) {
      baseColumns.push({
        field: "actions",
        headerName: "",
        flex: 1,
        hideSortIcons: true,
        filterable: false,
        renderCell: ({ row }) => (
          <div className="flex items-center justify-end h-full pr-2 gap-2">
            {!row.is_owner && (
              <>
                <div className="opacity-0 group-hover:opacity-100">
                  <DialogTrigger>
                    <Button intent="secondary" size="small">
                      <SwapHorizIcon fontSize="small" /> {t("Assign owner")}
                    </Button>
                    <TransferOwnershipModal profile={row} />
                  </DialogTrigger>
                </div>
                <div className="opacity-0 group-hover:opacity-100">
                  <DialogTrigger>
                    <Button intent="secondary" size="small">
                      <DeleteIcon fontSize="small" />
                    </Button>
                    <RemoveUserModal profile={row} />
                  </DialogTrigger>
                </div>
              </>
            )}
          </div>
        ),
      });
    }

    return baseColumns;
  }, [t, myProfile]);

  return (
    <DataGrid
      id="user-profiles"
      rows={[...profiles].sort((a, b) => {
        if (a.id === myProfile.id) return -1;
        if (b.id === myProfile.id) return 1;
        return a.email.localeCompare(b.email);
      })}
      columns={columns}
      hideFooterPagination
      disableRowSelectionOnClick
      disableColumnMenu
      defaultCursorOnHover
      getRowClassName={() => "group"} // For DeleteIcon hover effect
    />
  );
};

const RemoveUserModal = ({ profile }: { profile: Profile }) => {
  const { t } = useTranslation();

  const { mutate: removeUser } = useRemoveUser();

  const handleConfirm = () => {
    removeUser(profile.id, {
      onSuccess: () => {
        showSuccessToast(t("Removed user."));
      },
      onError: () => {
        showErrorToast(t("Could not remove user."));
      },
    });
  };

  return (
    <Modal>
      {({ close }) => (
        <>
          <ModalHeader title={t("Remove user")} onClose={close} />
          <ModalBody>
            {t("Are you sure you'd like to remove the following user?")}
            <ul className="list-disc pl-4 mt-2">
              <li>{profile.email}</li>
            </ul>
          </ModalBody>
          <ModalFooter>
            <Button intent="secondary" onPress={close}>
              {t("Cancel")}
            </Button>
            <Button
              intent="secondary"
              danger
              onPress={() => {
                handleConfirm();
                close();
              }}
            >
              {t("Remove user")}
            </Button>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};

interface InviteUserForm {
  email: string;
}

const InviteUserModal = ({
  onConfirm,
  onCancel,
}: {
  onConfirm: () => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();
  const { mutate: inviteUser } = useInviteUser();

  const methods = useForm<InviteUserForm>({
    defaultValues: { email: "" },
  });

  const onSubmit: SubmitHandler<InviteUserForm> = ({ email }) => {
    inviteUser(email, {
      onSuccess: () => {
        showSuccessToast(t("Invitation sent."));
        onConfirm();
      },
      onError: () => {
        showErrorToast(t("Could not send invitation. Maybe this email address is already taken."));
      },
    });
  };

  return (
    <Dialog open onClose={() => onCancel()}>
      <div className="px-6 py-4">
        <Heading3>{t("Invite user")}</Heading3>
      </div>
      <FormProvider {...methods}>
        <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
          <DialogContent>
            <div className="flex flex-col justify-between min-h-40 pb-4">
              <TextFieldConnected name="email" isRequired label={t("Email Address")} />
              <div>
                {t(
                  "By inviting, the new user receives access to all data of this manufacturer account.",
                )}
              </div>
            </div>
            <DialogActions>
              <Button intent="secondary" onPress={onCancel}>
                {t("Cancel")}
              </Button>
              <Button intent="primary" type="submit">
                {t("Invite")}
              </Button>
            </DialogActions>
          </DialogContent>
        </form>
      </FormProvider>
    </Dialog>
  );
};

const TransferOwnershipModal = ({ profile }: { profile: Profile }) => {
  const { t } = useTranslation();
  const { mutate: transferOwnership } = useTransferOwnership();

  const handleConfirm = () => {
    transferOwnership(profile.id, {
      onSuccess: () => {
        showSuccessToast(t("Ownership transferred successfully."));
      },
      onError: () => {
        showErrorToast(t("Could not transfer ownership."));
      },
    });
  };

  return (
    <Modal>
      {({ close }) => (
        <>
          <ModalHeader title={t("Transfer ownership")} onClose={close} />
          <ModalBody>
            {t("Are you sure you'd like to transfer ownership to the following user?")}
            <Text16 className="font-bold my-2">{profile.email}</Text16>
            <Text16>
              {t(
                "Note: This action will remove your owner privileges and transfer them to the selected user.",
              )}
            </Text16>
          </ModalBody>
          <ModalFooter>
            <Button intent="secondary" onPress={close}>
              {t("Cancel")}
            </Button>
            <Button
              intent="primary"
              onPress={() => {
                handleConfirm();
                close();
              }}
            >
              {t("Transfer ownership")}
            </Button>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};
