import { AccountTreeOutlined, Clear } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { useMemo } from "react";
import { DialogTrigger } from "react-aria-components";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import {
  usePatchProductionProcess,
  useProductionProcesses,
} from "../api/endpoints/production-processes";
import { ProductionProcess, SupplierMaterial } from "../api/types";
import { Button } from "../components/Button";
import { ComboBoxMultiOption } from "../components/ComboBoxMultiField";
import { InfoTooltip } from "../components/InfoTooltip";
import { PageContainer } from "../components/PageContainer";
import { TopBar } from "../components/TopBar";
import { ComboBoxMultiFieldConnected } from "../form-components/ComboBoxMultiFieldConnected";
import { DeleteProcessModal } from "../page-components/plant-settings/DeleteProcessModal";
import { EditProcessNameModal } from "../page-components/plant-settings/EditProcessNameModal";
import { ProcessFlowModel } from "../page-components/plant-settings/ProcessFlowModel";
import { useMaterials } from "../state/materials";
import { useActivePlant } from "../state/plants";

type Fields = {
  inputOutputFields: Record<string, string[]>;
  packagingFields: string[];
};

const useEditProductionProcessForm = ({
  inputOutputFields,
  process,
}: {
  inputOutputFields: [string, ComboBoxMultiOption[]][];
  process: ProductionProcess;
}) => {
  const navigate = useNavigate();
  const { mutate: patchProductionProcess } = usePatchProductionProcess();

  const methods = useForm<Fields>({
    defaultValues: {
      inputOutputFields: Object.fromEntries(
        inputOutputFields.map(([group, items]) => [
          group,
          items
            .filter((item) => process.input_output_scoped.includes(item.id as string))
            .map((item) => item.id as string),
        ]),
      ),
      packagingFields: process.packaging_scoped,
    },
  });

  const onSubmit: SubmitHandler<Fields> = (values) => {
    patchProductionProcess(
      {
        ...process,
        input_output_scoped: Object.values(values.inputOutputFields).flat(),
        packaging_scoped: values.packagingFields,
      },
      {
        onSuccess: () => navigate(`/production?section=${process.id}`),
      },
    );
  };

  return {
    methods,
    onSubmit,
  };
};

const EditProductionProcessComponent = ({
  process,
  packagings,
}: {
  process: ProductionProcess;
  packagings: SupplierMaterial[];
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const groupLabels: Record<string, string> = useMemo(() => {
    return {
      raw_materials: t("Raw materials"),
      packaging: t("Packaging"),
      energy_and_fuels: t("Energy and fuels"),
      ancillary_materials: t("Ancillary materials"),
      waste: t("Waste"),
      emissions: t("Emissions"),
    };
  }, [t]);

  const inputOutputFields: [string, ComboBoxMultiOption[]][] = process
    ? Object.entries(Object.groupBy(process?.input_output_options, (opt) => opt.group)).map(
        ([group, items]) => {
          const hasSubgroups = items?.some((field) => field.subgroup);
          return [
            group,
            items!
              .map((field) => ({
                ...field,
                label: t(field.label),
              }))
              .sort((a, b) => {
                if (a.subgroup === b.subgroup) return a.label.localeCompare(b.label);
                else if (!a.subgroup) return 1;
                else if (!b.subgroup) return -1;
                else return a.subgroup.localeCompare(b.subgroup);
              })
              .map((field) => ({
                id: field.key,
                label: field.label,
                isDisabled: field.disabled,
                group: hasSubgroups ? t(field.subgroup) || "Uncategorized" : undefined,
              })),
          ];
        },
      )
    : [];

  const packagingOptions = packagings.map((packaging) => ({
    id: packaging.id,
    label: packaging.name,
    isDisabled: false,
  }));

  const { methods, onSubmit } = useEditProductionProcessForm({
    inputOutputFields,
    process,
  });

  return (
    <PageContainer>
      <TopBar
        icon={<AccountTreeOutlined />}
        title={process.name}
        variant="concrete"
        subtitle={
          <div className="flex items-center gap-3">
            <span>{t("Add inputs & outputs below")}</span>
            {process && (
              <>
                <DialogTrigger>
                  <Button intent="link" size="small">
                    {t("Edit process name")}
                  </Button>
                  <EditProcessNameModal process={process} />
                </DialogTrigger>
                {process.is_deletable ? (
                  <DialogTrigger>
                    <Button intent="link">
                      <DeleteIcon fontSize="small" />
                    </Button>
                    <DeleteProcessModal process={process} />
                  </DialogTrigger>
                ) : (
                  <InfoTooltip
                    title={t(
                      "This production process cannot be deleted, because one or more products are conncected to it.",
                    )}
                    icon={<DeleteIcon className="text-neutral-400" fontSize="small" />}
                  />
                )}
              </>
            )}
          </div>
        }
        input={
          <Button intent="tertiary" onPress={() => navigate(`/production?section=${process.id}`)}>
            {t("Cancel process")}
            <Clear />
          </Button>
        }
      />
      <FormProvider {...methods}>
        <form noValidate onSubmit={methods.handleSubmit(onSubmit)} className="grow flex flex-col">
          <div className="-mx-6 px-6 py-3 bg-concrete bg-texture grid grid-cols-4 items-start gap-6">
            {inputOutputFields.map(([group, options]) => (
              <ComboBoxMultiFieldConnected<Fields>
                key={group}
                name={`inputOutputFields.${group}`}
                label={groupLabels[group] || group}
                options={options}
              />
            ))}
            {packagingOptions.length > 0 && (
              <ComboBoxMultiFieldConnected<Fields>
                key="packaging"
                name="packagingFields"
                label={t("Packaging")}
                options={packagingOptions}
              />
            )}
          </div>
          <div className="grid items-center justify-center gap-8 pb-20 grow">
            {process && <ProcessFlowModel process={process} />}
          </div>
          <div className="fixed bottom-10 left-1/2 -translate-x-1/2 flex items-center justify-center gap-4">
            <Button
              type="button"
              intent="secondary"
              onPress={() => {
                // TODO: update after design has clarified this
                window.history.back();
              }}
            >
              {t("Cancel")}
            </Button>
            <Button type="submit" intent="primary">
              {t("Submit")}
            </Button>
          </div>
        </form>
      </FormProvider>
    </PageContainer>
  );
};

export const EditProductionProcess = () => {
  const activePlant = useActivePlant();
  const { id } = useParams();

  const { packagingMaterials } = useMaterials();
  const { data: processes } = useProductionProcesses({ plantId: activePlant.id });

  const packagings = packagingMaterials
    .filter((x) => x.supplier_product)
    .map((x) => x.supplier_product);

  const process = processes?.find((p) => p.id === id);

  if (!process) return null;

  return <EditProductionProcessComponent process={process} packagings={packagings} />;
};
