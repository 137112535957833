import { useCallback } from "react";
import { ProductWithStatus } from "../api/types";
import { useElementaries } from "../state/elementaries";

export const useAreProductsComparable = () => {
  const { elementariesMap } = useElementaries();

  return useCallback(
    (product1: ProductWithStatus, product2: ProductWithStatus) => {
      const elementary1 = elementariesMap[product1.elementary_id];
      const elementary2 = elementariesMap[product2.elementary_id];

      return (
        product1.id !== product2.id &&
        elementary1.product_metadata_id === elementary2.product_metadata_id &&
        product1.unit === product2.unit &&
        product1.status !== "incomplete" &&
        product2.status !== "incomplete"
      );
    },
    [elementariesMap],
  );
};
