import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Text16 } from "../../components/TypographyOld";
import { ComboBoxFieldConnected } from "../../form-components/ComboBoxFieldConnected";
import { NumberFieldConnected } from "../../form-components/NumberFieldConnected";

export type AutomaticDoorScenario = {
  // emissions
  ethyl_hexanol: number;
  decamethylcyclopentasiloxane: number;
  benzene: number;
  trichloroethylene: number;
  dibutyl_phthalate: number;
  dioctyl_phthalate: number;
  formaldehyde: number;
  acetaldehyde: number;
  propanal: number;
  butyraldehyde: number;
  butenal: number;
  glutaraldehyde: number;
  // energy usage
  usage_frequency: ReturnType<typeof useUsageFrequencyOptions>[number]["id"];
  electricity_consumption_per_cycle_kwh: number;
};

const useUsageFrequencyOptions = () => {
  const { t } = useTranslation();
  return useMemo(
    () => [
      { label: `${t("Very frequent use")} (1,000,000)`, id: "very-frequent" as const },
      { label: `${t("High-frequency use")} (500,000)`, id: "high-frequency" as const },
      { label: `${t("Heavy use")} (200,000)`, id: "heavy" as const },
      { label: `${t("Normal-duty use")} (100,000)`, id: "normal-duty" as const },
      { label: `${t("Medium-duty use")} (50,000)`, id: "medium-duty" as const },
      { label: `${t("Frequent use")} (20,000)`, id: "frequent" as const },
      { label: `${t("Moderate use")} (10,000)`, id: "moderate" as const },
      { label: `${t("Light/occasional use")} (5,000)`, id: "occasional" as const },
    ],
    [t],
  );
};

export const AutomaticDoorFields = () => {
  const { t } = useTranslation();
  return (
    <div className="col-span-full">
      <Text16 className="mb-4">{t("Indoor Air Emissions")}</Text16>
      <div className="grid xl:grid-cols-3 grid-cols-2 gap-6">
        <NumberFieldConnected
          name="scenario.ethyl_hexanol"
          label="Ethyl Hexanol"
          inputProps={{
            addonRight: "μg/m³/28d",
          }}
          minValue={0}
          isRequired
        />
        <NumberFieldConnected
          name="scenario.decamethylcyclopentasiloxane"
          label="Decamethylcyclopentasiloxane"
          inputProps={{
            addonRight: "μg/m³/28d",
          }}
          minValue={0}
          isRequired
        />
        <NumberFieldConnected
          name="scenario.benzene"
          label="Benzene"
          inputProps={{
            addonRight: "μg/m³/28d",
          }}
          minValue={0}
          isRequired
        />
        <NumberFieldConnected
          name="scenario.trichloroethylene"
          label="Trichloroethylene"
          inputProps={{
            addonRight: "μg/m³/28d",
          }}
          minValue={0}
          isRequired
        />
        <NumberFieldConnected
          name="scenario.dibutyl_phthalate"
          label="Dibutyl Phthalate"
          inputProps={{
            addonRight: "μg/m³/28d",
          }}
          minValue={0}
          isRequired
        />
        <NumberFieldConnected
          name="scenario.dioctyl_phthalate"
          label="Dioctyl Phthalate"
          inputProps={{
            addonRight: "μg/m³/28d",
          }}
          minValue={0}
          isRequired
        />
        <NumberFieldConnected
          name="scenario.formaldehyde"
          label="Formaldehyde"
          inputProps={{
            addonRight: "μg/m³/28d",
          }}
          minValue={0}
          isRequired
        />
        <NumberFieldConnected
          name="scenario.acetaldehyde"
          label="Acetaldehyde"
          inputProps={{
            addonRight: "μg/m³/28d",
          }}
          minValue={0}
          isRequired
        />
        <NumberFieldConnected
          name="scenario.propanal"
          label="Propanal"
          inputProps={{
            addonRight: "μg/m³/28d",
          }}
          minValue={0}
          isRequired
        />
        <NumberFieldConnected
          name="scenario.butyraldehyde"
          label="Butyraldehyde"
          inputProps={{
            addonRight: "μg/m³/28d",
          }}
          minValue={0}
          isRequired
        />
        <NumberFieldConnected
          name="scenario.butenal"
          label="Butenal"
          inputProps={{
            addonRight: "μg/m³/28d",
          }}
          minValue={0}
          isRequired
        />
        <NumberFieldConnected
          name="scenario.glutaraldehyde"
          label="Glutaraldehyde"
          inputProps={{
            addonRight: "μg/m³/28d",
          }}
          minValue={0}
          isRequired
        />
      </div>

      <Text16 className="mt-6 mb-4">{t("Energy Use")}</Text16>
      <div className="grid xl:grid-cols-3 grid-cols-2 gap-6">
        <ComboBoxFieldConnected
          name="scenario.usage_frequency"
          label={t("Cycles per year")}
          isRequired
          options={useUsageFrequencyOptions()}
        />
        <NumberFieldConnected
          name="scenario.electricity_consumption_per_cycle_kwh"
          label={t("Electricity consumption per cycle")}
          isRequired
          inputProps={{
            addonRight: "kWh",
          }}
          rules={{
            validate: (value) => {
              if (value === 0) {
                return t("Must be greater than 0");
              }
            },
          }}
        />
      </div>
    </div>
  );
};
