import { useTranslation } from "react-i18next";
import { C2 } from "../../components/Typography";

export const EPDPreviewSkeleton = ({ logoSrc }: { logoSrc?: string }) => {
  const { t } = useTranslation();

  return (
    <div className="border-t border-x px-9 py-4">
      <div className="space-y-3">
        <div className="w-48 h-14 flex items-center justify-center">
          {logoSrc ? (
            <img
              src={logoSrc}
              alt="Company Logo"
              className="w-full h-full object-contain object-left"
            />
          ) : (
            <div className="bg-neutral-100 w-full h-full flex items-center justify-center">
              {t("Company logo")}
            </div>
          )}
        </div>
        <div className="bg-green-400 px-3 py-2">
          <C2>1. General Information</C2>
        </div>
        <div className="grid grid-cols-2 gap-y-2 gap-x-6">
          <div className="bg-neutral-100 h-4 w-3/4" />
          <div className="bg-neutral-100 h-4 w-1/2" />
          <div className="bg-neutral-100 h-4 w-1/2" />
          <div className="bg-neutral-100 h-4 w-1/2" />
          <div className="bg-neutral-100 h-4 w-3/4" />
          <div className="bg-neutral-100 h-4 w-3/4" />
        </div>
      </div>
    </div>
  );
};
