import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const HARD_CODED_CONCRETE_ELEMENTARY_ID = "05ce014f-4e1a-49ab-86dd-79bfece54442";
export const HARD_CODED_BITUMEN_MEMBRANE_ELEMENTARY_ID = "bf8eafe4-1e36-4738-a78f-0f78633cf0c9";
export const HARD_CODED_ELASTOMER_MEMBRANE_ELEMENTARY_ID = "299485f6-7b40-412e-88bf-b5f7994ca495";
export const HARD_CODED_DRAINAGE_CHANNEL_ELEMENTARY_ID = "7d5fdd50-47d7-4334-b2f4-9f3e20c54c0e";
export const HARD_CODED_AUTOMATIC_DOOR_ELEMENTARY_ID = "81d479de-fd12-4928-8d48-c4d5cb7a8dde";
export const HARD_CODED_PARTITION_SYSTEM_ELEMENTARY_ID = "3cfc7361-5c65-4676-b048-d82e46e0f048";
export const HARD_CODED_CABLE_TRAY_ELEMENTARY_ID = "479492f0-dad5-43f5-b443-852ac774b5ca";

export const HARDCODED_ELEMENTARIES_WITHOUT_RECIPE = [
  "035d69c2-c36b-44b7-93ed-ead5f831dd34",
  "de0d83d3-5788-4db5-944b-7d621e3d6d05",
  "40d9191d-206e-4ed1-9f9a-01ede433d9b2",
  "da471f5f-8c83-49c8-ac60-c505963dfb7c",
];

export const HARD_CODED_PRECAST_CATEGORY_ID = "dfaa7c81-5887-4a4c-bab7-4c2febe8fcee";

export const HARDE_CODED_MANAGEMENT_SYSTEM_OPTIONS = [
  "ISO 9001",
  "ISO 14001",
  "ISO 45001",
  "ISO 50001",
  "ISO 27001",
  "ISO 37001",
  "ISO 45003",
  "BES 6001",
  "EMAS",
];

// -------------------
// TODO: move to backend or to some place where we define product categories
export const useHardCodedConcreteMustContainValidation = () => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        label: t("Cement (or substitutes)"),
        fromCategories: ["cement", "ggbs", "fly ash", "silica fume powder"],
      },
      {
        label: t("Aggregates"),
        fromCategories: [
          "gravel",
          "aggregates",
          "natural aggregates",
          "sand",
          "limestone",
          "recycled concrete",
          "recycled brick",
          "recycled glass",
          "rock dust",
          "natural stone",
          "sand and gravel",
        ],
      },
      {
        label: t("Water"),
        fromCategories: ["water"],
      },
    ],
    [t],
  );
};
// -------------------

export const HARDCODED_CAS_NUMBERS = [
  "10022-68-1",
  "10043-35-3",
  "10099-74-8",
  "101-14-4",
  "101-61-1",
  "101-77-9",
  "101-80-4",
  "10108-64-2",
  "10124-36-4",
  "10124-43-3",
  "10141-05-6",
  "10325-94-7",
  "10588-01-9",
  "106-94-5",
  "1067-53-4",
  "107-06-2",
  "107-15-3",
  "1072-63-5",
  "108-78-1",
  "109-86-4",
  "110-00-9",
  "110-49-6",
  "110-71-4",
  "110-80-5",
  "111-15-9",
  "111-30-8",
  "111-96-6",
  "11103-86-9",
  "11113-50-1",
  "11120-22-2",
  "112-49-2",
  "1120-71-4",
  "115-96-8",
  "1163-19-5",
  "117-81-7",
  "117-82-8",
  "119-47-1",
  "119313-12-1",
  "120-12-7",
  "120-71-8",
  "12008-41-2",
  "12036-76-9",
  "12060-00-3",
  "12065-90-6",
  "121-14-2",
  "121158-58-5",
  "12141-20-7",
  "12179-04-3",
  "12202-17-4",
  "12267-73-1",
  "123-77-3",
  "123-91-1",
  "12578-12-0",
  "12626-81-2",
  "12656-85-8",
  "127-19-5",
  "129-00-0",
  "1718-52-1",
  "1303-28-2",
  "1303-86-2",
  "1303-96-4",
  "1306-19-0",
  "1306-23-6",
  "131-18-0",
  "1314-41-6",
  "13149-00-3",
  "1317-36-8",
  "1319-46-6",
  "1327-53-3",
  "1330-43-4",
  "1333-73-9",
  "1333-82-0",
  "134237-50-6",
  "134237-51-7",
  "134237-52-8",
  "13424-46-9",
  "1344-37-2",
  "13530-68-2",
  "13701-59-2",
  "1372804-76-6",
  "13814-96-5",
  "13840-56-7",
  "140-66-9",
  "14166-21-3",
  "143-24-8",
  "14312-40-4",
  "143860-04-2",
  "14890-53-0",
  "15087-24-8",
  "1522-92-5",
  "15245-44-0",
  "15571-58-1",
  "15606-95-8",
  "1718-53-2",
  "1719-03-5",
  "17570-76-2",
  "1782069-81-1",
  "191-24-2",
  "1937-37-7",
  "19438-60-9",
  "198840-65-2",
  "2058-94-8",
  "206-44-0",
  "93951-69-0",
  "207-08-9",
  "20837-86-9",
  "21041-95-2",
  "21049-39-8",
  "210555-94-5",
  "218-01-9",
  "22454-04-2",
  "22673-19-4",
  "2451-62-9",
  "24613-89-6",
  "25155-23-1",
  "25214-70-4",
  "25550-51-0",
  "255881-94-8",
  "25637-99-4",
  "25747-83-5",
  "2580-56-5",
  "25973-55-1",
  "27147-75-7",
  "27459-10-5",
  "301-04-2",
  "302-01-2",
  "307-55-1",
  "31119-53-6",
  "3194-55-6",
  "3296-90-0",
  "335-67-1",
  "335-76-2",
  "36437-37-3",
  "36483-57-5",
  "36861-47-9",
  "3687-31-8",
  "375-95-1",
  "376-06-7",
  "37853-59-1",
  "3825-26-1",
  "3846-71-7",
  "3864-99-1",
  "4149-60-4",
  "4247-02-3",
  "48122-14-1",
  "49663-84-5",
  "50-32-8",
  "513-78-0",
  "513-79-1",
  "51404-69-4",
  "540-97-6",
  "541-02-6",
  "548-62-9",
  "552-30-7",
  "556-67-2",
  "56-35-9",
  "56-55-3",
  "561-41-1",
  "57110-29-9",
  "573-58-0",
  "57427-55-1",
  "59653-74-6",
  "60-09-3",
  "605-50-5",
  "61788-32-7",
  "62229-08-7",
  "625-45-6",
  "629-14-1",
  "64-67-5",
  "6477-64-1",
  "65996-93-2",
  "6786-83-0",
  "68-12-2",
  "6807-17-6",
  "683-18-1",
  "68515-42-4",
  "68515-50-4",
  "68515-51-5",
  "68648-93-1",
  "68784-75-8",
  "69011-06-9",
  "693-98-1",
  "71-48-7",
  "71850-09-4",
  "71868-10-5",
  "71888-89-6",
  "72629-94-8",
  "741687-98-9",
  "7439-92-1",
  "7440-43-9",
  "74499-35-7",
  "75-12-7",
  "75-56-9",
  "75166-30-2",
  "75166-31-3",
  "7632-4-4",
  "7646-79-9",
  "77-09-8",
  "77-40-7",
  "77-78-1",
  "7738-94-5",
  "7758-97-6",
  "776297-69-9",
  "7775-11-3",
  "7778-39-4",
  "7778-44-1",
  "7778-50-9",
  "7784-40-9",
  "7789-00-6",
  "7789-12-0",
  "7789-6-2",
  "7789-9-5",
  "7790-79-6",
  "78-00-2",
  "7803-57-8",
  "79-01-6",
  "79-06-1",
  "79-16-3",
  "79-94-7",
  "80-05-7",
  "80-09-1",
  "80-46-6",
  "80-54-6",
  "8012-00-8",
  "81-15-2",
  "838-88-0",
  "84-61-7",
  "84-69-5",
  "84-74-2",
  "84-75-3",
  "84777-06-0",
  "85-01-8",
  "85-42-7",
  "85-68-7",
  "852541-21-0",
  "852541-25-4",
  "852541-30-1",
  "85535-84-8",
  "85535-85-9",
  "872-50-4",
  "88-85-7",
  "90-04-0",
  "90-94-8",
  "90640-80-5",
  "90640-81-6",
  "90640-82-7",
  "91031-62-8",
  "91995-15-2",
  "91995-17-4",
  "92-67-1",
  "924-42-5",
  "94-26-8",
  "95-53-4",
  "95-80-7",
  "95342-41-9",
  "96-13-9",
  "96-18-4",
  "96-45-7",
  "97-56-3",
  "98-54-4",
  "98-95-3",
];
