import { LaunchOutlined } from "@mui/icons-material";
import { DialogTrigger } from "react-aria-components";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { EPDSearchResult } from "../../../api/types";
import { Button } from "../../../components/Button";
import { InfoTooltip } from "../../../components/InfoTooltip";
import { Link } from "../../../components/Link";
import { Modal } from "../../../components/Modal";
import { Radio } from "../../../components/RadioGroupField";
import { C1, C2 } from "../../../components/Typography";
import { RadioGroupFieldConnected } from "../../../form-components/RadioGroupFieldConnected";
import { SearchEPDModalContent } from "./SearchEPDModalContent";

const PrimaryDataLabel = ({ item }: { item: EPDSearchResult }) => {
  return (
    <div className="flex items-center gap-1">
      {item.name_en}
      <Link
        intent="link"
        size="small"
        href={item.item_source_url || item.epd_pdf_url}
        target="_blank"
      >
        <LaunchOutlined
          fontSize="small"
          className={item.can_be_used ? "text-neutral-500" : "text-neutral-400"}
        />
      </Link>
    </div>
  );
};

export const PrimaryData = ({
  primaryData,
  isEditing,
}: {
  primaryData: EPDSearchResult[];
  isEditing: boolean;
}) => {
  const { t } = useTranslation();

  const methods = useFormContext();
  const epdId = methods.watch("epdId");
  const selectedEPD = primaryData.find((item) => item.id === epdId);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-2">
        <C1 $bold>{t("EPD")}</C1>
        <InfoTooltip>
          {t(`EPD data is the life cycle inventory (LCI) data provided by published EPDs.`)}
        </InfoTooltip>
      </div>

      {isEditing ? (
        <>
          <RadioGroupFieldConnected name="epdId" aria-label={t("Select")}>
            {primaryData.map((item) => (
              <Radio
                key={item.id}
                value={item.id}
                isDisabled={!item.can_be_used}
                description={`${item.manufacturer}, ${item.reference_year}`}
              >
                <PrimaryDataLabel item={item} />
              </Radio>
            ))}
          </RadioGroupFieldConnected>

          <div className="space-y-2">
            <div className="flex items-center gap-2 [&_p,&_button]:text-neutral-400">
              <C2>{t("The right EPD isn't shown here?")}</C2>
              <DialogTrigger>
                <Button intent="link" size="small">
                  {t("Try manual search")}
                </Button>
                <Modal size="lg">{({ close }) => <SearchEPDModalContent onClose={close} />}</Modal>
              </DialogTrigger>
            </div>
          </div>
        </>
      ) : (
        <div className="flex items-center gap-4">
          <div className="shrink-0 flex items-center justify-center size-12 bg-builtgreen text-energyyellow font-bold">
            EPD
          </div>
          {selectedEPD && (
            <div className="flex flex-col [&_[data-description]]:text-neutral-400">
              <PrimaryDataLabel item={selectedEPD} />
              <C2
                data-description
              >{`${selectedEPD?.manufacturer}, ${selectedEPD?.reference_year}`}</C2>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
