import { useMutation, useQuery, useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { saveAs } from "file-saver";
import { useSearchParams } from "react-router";
import {
  useDeleteProduct,
  useGetProduct,
  useGetProductExcelExport,
  useGetProductImpactScale,
  useGetProducts,
  useGetProductsBulkUploadSheet,
  usePatchProduct,
  usePostDocumentBulkPCF,
  usePostDocumentComparisonPCF,
  usePostProduct,
  usePostProductsBulkUpload,
  usePostUpdateProductLca,
} from "../api/endpoints/products";
import { BulkUploadResult, Product } from "../api/types";
import { showErrorToast } from "../util/toasts";
import { useActivePlant } from "./plants";

const updateProductInList = (
  oldList: Product[] | undefined,
  product: Product,
): Product[] | undefined => {
  if (!oldList) return oldList;
  if (oldList.find((p) => p.id === product.id)) {
    return oldList.map((p) => (p.id === product.id ? { ...p, ...product } : p));
  }
  // There's a chance that the product is not in the list, so we add it.
  // Happens when a product is created, then updated, but the list didn't have to refresh
  // in the mean time.
  return [...oldList, product];
};

export const useProducts = ({ plantId }: { plantId?: string }) => {
  const getProducts = useGetProducts();

  return useQuery({
    queryKey: [plantId, "products"],
    queryFn: () => getProducts({ plantId: plantId! }),
    enabled: !!plantId,
  });
};

export const useActivePlantProducts = () => {
  const activePlant = useActivePlant();
  const getProducts = useGetProducts();

  return useSuspenseQuery({
    queryKey: [activePlant.id, "products"],
    queryFn: () =>
      getProducts({
        plantId: activePlant.id,
      }),
  });
};

export const useSelectedProductId = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("selectedProduct") || undefined;

  return id;
};

export const useProduct = (id: string) => {
  const activePlant = useActivePlant();
  const getProduct = useGetProduct();

  return useSuspenseQuery({
    queryKey: [activePlant.id, "products", id],
    queryFn: () =>
      getProduct({
        id,
        plantId: activePlant.id,
      }),
  });
};

export const useProductImpactScale = ({ plantId, id }: { plantId: string; id: string }) => {
  const getProductImpactScale = useGetProductImpactScale();

  return useSuspenseQuery({
    queryKey: [plantId, "productImpactScale", id],
    queryFn: () =>
      getProductImpactScale({
        id,
        plantId,
      }),
  });
};

export const useAddProduct = () => {
  const activePlant = useActivePlant();
  const queryClient = useQueryClient();
  const postProduct = usePostProduct();

  return useMutation({
    mutationFn: (product: Parameters<typeof postProduct>[0]["product"]) =>
      postProduct({
        plantId: activePlant.id,
        product,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [activePlant.id, "products"],
      });
    },
  });
};

export const useUpdateProduct = () => {
  const activePlant = useActivePlant();
  const queryClient = useQueryClient();
  const patchProduct = usePatchProduct();

  return useMutation({
    mutationFn: ({
      productId,
      product,
    }: Pick<Parameters<typeof patchProduct>[0], "productId" | "product">) =>
      patchProduct({
        plantId: activePlant.id,
        productId,
        product,
      }),
    onSuccess: (product) => {
      queryClient.invalidateQueries({
        queryKey: [activePlant.id, "products", product.id],
      });
      queryClient.invalidateQueries({
        queryKey: [activePlant.id, "productImpactScale", product.id],
      });
      queryClient.setQueryData<Product[]>([activePlant.id, "products"], (oldList) =>
        updateProductInList(oldList, product),
      );
    },
  });
};

export const useRemoveProducts = () => {
  const queryClient = useQueryClient();
  const deleteProduct = useDeleteProduct();

  return useMutation({
    mutationFn: (props: Parameters<typeof deleteProduct>[0][]) => {
      return Promise.all(props.map((p) => deleteProduct(p)));
    },
    onSuccess: (_, [{ plantId }]) => {
      queryClient.invalidateQueries({ queryKey: [plantId, "products"] });
    },
  });
};

export const useUpdateProductLca = () => {
  const queryClient = useQueryClient();
  const updateProductLca = usePostUpdateProductLca();

  return useMutation({
    retry: false,
    mutationFn: updateProductLca,
    onSuccess: (product, { plantId, productId }) => {
      queryClient.invalidateQueries({ queryKey: [plantId, "products", productId] });
      queryClient.setQueryData<Product[]>([plantId, "products"], (oldList) =>
        updateProductInList(oldList, product),
      );
    },
  });
};

export const useDownloadProductExcelExport = () => {
  const getProductExcelExport = useGetProductExcelExport();

  return useMutation({
    mutationFn: async () => {
      const response = await getProductExcelExport();
      saveAs(response, "Product-Export.xlsx");
    },
  });
};

export const useDownloadProductsBulkUploadSheet = () => {
  const activePlant = useActivePlant();
  const getProductsBulkUploadSheet = useGetProductsBulkUploadSheet();

  return useMutation({
    mutationFn: async (elementaryId: string) => {
      const response = await getProductsBulkUploadSheet({ plantId: activePlant.id, elementaryId });
      saveAs(response, "Product-Bulk-Upload.xlsx");
    },
  });
};

export const useProductsBulkUpload = ({
  onSuccess,
}: {
  onSuccess: (result: BulkUploadResult) => void;
}) => {
  const queryClient = useQueryClient();
  const activePlant = useActivePlant();
  const bulkUploadProducts = usePostProductsBulkUpload();

  return useMutation({
    mutationFn: (file: File) => {
      return bulkUploadProducts({
        file,
      });
    },
    onSuccess: (result) => {
      queryClient.invalidateQueries({
        queryKey: [activePlant.id, "products"],
      });
      queryClient.invalidateQueries({
        queryKey: [activePlant.id, "productRecipe"],
      });
      onSuccess(result);
    },
    onError: () => {
      showErrorToast("Something went wrong. Make sure to download an up-to-date template.");
    },
  });
};

export const useDocumentBulkPCF = () => {
  const documentBulkPCF = usePostDocumentBulkPCF();

  return useMutation({
    mutationFn: documentBulkPCF,
  });
};

export const useDocumentComparisonPCF = () => {
  const documentComparisonPCF = usePostDocumentComparisonPCF();

  return useMutation({
    mutationFn: documentComparisonPCF,
  });
};
