import { UploadFileOutlined } from "@mui/icons-material";
import { FileTrigger } from "react-aria-components";
import { useTranslation } from "react-i18next";
import { usePatchManufacturer } from "../../api/endpoints/manufacturers";
import { useAuthenticatedImgSrc, useUploadImage } from "../../api/endpoints/media";
import { Button } from "../../components/Button";
import { Card } from "../../components/Card";
import { Text14 } from "../../components/TypographyOld";
import { useActiveManufacturer } from "../../state/manufacturers";
import { showErrorToast } from "../../util/toasts";
import { useValidateFileSize } from "../../util/validateFileSize";
import { EPDPreviewSkeleton } from "./EPDPreviewSkeleton";

export const ManageCompanyLogo = () => {
  const { t } = useTranslation();

  const uploadImage = useUploadImage();
  const { mutate: updateManufacturer } = usePatchManufacturer();

  const { activeManufacturer } = useActiveManufacturer();
  const validateFileSize = useValidateFileSize();

  const handleFileDrop = async (files: FileList | null) => {
    const file = files?.[0];
    if (!file) return;
    try {
      validateFileSize(file);
    } catch (_) {
      return;
    }

    try {
      const key = await uploadImage({
        file,
      });
      updateManufacturer({
        manufacturer: { ...activeManufacturer, image_url: key },
      });
    } catch (_) {
      showErrorToast(t("Couldn't upload company logo."));
    }
  };

  const logoSrc = useAuthenticatedImgSrc({ imageUrl: activeManufacturer.image_url || undefined });

  return (
    <Card title={t("Company logo")}>
      <div className="text-right w-full">
        <Text14 className="text-neutral-500">{t("EPD document preview")}</Text14>
      </div>
      <div className="grid grid-cols-2 gap-8 items-start">
        <div className="space-y-4 mt-5 ml-8">
          <Text14 className="text-neutral-500">
            {t("Pick a logo to display on the header of your generated EPDs.")}
            <br />
            {/*
            {t("Ideal size is 256px - 660px (PNG / SVG / WEBP)")}
            Displaying SVG in the UI did not work out of the box.
            */}
            {t("Ideal size is 256px - 660px (PNG / JPEG / WEBP)")}
          </Text14>
          <FileTrigger onSelect={handleFileDrop}>
            <Button intent="secondary">
              <UploadFileOutlined />
              {activeManufacturer.image_url ? t("Update company logo") : t("Upload company logo")}
            </Button>
          </FileTrigger>
        </div>
        <EPDPreviewSkeleton logoSrc={logoSrc} />
      </div>
    </Card>
  );
};
