import { cva } from "class-variance-authority";
import { ReactNode } from "react";
import { Button as ButtonPrimitive } from "react-aria-components";
import { Label14 } from "./TypographyOld";

const containerStyles = cva(["bg-white", "flex", "items-center"])();

const buttonStyles = cva([
  "flex items-center gap-1",
  "cursor-pointer",
  "bg-white",
  "px-6",
  "h-12",
  "border-b",
  "border-transparent",
  "outline-hidden",
  "data-[active=true]:border-steelblue",
  "[&[data-active=false]>p]:text-neutral-400",
]);

export const TabSwitch = ({
  activeTab,
  setActiveTab,
  options,
}: {
  activeTab: string;
  setActiveTab: (tab: string) => void;
  options: { label: ReactNode; value: string }[];
}) => {
  return (
    <div className={containerStyles}>
      {options.map(({ label, value }) => (
        <ButtonPrimitive
          data-active={value === activeTab}
          key={value}
          onPress={() => setActiveTab(value)}
          className={buttonStyles()}
        >
          <Label14>{label}</Label14>
        </ButtonPrimitive>
      ))}
    </div>
  );
};
