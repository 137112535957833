import { useTranslation } from "react-i18next";
import { PlantMaterial } from "../../api/types";
import { Button } from "../../components/Button";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "../../components/Modal";

export const DeleteMaterialsModal = ({
  selectedMaterials,
  onSubmit,
}: {
  selectedMaterials: Set<PlantMaterial>;
  onSubmit: (close: () => void) => Promise<void>;
}) => {
  const { t } = useTranslation();

  return (
    <Modal>
      {({ close }) => (
        <>
          <ModalHeader title={t("Delete materials")} onClose={close} />
          <ModalBody>
            {t("Are you sure you'd like to delete these materials?")}
            <ul className="list-disc pl-4 mt-2">
              {[...selectedMaterials].map((material) => (
                <li key={material.supplier_product?.id || material.prechain_product?.id}>
                  {material.supplier_product?.name || material.prechain_product?.product.name}
                </li>
              ))}
            </ul>
          </ModalBody>
          <ModalFooter>
            <Button intent="secondary" onPress={close}>
              {t("Cancel")}
            </Button>
            <Button intent="secondary" danger onPress={() => onSubmit(close)}>
              {t("Delete materials")}
            </Button>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};
