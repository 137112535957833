import { useCallback, useState } from "react";
import { PlantMaterial } from "../../api/types";

export const useMaterialSelection = ({ materials }: { materials: PlantMaterial[] }) => {
  const [isSelectMode, setSelectMode] = useState(false);
  const [selectedMaterials, setSelectedMaterials] = useState<Set<PlantMaterial>>(new Set());

  const toggleSelect = useCallback((material: PlantMaterial) => {
    setSelectedMaterials((prev) => {
      const next = new Set(prev);
      if (prev.has(material)) {
        next.delete(material);
      } else {
        next.add(material);
      }
      return next;
    });
  }, []);

  const toggleSelectMode = useCallback(() => {
    if (isSelectMode) {
      setSelectedMaterials(new Set());
    }
    setSelectMode((prev) => !prev);
  }, [isSelectMode]);

  const toggleSelectAll = useCallback(() => {
    if (selectedMaterials.size < materials.length) {
      setSelectedMaterials(new Set(materials));
    } else {
      setSelectedMaterials(new Set());
    }
  }, [materials, selectedMaterials.size]);

  const resetSelection = useCallback(() => {
    setSelectedMaterials(new Set());
    setSelectMode(false);
  }, []);

  return {
    isSelectMode,
    toggleSelectMode,
    toggleSelect,
    selectedMaterials,
    setSelectedMaterials,
    toggleSelectAll,
    resetSelection,
  };
};
