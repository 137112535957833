import { useMutation } from "@tanstack/react-query";
import { useCallback } from "react";
import { Declaration, VerifyDeclarationRequest } from "../types";
import { useApi } from "../useApi";

export const useGetDeclaration = () => {
  const api = useApi<Declaration>();

  return useCallback(
    ({ plantId, productId }: { plantId: string; productId: string }) =>
      api({
        url: `/v1/plants/${plantId}/products/${productId}/declarations`,
      }),
    [api],
  );
};

export const useUploadDocument = () => {
  const api = useApi<void>({
    method: "POST",
    headers: { "Content-Type": "multipart/form-data" },
  });

  return useMutation({
    mutationFn: ({
      plantId,
      productId,
      declarationId,
      token,
      file,
    }: {
      plantId: string;
      productId: string;
      declarationId: string;
      token: string;
      file: File;
    }) => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("token", token);

      return api({
        url: `/v1/plants/${plantId}/products/${productId}/declarations/${declarationId}/document`,
        data: formData,
      });
    },
  });
};

export const usePostDeclaration = () => {
  const api = useApi<void>({ method: "POST" });

  return useCallback(
    ({
      plantId,
      productId,
      programOperatorId,
    }: {
      plantId: string;
      productId: string;
      programOperatorId: string;
    }) =>
      api({
        url: `/v1/plants/${plantId}/products/${productId}/declarations`,
        data: { program_operator_id: programOperatorId },
      }),
    [api],
  );
};

export const usePostDeclarationVerification = () => {
  const api = useApi<Blob>({ method: "POST" });

  return useMutation({
    mutationFn: ({
      plantId,
      productId,
      data,
    }: {
      plantId: string;
      productId: string;
      data: VerifyDeclarationRequest;
    }) => {
      return api({
        url: `/v1/plants/${plantId}/products/${productId}/declarations/verication`,
        data,
        responseType: "blob",
      });
    },
  });
};
