import { t } from "i18next";
import { useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { Modal, ModalBody, ModalHeader } from "../components/Modal";
import { Text16 } from "../components/TypographyOld";

export const VersionInfo = () => {
  const [isOpen, setIsOpen] = useState(false);
  useHotkeys("shift+?", () => setIsOpen((open) => !open));

  // this is statically replaced with vite define
  const frontendVersion = __BUILD_TIMESTAMP__.replace(/"/g, "");

  return (
    <Modal isOpen={isOpen} onOpenChange={setIsOpen}>
      <ModalHeader title={t("Version Info")} />
      <ModalBody>
        <Text16>{frontendVersion}</Text16>
      </ModalBody>
    </Modal>
  );
};
