import { ArrowDropDownOutlined, ArrowDropUpOutlined } from "@mui/icons-material";
import { ResponsiveBar } from "@nivo/bar";
import { useState } from "react";
import { MenuTrigger } from "react-aria-components";
import { useTranslation } from "react-i18next";
import { Impact, ProductDetail } from "../../api/types";
import { Badge } from "../../components/Badge";
import { Button } from "../../components/Button";
import { InfoTooltip } from "../../components/InfoTooltip";
import { Menu, MenuItem } from "../../components/Menu";
import { tooltipStyles } from "../../components/Tooltip";
import { Label12, Text14 } from "../../components/TypographyOld";
import { coreIndicators, filterImpact } from "../../lib/impact";
import { formatPrecision } from "../../util/format";

const useLabels = () => {
  const { t } = useTranslation();
  return {
    a1: t("Raw materials"),
    a2: t("Transportation to plant"),
    a3: t("Manufacturing"),
  };
};

type StageData = {
  product1: number;
  product1Unit: string;
  product2: number;
  product2Unit: string;
  stage: string;
  indicator: string;
};

const getGridYValues = (data: StageData[]): number[] => {
  const maxValue = Math.max(...data.flatMap((d) => [d.product1, d.product2]));

  // Find the magnitude of maxValue (e.g. 100s, 1000s etc)
  const magnitude = Math.pow(10, Math.floor(Math.log10(maxValue)));

  // Round up to next multiple of magnitude/2
  const niceMax = Math.ceil(maxValue / (magnitude / 2)) * (magnitude / 2);

  // Create evenly distributed steps
  return Array.from({ length: 5 }, (_, i) => (niceMax * i) / 4);
};

const calculatePercentageDifference = (value1: number, value2: number): number => {
  if (value1 === 0 && value2 === 0) return 0;
  if (value1 === 0) return 100;
  const smaller = Math.min(Math.abs(value1), Math.abs(value2));
  const larger = Math.max(Math.abs(value1), Math.abs(value2));
  return ((larger - smaller) / smaller) * 100;
};

export const ProductComparisonLifeCycleChart = ({
  product1,
  product2,
}: {
  product1: ProductDetail;
  product2: ProductDetail;
}) => {
  const { t } = useTranslation();
  const styles = getComputedStyle(document.documentElement);
  const co2brown = styles.getPropertyValue("--color-co2brown");
  const neutral300 = styles.getPropertyValue("--color-neutral-300");
  const labels = useLabels();

  const [activeIndicator, setActiveIndicator] = useState<Impact["indicator_name"]>("GWP-total");
  const activeIndicatorOptions = Array.from(coreIndicators).map((indicator) => ({
    label: t(indicator),
    value: indicator,
    id: indicator,
  }));

  const getStageImpact = (product: ProductDetail, stage: "a1" | "a2" | "a3"): Impact[] => {
    return filterImpact(product.lca_results?.impact_summary[stage] || [], coreIndicators);
  };

  const stages = ["a1", "a2", "a3"] as const;

  const chartData: StageData[] = stages.flatMap((stage) => {
    const product1Impact = getStageImpact(product1, stage);
    const product2Impact = getStageImpact(product2, stage);

    return Array.from(coreIndicators).map<StageData>((indicator) => {
      const product1Data = product1Impact.find((i) => i.indicator_name === indicator);
      const product2Data = product2Impact.find((i) => i.indicator_name === indicator);

      return {
        stage: labels[stage],
        indicator,
        product1: product1Data?.value ?? 0,
        product1Unit: t(product1Data?.unit ?? ""),
        product2: product2Data?.value ?? 0,
        product2Unit: t(product2Data?.unit ?? ""),
      };
    });
  });

  const filteredChartData = chartData.filter((item) => item.indicator === activeIndicator);

  const gridYValues = getGridYValues(filteredChartData);

  // (_, value) => setActiveIndicator(value?.id as Impact["indicator_name"])
  const getIndicatorChangeAction = (indicator: Impact["indicator_name"]) => () =>
    setActiveIndicator(indicator);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between gap-2">
        <Label12 className="flex items-center gap-1">
          {t(activeIndicator)}: {t("Lifecycle stages")}
          <InfoTooltip>
            {t(
              "Steps that track the product's environmental impact across its lifecycle stages: from raw material extraction, through production and use, to disposal or recycling.",
            )}
          </InfoTooltip>
        </Label12>
        <MenuTrigger>
          <Button intent="secondary" size="small">
            {t("Show")} {activeIndicatorOptions.find((i) => i.value === activeIndicator)?.label}
            <ArrowDropDownOutlined />
          </Button>
          <Menu>
            {activeIndicatorOptions.map((option) => (
              <MenuItem
                key={option.id}
                text={option.label}
                onAction={getIndicatorChangeAction(option.value)}
                size="sm"
              />
            ))}
          </Menu>
        </MenuTrigger>
      </div>
      <div className="h-[200px]">
        <ResponsiveBar
          data={filteredChartData}
          keys={["product1", "product2"]}
          indexBy="stage"
          groupMode="grouped"
          padding={0.5}
          innerPadding={4}
          colors={({ id }) => (id === "product1" ? co2brown : neutral300)}
          enableLabel={false}
          margin={{ top: 30, right: 0, bottom: 50, left: 60 }}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            renderTick: ({ value, x, y, tickIndex }) => {
              return (
                <g transform={`translate(${x},${y})`}>
                  <foreignObject x="-120" y="10" width="240" height="60">
                    <div className="flex flex-col items-center">
                      <Label12>{value}</Label12>
                      <Label12>(A{tickIndex + 1})</Label12>
                    </div>
                  </foreignObject>
                </g>
              );
            },
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            tickValues: gridYValues,
          }}
          gridYValues={gridYValues}
          labelSkipWidth={12}
          labelSkipHeight={12}
          tooltip={({ id, value, data }) => (
            <div className={tooltipStyles()}>
              <Text14>
                <strong>{id === "product1" ? product1.name : product2.name}</strong>
              </Text14>
              <Text14>
                {t(data.indicator)}:{" "}
                <strong>
                  {formatPrecision(value, 4)} {data[`${id}Unit` as keyof StageData]}
                </strong>
              </Text14>
            </div>
          )}
          layers={[
            "grid",
            "axes",
            "bars",
            "markers",
            "legends",
            "annotations",
            ({ bars }) => {
              return (
                <>
                  {bars.slice(0, bars.length / 2).map((bar, index) => {
                    const matchingBar = bars[index + bars.length / 2];
                    if (!matchingBar) return null;

                    const value1 = bar.data.data.product1;
                    const value2 = matchingBar.data.data.product2;
                    const percentDiff = calculatePercentageDifference(value1, value2);

                    // Calculate position for the badge
                    // Position at the right edge of the second bar
                    const barGroupWidth = matchingBar.width * 2;
                    const padding = barGroupWidth * 0.3;
                    const x = matchingBar.x + matchingBar.width + padding;
                    // Position at the top of the chart
                    const y = 0;

                    return (
                      <foreignObject key={`badge-${index}`} x={x + 10} y={y} width="80" height="30">
                        <div className="flex items-center justify-center">
                          <Badge color="brown" size="sm" shrunkIcon>
                            {percentDiff > 0 ? <ArrowDropUpOutlined /> : <ArrowDropDownOutlined />}
                            {percentDiff.toFixed(1)}%
                          </Badge>
                        </div>
                      </foreignObject>
                    );
                  })}
                </>
              );
            },
          ]}
        />
      </div>
    </div>
  );
};
