import { useTranslation } from "react-i18next";
import { Text16 } from "../../components/TypographyOld";
import { NumberFieldConnected } from "../../form-components/NumberFieldConnected";

export type PartitionSystemScenario = {
  ethyl_hexanol: number;
  decamethylcyclopentasiloxane: number;
  benzene: number;
  trichloroethylene: number;
  dibutyl_phthalate: number;
  dioctyl_phthalate: number;
  formaldehyde: number;
  acetaldehyde: number;
  propanal: number;
  butyraldehyde: number;
  butenal: number;
  glutaraldehyde: number;
};

export const PartitionSystemFields = () => {
  const { t } = useTranslation();
  return (
    <div className="col-span-full">
      <Text16 className="mb-4">{t("Indoor Air Emissions")}</Text16>
      <div className="grid xl:grid-cols-3 grid-cols-2 gap-6">
        <NumberFieldConnected
          name="scenario.ethyl_hexanol"
          label="Ethyl Hexanol"
          inputProps={{
            addonRight: "μg/m³/28d",
          }}
          minValue={0}
          isRequired
        />
        <NumberFieldConnected
          name="scenario.decamethylcyclopentasiloxane"
          label="Decamethylcyclopentasiloxane"
          inputProps={{
            addonRight: "μg/m³/28d",
          }}
          minValue={0}
          isRequired
        />
        <NumberFieldConnected
          name="scenario.benzene"
          label="Benzene"
          inputProps={{
            addonRight: "μg/m³/28d",
          }}
          minValue={0}
          isRequired
        />
        <NumberFieldConnected
          name="scenario.trichloroethylene"
          label="Trichloroethylene"
          inputProps={{
            addonRight: "μg/m³/28d",
          }}
          minValue={0}
          isRequired
        />
        <NumberFieldConnected
          name="scenario.dibutyl_phthalate"
          label="Dibutyl Phthalate"
          inputProps={{
            addonRight: "μg/m³/28d",
          }}
          minValue={0}
          isRequired
        />
        <NumberFieldConnected
          name="scenario.dioctyl_phthalate"
          label="Dioctyl Phthalate"
          inputProps={{
            addonRight: "μg/m³/28d",
          }}
          minValue={0}
          isRequired
        />
        <NumberFieldConnected
          name="scenario.formaldehyde"
          label="Formaldehyde"
          inputProps={{
            addonRight: "μg/m³/28d",
          }}
          minValue={0}
          isRequired
        />
        <NumberFieldConnected
          name="scenario.acetaldehyde"
          label="Acetaldehyde"
          inputProps={{
            addonRight: "μg/m³/28d",
          }}
          minValue={0}
          isRequired
        />
        <NumberFieldConnected
          name="scenario.propanal"
          label="Propanal"
          inputProps={{
            addonRight: "μg/m³/28d",
          }}
          minValue={0}
          isRequired
        />
        <NumberFieldConnected
          name="scenario.butyraldehyde"
          label="Butyraldehyde"
          inputProps={{
            addonRight: "μg/m³/28d",
          }}
          minValue={0}
          isRequired
        />
        <NumberFieldConnected
          name="scenario.butenal"
          label="Butenal"
          inputProps={{
            addonRight: "μg/m³/28d",
          }}
          minValue={0}
          isRequired
        />
        <NumberFieldConnected
          name="scenario.glutaraldehyde"
          label="Glutaraldehyde"
          inputProps={{
            addonRight: "μg/m³/28d",
          }}
          minValue={0}
          isRequired
        />
      </div>
    </div>
  );
};
