import { Close } from "@mui/icons-material";
import { cva, VariantProps } from "class-variance-authority";
import { ReactNode } from "react";
import {
  Dialog,
  DialogProps,
  ModalOverlay,
  ModalOverlayProps,
  Modal as ModalPrimitive,
} from "react-aria-components";
import { Button } from "./Button";
import { C2, Heading3 } from "./Typography";

const modalOverlayStyles = cva([
  "fixed",
  "inset-0",
  "z-100",
  "flex",
  "items-start",
  "justify-center",
  "min-h-full",
  "overflow-y-auto",
  "bg-slate-900/40",
  "p-8",
  "text-center",
  "backdrop-blur-xs",
])();

const modalStyles = cva(
  ["w-full", "rounded-none", "border", "bg-white", "text-left", "shadow-2xl"],
  {
    variants: {
      size: {
        sm: "max-w-md",
        md: "max-w-lg",
        lg: "max-w-2xl",
        xl: "max-w-4xl",
        "2xl": "max-w-6xl",
      },
    },
    defaultVariants: {
      size: "md",
    },
  },
);

export const Modal = ({
  children,
  isOpen,
  onOpenChange,
  size,
  isDismissable = true,
}: Pick<DialogProps, "children"> &
  Pick<ModalOverlayProps, "isOpen" | "onOpenChange" | "isDismissable"> &
  VariantProps<typeof modalStyles>) => {
  return (
    <ModalOverlay
      isDismissable={isDismissable}
      className={modalOverlayStyles}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
    >
      <ModalPrimitive className={modalStyles({ size })}>
        <Dialog className="flex flex-col outline-hidden">{children}</Dialog>
      </ModalPrimitive>
    </ModalOverlay>
  );
};

export const ModalHeader = ({
  title,
  subtitle,
  onClose,
}: {
  title: string;
  subtitle?: string;
  onClose?: () => void;
}) => (
  <div className="flex items-center justify-between border-b p-6 bg-neutral-100">
    <div className="flex flex-col gap-2 max-w-lg [&_p]:text-neutral-500">
      <Heading3>{title}</Heading3>
      {subtitle && <C2>{subtitle}</C2>}
    </div>
    {onClose && (
      <Button intent="tertiary" onPress={onClose}>
        <Close />
      </Button>
    )}
  </div>
);

export const ModalBody = ({ children }: { children: ReactNode }) => (
  <div className="bg-white p-6">{children}</div>
);

export const ModalFooter = ({ children }: { children: ReactNode }) => (
  <div className="flex items-center gap-4 justify-end border-t p-6">{children}</div>
);
