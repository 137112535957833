import { useMutation, useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { useGetProductRecipe, usePutProductRecipe } from "../api/endpoints/recipe";
import { RecipeItem } from "../api/types";
import { useActivePlant } from "./plants";

export const useProductRecipe = ({
  plantId,
  productId,
}: {
  plantId: string;
  productId: string;
}) => {
  const getProductRecipe = useGetProductRecipe();

  return useSuspenseQuery({
    queryKey: [plantId, "productRecipe", productId],
    queryFn: () =>
      getProductRecipe({
        productId,
        plantId,
      }),
  });
};

export const useUpdateProductRecipe = () => {
  const activePlant = useActivePlant();
  const queryClient = useQueryClient();
  const putProductRecipe = usePutProductRecipe();
  return useMutation({
    mutationFn: ({ recipe, productId }: { recipe: Omit<RecipeItem, "id">[]; productId: string }) =>
      putProductRecipe({
        productId,
        plantId: activePlant.id,
        recipe,
      }),
    onSuccess: (_, { productId }) => {
      queryClient.invalidateQueries({
        queryKey: [activePlant.id, "productRecipe", productId],
      });
      queryClient.invalidateQueries({
        queryKey: [activePlant.id, "products", productId],
      });
    },
  });
};
