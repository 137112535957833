import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useDeleteSilo, usePostSilo, usePutSilo } from "../api/endpoints/silos";
import { useActivePlant } from "./plants";

export const useAddSilo = () => {
  const activePlant = useActivePlant();
  const queryClient = useQueryClient();
  const postSilo = usePostSilo();

  return useMutation({
    mutationFn: (data: Omit<Parameters<typeof postSilo>[0]["data"], "plant_id">) =>
      postSilo({
        plantId: activePlant.id,
        data: {
          ...data,
          plant_id: activePlant.id,
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [activePlant.id, "materials"],
      });
    },
  });
};

export const useUpdateSilo = () => {
  const activePlant = useActivePlant();
  const queryClient = useQueryClient();
  const putSilo = usePutSilo();

  return useMutation({
    mutationFn: (data: Omit<Parameters<typeof putSilo>[0]["data"], "plant_id">) =>
      putSilo({
        plantId: activePlant.id,
        data: {
          ...data,
          plant_id: activePlant.id,
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [activePlant.id, "materials"],
      });
    },
  });
};

export const useRemoveSilo = () => {
  const activePlant = useActivePlant();
  const queryClient = useQueryClient();
  const deleteSilo = useDeleteSilo();

  return useMutation({
    mutationFn: (data: Omit<Parameters<typeof deleteSilo>[0], "plantId">) =>
      deleteSilo({
        ...data,
        plantId: activePlant.id,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [activePlant.id, "materials"],
      });
    },
  });
};
