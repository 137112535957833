import { ArrowDropDownOutlined, ArrowDropUpOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Fragment } from "react/jsx-runtime";
import { Impact } from "../../api/types";
import { Badge } from "../../components/Badge";
import { Label14, Text14 } from "../../components/TypographyOld";
import { useExtrasPerImpactName } from "../../lib/impact";
import { formatPrecision } from "../../util/format";

export const ImpactFactors = ({
  impacts,
  impacts2,
}: {
  impacts: Impact[];
  impacts2?: Impact[];
}) => {
  const { t } = useTranslation();
  const extrasPerImpactName = useExtrasPerImpactName();
  return (
    <>
      {impacts.map((impact, index) => {
        const impact2 = impacts2?.[index];
        const diff = impact2 ? impact.value - impact2.value : 0;
        const diffPercentage = impact2 ? (diff / impact2.value) * 100 : 0;
        const unitSplit = t(impact.unit).split(" ");
        const shortUnit = unitSplit[0].replace(",", "");
        const restUnit = unitSplit.slice(1).join(" ");
        const labelUnit = restUnit.length > 0 ? restUnit : t(impact.unit);

        const indicatorExtra =
          extrasPerImpactName[impact.indicator_name as keyof typeof extrasPerImpactName];

        return (
          <Fragment key={impact.indicator_name}>
            {impacts2 && (
              <Badge color="brown" size="sm" shrunkIcon>
                {diffPercentage > 0 ? <ArrowDropUpOutlined /> : <ArrowDropDownOutlined />}
                {Math.round(diffPercentage)}%
              </Badge>
            )}
            <div className="flex flex-col gap-1">
              <Text14>
                {indicatorExtra.niceName} ({indicatorExtra.indicatorName}) in {labelUnit}
              </Text14>
              <Label14 className="flex gap-2">
                {formatPrecision(impact.value, 4)} {shortUnit}
                {impact2 && (
                  <span className="text-neutral-400">
                    | {formatPrecision(impact2.value, 4)} {shortUnit}
                  </span>
                )}
              </Label14>
            </div>
          </Fragment>
        );
      })}
    </>
  );
};
