import { ImageOutlined, UploadFileOutlined } from "@mui/icons-material";
import { memo, useCallback, useMemo, useRef, useState } from "react";
import { Button as ButtonPrimitive, DialogTrigger, FileTrigger } from "react-aria-components";
import { useTranslation } from "react-i18next";
import ReactCrop, { Crop, centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import tw from "tailwind-styled-components";
import { useAuthenticatedImgSrc, useUploadImage } from "../../api/endpoints/media";
import { Product } from "../../api/types";
import { apiLink } from "../../api/useApi";
import { Button } from "../../components/Button";
import { Dropzone } from "../../components/Dropzone";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "../../components/Modal";
import { Text14 } from "../../components/TypographyOld";
import { useElementaries } from "../../state/elementaries";
import { useProductMetadata } from "../../state/productMetadata";
import { useUpdateProduct } from "../../state/products";
import { showErrorToast } from "../../util/toasts";
import { useValidateFileSize } from "../../util/validateFileSize";

const DEFAULT_CROP: Crop = {
  unit: "%",
  width: 90,
  height: 90,
  x: 5,
  y: 5,
};

function cropImageToBlob(image: HTMLImageElement, crop: Crop): Promise<Blob> {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  if (!ctx) {
    throw new Error("No 2d context");
  }

  // Convert percentage to pixels
  const cropX = Math.round((crop.x * image.naturalWidth) / 100);
  const cropY = Math.round((crop.y * image.naturalHeight) / 100);
  const cropWidth = Math.round((crop.width * image.naturalWidth) / 100);
  const cropHeight = Math.round((crop.height * image.naturalHeight) / 100);

  // Use the actual cropped dimensions for the canvas
  canvas.width = cropWidth;
  canvas.height = cropHeight;

  // Set white background
  ctx.fillStyle = "white";
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  // Draw the cropped portion at its original size
  ctx.drawImage(image, cropX, cropY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);

  return new Promise((resolve) => {
    canvas.toBlob(
      (blob) => {
        if (!blob) throw new Error("Failed to create blob");
        resolve(blob);
      },
      "image/jpeg",
      0.95,
    );
  });
}

function isCropValid(crop?: Crop): boolean {
  return Boolean(
    crop &&
      crop.width &&
      crop.height &&
      crop.x !== undefined &&
      crop.y !== undefined &&
      crop.width > 10 && // Ensure minimum size
      crop.height > 10,
  );
}

const ImageUploadModal = ({
  onClose,
  onConfirm,
}: {
  onClose: () => void;
  onConfirm: (file: File) => void;
}) => {
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const imgRef = useRef<HTMLImageElement | null>(null);
  const [crop, setCrop] = useState<Crop>(DEFAULT_CROP);
  const [completedCrop, setCompletedCrop] = useState<Crop>();

  const handleDrop = useCallback(
    (file: File) => {
      if (!file.type.startsWith("image/")) {
        showErrorToast(t("Please upload an image file."));
        return;
      }
      setSelectedFile(file);
      setCompletedCrop(undefined);
      const url = URL.createObjectURL(file);
      setPreviewUrl(url);
    },
    [t],
  );

  const resetUpload = useCallback(() => {
    if (previewUrl) {
      URL.revokeObjectURL(previewUrl);
    }
    setSelectedFile(null);
    setPreviewUrl(null);
    setCrop(DEFAULT_CROP);
    setCompletedCrop(undefined);
  }, [previewUrl]);

  const handleConfirm = useCallback(async () => {
    if (!selectedFile || !previewUrl || !imgRef.current || !completedCrop) return;

    try {
      const croppedBlob = await cropImageToBlob(imgRef.current, completedCrop);
      const croppedFile = new File([croppedBlob], selectedFile.name, {
        type: "image/jpeg",
      });
      onConfirm(croppedFile);
      onClose();
    } catch (error) {
      console.error("Error cropping image:", error);
      showErrorToast(t("Couldn't crop image."));
    }
  }, [selectedFile, previewUrl, completedCrop, onConfirm, onClose, t]);

  const onImageLoad = useCallback((e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget;
    const initialCrop = centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 90,
        },
        1,
        width,
        height,
      ),
      width,
      height,
    );
    setCrop(initialCrop);
    setCompletedCrop(initialCrop);
  }, []);

  return (
    <>
      <ModalHeader title={t("Upload new product image")} onClose={onClose} />
      <ModalBody>
        {!previewUrl ? (
          <FileTrigger
            onSelect={(files) => {
              if (files?.[0]) {
                handleDrop(files[0]);
              }
            }}
          >
            <ButtonPrimitive className="w-full h-full grid">
              <Dropzone onDrop={handleDrop}>
                <div className="text-center">
                  <p className="font-bold">{t("Drop your image here")}</p>
                  <p className="text-gray-500">{t("or click to select from your computer")}</p>
                </div>
              </Dropzone>
            </ButtonPrimitive>
          </FileTrigger>
        ) : (
          <div className="overflow-auto flex flex-col items-center">
            <ReactCrop
              className="max-h-[400px]"
              crop={crop}
              onChange={(_, percentCrop: Crop) => setCrop({ ...percentCrop, unit: "%" })}
              onComplete={(_, percentCrop: Crop) => setCompletedCrop({ ...percentCrop, unit: "%" })}
              aspect={1}
              circularCrop={false}
              minWidth={50}
            >
              <img
                ref={imgRef}
                src={previewUrl}
                alt="Preview"
                onLoad={onImageLoad}
                className="m-auto max-w-full h-full object-contain"
              />
            </ReactCrop>
            <div className="flex flex-col items-center mt-4">
              <Text14>
                {t("The product image is visible on your product page and on generated EPDs.")}
              </Text14>
              <Text14>{t("The ideal size is 2500px x 2500px")}</Text14>
            </div>
            <div className="mt-4 flex justify-center">
              <Button intent="secondary" onPress={resetUpload}>
                {t("Upload other product image")}
              </Button>
            </div>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button intent="secondary" onPress={onClose}>
          {t("Cancel")}
        </Button>
        <Button
          intent="primary"
          onPress={handleConfirm}
          isDisabled={!selectedFile || !isCropValid(completedCrop)}
        >
          {t("Confirm")}
        </Button>
      </ModalFooter>
    </>
  );
};

const SxButton = tw(ButtonPrimitive)`
  w-full
  h-full
  z-20
  absolute
  bg-neutral-900
  bg-opacity-40
  top-0
  left-0
  opacity-0
  group-hover:opacity-100
  transition-opacity
  cursor-pointer
  text-white
  flex
  items-center
  justify-center
`;

function useProductImage(product: Product) {
  const {
    data: { productMetadataMap },
  } = useProductMetadata();
  const { elementariesMap } = useElementaries();

  const defaultImageUrl = useMemo(() => {
    const elementary = elementariesMap[product.elementary_id];
    const metadata = productMetadataMap[elementary.product_metadata_id ?? ""];
    return apiLink(`/static${metadata.img_url}`);
  }, [product, elementariesMap, productMetadataMap]);

  const imageSrc = useAuthenticatedImgSrc({ imageUrl: product.image_url || undefined });

  return imageSrc || defaultImageUrl;
}

export const ProductImage = memo(function ProductImageComponent({
  product,
  disableCropping = false,
  disableUpload = false,
}: {
  product: Product;
  disableCropping?: boolean;
  disableUpload?: boolean;
}) {
  const { t } = useTranslation();
  const uploadImage = useUploadImage();
  const { mutate: updateProduct } = useUpdateProduct();
  const validateFileSize = useValidateFileSize();

  const imageSrc = useProductImage(product);

  const handleUpload = async (file: File) => {
    try {
      validateFileSize(file);
      const key = await uploadImage({
        file,
      });
      updateProduct({
        productId: product.id,
        product: { ...product, image_url: key },
      });
    } catch (_) {
      showErrorToast(t("Couldn't upload product image."));
    }
  };

  // Note: we're using the padding-trick to keep the image square, responsively
  return (
    <div className="shrink-0 p-[2px] bg-white relative group w-full pb-[100%]">
      {!imageSrc ? (
        <div className="absolute left-0 top-0 w-full h-full flex items-center justify-center">
          <ImageOutlined className="text-neutral-300" fontSize="large" />
        </div>
      ) : (
        <img
          className="w-full h-full absolute left-0 top-0 z-10 object-cover select-none pointer-events-none"
          src={imageSrc}
          alt="Product"
        />
      )}
      {!disableUpload &&
        (disableCropping ? (
          <FileTrigger
            onSelect={(files) => {
              if (!files?.[0]) return;
              handleUpload(files[0]);
            }}
          >
            <SxButton>
              <UploadFileOutlined fontSize="large" />
            </SxButton>
          </FileTrigger>
        ) : (
          <DialogTrigger>
            <SxButton>
              <UploadFileOutlined fontSize="large" />
            </SxButton>
            <Modal size="xl">
              {({ close }) => <ImageUploadModal onClose={close} onConfirm={handleUpload} />}
            </Modal>
          </DialogTrigger>
        ))}
    </div>
  );
});
