import {
  Add,
  ClearOutlined,
  GroupWorkOutlined,
  HomeWorkOutlined,
  LinkOutlined,
} from "@mui/icons-material";
import { TFunction } from "i18next";
import { Fragment, useMemo, useState } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Plant, PlantMaterial, ProductDetail } from "../../api/types";
import { Button } from "../../components/Button";
import { Card } from "../../components/Card";
import { ComboBoxField } from "../../components/ComboBoxField";
import { Label } from "../../components/TypographyOld";
import { ComboBoxFieldConnected } from "../../form-components/ComboBoxFieldConnected";
import { NumberFieldConnected } from "../../form-components/NumberFieldConnected";
import { usePlants } from "../../state/plants";
import { exists } from "../../util/commonUtil";
import { ProductRecipeForm, ProductRecipeMaterialOption } from "./types";

const getMaterialId = (material: PlantMaterial): string | undefined => {
  return material.prechain_product?.id || material.supplier_product?.id || material.silo?.id;
};

const getSupplierName = (material: PlantMaterial, plants: Plant[]): string | undefined => {
  return (
    material.silo?.name ||
    material.supplier_product?.supplier_plant?.supplier_company?.name ||
    plants.find((p) => p.id === material.prechain_product?.plant_id)?.name
  );
};

const getProductName = (material: PlantMaterial): string | undefined => {
  return material.prechain_product?.product?.name || material.supplier_product?.name;
};

const getMaterialIcon = (material: PlantMaterial): JSX.Element | undefined => {
  if (material.silo) return <GroupWorkOutlined />;
  const hasPrimaryData = !!material.supplier_product?.epd_id || !!material.prechain_product;
  if (!hasPrimaryData) return undefined;
  return material.prechain_product ? <HomeWorkOutlined /> : <LinkOutlined />;
};

const getIsSiloWithMissingYearData = (
  material: PlantMaterial,
  selectedProduct: ProductDetail,
): boolean => {
  if (!material.silo) return false;

  const allItems = material.silo?.items.flatMap((item) => item.annual_items);
  const groupedByYear = Object.groupBy(allItems, (item) => item.year);
  const maxItems = Math.max(
    ...Object.entries(groupedByYear).map(([, items]) => items?.length ?? 0),
  );

  const currentYear = selectedProduct.year_under_review;
  const hasMissingYearData = (groupedByYear[currentYear]?.length || 0) < maxItems;

  const siloIsEmptyForYear = (groupedByYear[currentYear] || []).every((x) => x.amount === 0);

  return hasMissingYearData || siloIsEmptyForYear;
};

const createMaterialOption = (
  t: TFunction,
  material: PlantMaterial,
  plants: Plant[],
  selectedProduct: ProductDetail,
): { id: string; icon?: JSX.Element; label: string; isDisabled: boolean } | undefined => {
  const id = getMaterialId(material);
  if (!id) return undefined;

  const supplierName = getSupplierName(material, plants);
  const productName = getProductName(material);
  const icon = getMaterialIcon(material);
  const isSiloWithMissingYearData = getIsSiloWithMissingYearData(material, selectedProduct);

  return {
    id,
    icon,
    label: [supplierName, productName]
      .filter(exists)
      .join(" – ")
      .concat(isSiloWithMissingYearData ? t(" (specify year to select)") : ""),
    isDisabled: isSiloWithMissingYearData,
  };
};

export const ProductRecipeFields = ({
  selectedProduct,
  materialOptions,
  materials,
}: {
  selectedProduct: ProductDetail;
  materialOptions: ProductRecipeMaterialOption[];
  materials: PlantMaterial[];
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext<ProductRecipeForm>();
  const { data: plants } = usePlants();
  const { fields, append, remove } = useFieldArray<ProductRecipeForm>({
    control,
    name: "materials",
  });

  const [adding, setAdding] = useState(false);

  const formMaterials = useWatch({ control, name: "materials" });

  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...formMaterials[index],
    };
  });

  const availableMaterialOptions = useMemo(() => {
    const unusedSupplierMaterials = materials.filter(
      (mat) =>
        !formMaterials.some((material) => {
          const id = material.input;

          return (
            (mat.silo && id === mat.silo.id) ||
            (mat.prechain_product && id === mat.prechain_product.id) ||
            (mat.supplier_product && id === mat.supplier_product.id)
          );
        }),
    );

    return materialOptions
      .filter((materialOption) =>
        unusedSupplierMaterials.some(
          (m) =>
            m.silo?.items.some(
              (item) => item.supplier_product?.elementary_id === materialOption.elementaryId,
            ) ||
            m.supplier_product?.elementary_id === materialOption.elementaryId ||
            m.prechain_product?.product?.elementary_id === materialOption.elementaryId,
        ),
      )
      .map((option) => ({
        id: option.elementaryId,
        label: option.label,
        isDisabled: !option.isAllowedInCategory,
      }));
  }, [materialOptions, materials, formMaterials]);

  return (
    <div className="grow overflow-y-auto py-2">
      <Card
        footerSection={
          <>
            {!adding && availableMaterialOptions.length > 0 && (
              <Button intent="tertiary" onPress={() => setAdding(true)}>
                <Add />
                {t("Add Ingredient")}
              </Button>
            )}
            {adding && (
              <ComboBoxField
                onChange={(_, opt) => {
                  if (!opt) return;
                  const option = materialOptions.find((o) => o.elementaryId === opt.id);
                  if (option) {
                    append({ ...option, input: null, value: null });
                    setAdding(false);
                  }
                }}
                value={null}
                disablePortal
                options={availableMaterialOptions}
                label={t("Material")}
              />
            )}
          </>
        }
      >
        {controlledFields.length === 0 && (
          <Label className="text-center pt-6">{t("No ingredients added yet")}</Label>
        )}
        <div className="py-3 grid grid-cols-[1fr_1fr_auto] gap-6">
          {controlledFields.map((opt, idx) => {
            const rawMaterialOptions = materials
              .filter(
                (material) =>
                  material.prechain_product?.product?.elementary_id === opt.elementaryId ||
                  material.supplier_product?.elementary_id === opt.elementaryId ||
                  material.silo?.items.some(
                    (item) => item.supplier_product?.elementary_id === opt.elementaryId,
                  ),
              )
              .filter((material) => {
                const id = opt.input;

                // Check if the material is linked to this field or unused
                return (
                  material.supplier_product?.id === id ||
                  material.prechain_product?.id === id ||
                  !controlledFields.some((field) => {
                    const id = field.input;
                    return (
                      id === material.supplier_product?.id || id === material.prechain_product?.id
                    );
                  })
                );
              })
              .map((material) => createMaterialOption(t, material, plants, selectedProduct))
              .filter(exists);

            return (
              <Fragment key={opt.id}>
                <NumberFieldConnected<ProductRecipeForm>
                  key={opt.id}
                  name={`materials.${idx}.value`}
                  inputProps={{ addonRight: opt.unit }}
                  label={opt.label}
                />
                <ComboBoxFieldConnected
                  name={`materials.${idx}.input`}
                  options={rawMaterialOptions}
                  label={t("Linked Supplier Material")}
                />
                <Button intent="secondary" danger onPress={() => remove(idx)} square>
                  <ClearOutlined />
                </Button>
              </Fragment>
            );
          })}
        </div>
      </Card>
    </div>
  );
};
