import { ajvResolver } from "@hookform/resolvers/ajv";
import { isAxiosError } from "axios";
import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router";
import { DynamicFormJSONSchema, ProductDetail } from "../../api/types";
import { Heading3, Text16 } from "../../components/TypographyOld";
import { JSONSchemaFieldConnected } from "../../form-components/JSONSchemaFieldConnected";
import { NumberFieldConnected } from "../../form-components/NumberFieldConnected";
import { useElementaries } from "../../state/elementaries";
import {
  HARD_CODED_AUTOMATIC_DOOR_ELEMENTARY_ID,
  HARD_CODED_BITUMEN_MEMBRANE_ELEMENTARY_ID,
  HARD_CODED_CABLE_TRAY_ELEMENTARY_ID,
  HARD_CODED_CONCRETE_ELEMENTARY_ID,
  HARD_CODED_DRAINAGE_CHANNEL_ELEMENTARY_ID,
  HARD_CODED_ELASTOMER_MEMBRANE_ELEMENTARY_ID,
  HARD_CODED_PARTITION_SYSTEM_ELEMENTARY_ID,
  HARD_CODED_PRECAST_CATEGORY_ID,
  HARDCODED_ELEMENTARIES_WITHOUT_RECIPE,
} from "../../state/HARD_CODED";
import { useProduct, useSelectedProductId, useUpdateProduct } from "../../state/products";
import { useGetLinkWithParams } from "../../url/useGetLinkWithParams";
import { exists } from "../../util/commonUtil";
import { showErrorToast } from "../../util/toasts";
import { useWarnBeforeUnload } from "../../util/useWarnBeforeUnload";
import { EditFlowNav } from "./EditFlowNav";
import { PartitionSystemFields, PartitionSystemScenario } from "./PartitionSystemScenario";
import { AutomaticDoorFields, AutomaticDoorScenario } from "./ProductSpecsAutomaticDoorScenario";
import { CableTrayInstallationFields, CableTrayScenario } from "./ProductSpecsCableTrayScenario";
import { ConcreteApplicationField, ConcreteScenario } from "./ProductSpecsConcreteScenario";
import {
  DrainageChannelScenario,
  DraingeChannelInstallationFields,
} from "./ProductSpecsDraingeChannelScenario";
import {
  BitumenMembraneFields,
  BitumenMembraneScenario,
  ElastomerMembraneFields,
  ElastomerMembraneScenario,
} from "./ProductSpecsMembraneScenario";
type Scenarios =
  | DrainageChannelScenario
  | ConcreteScenario
  | CableTrayScenario
  | BitumenMembraneScenario
  | ElastomerMembraneScenario
  | AutomaticDoorScenario
  | PartitionSystemScenario
  | null;

interface ProductUsageForm {
  transportDistance: number;
  scenario: Scenarios;
}

const ProductSpecsMain = ({ id }: { id: string }) => {
  const { data: selectedProduct } = useProduct(id);

  return <ProductSpecsForm selectedProduct={selectedProduct} />;
};

export const ProductSpecs = () => {
  const id = useSelectedProductId();

  if (!id) return null;

  return <ProductSpecsMain id={id} />;
};

const ProductSpecsForm = ({ selectedProduct }: { selectedProduct: ProductDetail }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const getLinkWithParams = useGetLinkWithParams();
  const { elementariesMap } = useElementaries();
  const onPrev = () => navigate(getLinkWithParams("/edit/product-details"));

  const { mutate: updateProduct, isPending: loading } = useUpdateProduct();
  const [searchParams] = useSearchParams();

  const isConcrete = useMemo(() => {
    return (
      selectedProduct.elementary_id === HARD_CODED_CONCRETE_ELEMENTARY_ID ||
      elementariesMap[selectedProduct.elementary_id]?.product_category_id ===
        HARD_CODED_PRECAST_CATEGORY_ID
    );
  }, [selectedProduct.elementary_id, elementariesMap]);

  const isDrainageChannel =
    selectedProduct.elementary_id === HARD_CODED_DRAINAGE_CHANNEL_ELEMENTARY_ID;

  const isCableTray = selectedProduct.elementary_id === HARD_CODED_CABLE_TRAY_ELEMENTARY_ID;

  const isBitumenMembrane =
    selectedProduct.elementary_id === HARD_CODED_BITUMEN_MEMBRANE_ELEMENTARY_ID;

  const isElastomerMembrane =
    selectedProduct.elementary_id === HARD_CODED_ELASTOMER_MEMBRANE_ELEMENTARY_ID;

  const isAutomaticDoor = selectedProduct.elementary_id === HARD_CODED_AUTOMATIC_DOOR_ELEMENTARY_ID;

  const isPartitionSystem =
    selectedProduct.elementary_id === HARD_CODED_PARTITION_SYSTEM_ELEMENTARY_ID;

  const hasScenario =
    isConcrete || isBitumenMembrane || isElastomerMembrane || isAutomaticDoor || isPartitionSystem;

  const methods1 = useForm<Record<string, unknown>>({
    // @ts-expect-error TODO: Try and fix https://ajv.js.org/guide/typescript.html#utility-types-for-schemas
    resolver: ajvResolver(selectedProduct.tech_specs_schema, {
      strict: false,
    }),
  });
  const reset = methods1.reset;

  const methods2 = useForm<ProductUsageForm>({
    defaultValues: selectedProduct
      ? {
          transportDistance: selectedProduct.distance_a4_km ?? NaN,
          scenario: hasScenario ? selectedProduct.scenario : null,
        }
      : undefined,
  });

  useWarnBeforeUnload(methods1.formState.isDirty || methods2.formState.isDirty);

  const onSubmit = async () => {
    if (!selectedProduct) return;

    [methods1, methods2].forEach((method) => method.clearErrors());

    const formsValid = (await Promise.all([methods1.trigger(), methods2.trigger()])).every(Boolean);

    if (!formsValid) return;

    updateProduct(
      {
        productId: selectedProduct.id,
        product: {
          ...selectedProduct,
          tech_specs: Object.fromEntries(
            Object.entries(methods1.getValues()).filter(([, value]) => exists(value)),
          ),
          distance_a4_km: selectedProduct.metadata.declared_modules.a4
            ? methods2.getValues("transportDistance")
            : undefined,
          scenario: hasScenario ? methods2.getValues("scenario") : undefined,
          production_process_id: selectedProduct.production_process_id,
        },
      },
      {
        onSuccess: () => {
          if (HARDCODED_ELEMENTARIES_WITHOUT_RECIPE.includes(selectedProduct.elementary_id)) {
            navigate({
              pathname: "/edit/product-production-process",
              search: searchParams.toString(),
            });
          } else {
            navigate({
              pathname: "/edit/product-recipe",
              search: searchParams.toString(),
            });
          }
        },
        onError: (error) => {
          if (isAxiosError(error) && error.response?.data?.message) {
            // Apparently we have a technical error message from the server here
            // that's user-friendly enough to display it
            showErrorToast(error.response?.data?.message);
          } else {
            console.error(error);
          }
        },
      },
    );
  };

  useEffect(
    function populateForm() {
      if (!selectedProduct || !selectedProduct.tech_specs) return;

      reset(selectedProduct.tech_specs);
    },
    [selectedProduct, reset],
  );

  return (
    <div className="flex flex-col h-full pb-20">
      <div className="grow">
        <div className="flex flex-col gap-8">
          <FormProvider {...methods1}>
            <form noValidate>
              <TechSpecsFields schema={selectedProduct.tech_specs_schema} />
            </form>
          </FormProvider>

          {selectedProduct.metadata.declared_modules.a4 && (
            <div className="mx-auto w-full max-w-6xl space-y-2">
              <div className="flex flex-col gap-2">
                <Heading3>{t("Product usage")}</Heading3>
                <Text16>
                  {t(
                    "We use the following information to build scenarios for your product's environmental data after leaving the factory gate.",
                  )}
                </Text16>
              </div>
              <div className="grow">
                <FormProvider {...methods2}>
                  <form noValidate className="grid xl:grid-cols-3 grid-cols-2 gap-6">
                    {isDrainageChannel && <DraingeChannelInstallationFields />}
                    {isCableTray && <CableTrayInstallationFields />}
                    {isConcrete && <ConcreteApplicationField />}
                    {isBitumenMembrane && <BitumenMembraneFields />}
                    {isElastomerMembrane && <ElastomerMembraneFields />}
                    <NumberFieldConnected
                      name="transportDistance"
                      minValue={0}
                      label={t("Average Distance to Customer")}
                      isRequired
                      inputProps={{
                        addonRight: "km",
                      }}
                    />
                    {isAutomaticDoor && <AutomaticDoorFields />}
                    {isPartitionSystem && <PartitionSystemFields />}
                  </form>
                </FormProvider>
              </div>
            </div>
          )}
        </div>
      </div>
      <EditFlowNav onPrev={onPrev} onNext={onSubmit} nextSubmit={false} nextLoading={loading} />
    </div>
  );
};

const TechSpecsFields = ({ schema }: { schema: DynamicFormJSONSchema }) => {
  return (
    <div className="grow flex flex-col gap-10 py-2 mx-auto w-full max-w-6xl">
      <div className="grid xl:grid-cols-3 grid-cols-2 gap-6">
        {Object.entries(schema.properties).map(([fieldname, fieldConfig]) => (
          <JSONSchemaFieldConnected
            key={fieldname}
            schema={schema}
            fieldName={fieldname}
            fieldConfig={fieldConfig}
          />
        ))}
      </div>
    </div>
  );
};
