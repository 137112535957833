import { useCallback } from "react";
import { ProductWithStatus } from "../../api/types";
import { useGetLinkWithParams } from "../../url/useGetLinkWithParams";

export const useIncompleteProductLink = () => {
  const getLinkWithParams = useGetLinkWithParams();

  return useCallback(
    (product: ProductWithStatus) => {
      return getLinkWithParams("/edit/product-details", { selectedProduct: product.id });
    },
    [getLinkWithParams],
  );
};
