import { ComponentProps, forwardRef, HTMLProps } from "react";
import { TextField as TextFieldPrimitive, type TextFieldProps } from "react-aria-components";

import { exists } from "../util/commonUtil";
import { FieldLabel, movingLabelStyles } from "./FieldLabel";
import { FieldText } from "./FieldText";
import { TextArea } from "./TextArea";

export const TextAreaField = forwardRef<
  HTMLTextAreaElement,
  Omit<TextFieldProps, "children" | "className"> & {
    label?: string;
    labelProps?: ComponentProps<typeof FieldLabel>;
    errorMessage?: string;
    inputProps?: Omit<HTMLProps<HTMLTextAreaElement>, "children" | "className">;
    placeholder?: string;
    isRequired?: boolean;
  }
>(({ label, errorMessage, inputProps, labelProps, placeholder, ...props }, ref) => {
  const _placeholder = exists(label) ? " " : placeholder;

  return (
    <TextFieldPrimitive {...props}>
      <div className={movingLabelStyles}>
        {label && (
          <FieldLabel isRequired={props.isRequired} isDisabled={props.isDisabled} {...labelProps}>
            {label}
          </FieldLabel>
        )}
        <TextArea
          ref={ref}
          {...inputProps}
          isDisabled={props.isDisabled}
          hasError={!!errorMessage}
          hasLabel={!!label}
          placeholder={_placeholder}
        />
      </div>
      {errorMessage && <FieldText intent="error">{errorMessage}</FieldText>}
    </TextFieldPrimitive>
  );
});
