import { useTranslation } from "react-i18next";
import { ProductDetail } from "../../api/types";
import { Label12, Text14 } from "../../components/TypographyOld";
import { coreIndicators, filterImpact } from "../../lib/impact";
import { useProductImpactScale } from "../../state/products";
import { ImpactFactors } from "../product-detail/ImpactFactors";
import { RadarChart } from "../product-detail/RadarChart";

export const ProductComparisonEnvironmentalPerformanceIndicators = ({
  product1,
  product2,
}: {
  product1: ProductDetail;
  product2: ProductDetail;
}) => {
  const { t } = useTranslation();

  const { data: endsOfScale = [] } = useProductImpactScale({
    plantId: product1.plant_id,
    id: product1.id,
  });
  const filteredImpacts = product1.lca_results?.impact_summary.a1_a3
    ? filterImpact(product1.lca_results.impact_summary.a1_a3, coreIndicators)
    : [];

  const filteredImpacts2 = product2.lca_results?.impact_summary.a1_a3
    ? filterImpact(product2.lca_results.impact_summary.a1_a3, coreIndicators)
    : [];

  return (
    <div className="flex flex-col gap-5">
      <Label12>Environmental performance indicators</Label12>
      <hr className="border-neutral-300" />
      <div className="grid grid-cols-1 md:grid-cols-[1fr_1.681fr_1fr] gap-8">
        <Text14>
          <div
            dangerouslySetInnerHTML={{
              __html: t(
                "While GWP assesses the contribution of a product or process to climate change by measuring the greenhouse gases it emits, other significant environmental impacts may be overlooked if only GWP is considered.<br /><br />A product with low GWP might still <strong>cause significant harm</strong> for <strong>overall planetary health</strong>, like water depletion and soil accidification.<br /><br />By assessing multiple indicators,  gain a <strong>holistic understanding</strong> of a product's overall environmental footprint, avoid unintended trade-offs, and support better decision-making for long-term sustainability.<br /><br />The diagram covers the <strong>A1-A3 stages</strong> of the lifecycle.",
              ),
            }}
          />
        </Text14>
        <div className="h-[500px]">
          <RadarChart
            product={product1}
            product2={product2}
            impacts={filteredImpacts}
            impacts2={filteredImpacts2}
            endsOfScale={endsOfScale}
          />
        </div>
        <div className="flex items-center">
          <div className="grid grid-cols-[auto_1fr] gap-6">
            <ImpactFactors impacts={filteredImpacts} impacts2={filteredImpacts2} />
          </div>
        </div>
      </div>
    </div>
  );
};
