import { useMutation, useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { saveAs } from "file-saver";
import { useEffect, useMemo, useState } from "react";
import {
  useGetMaterial,
  useGetMaterialsBulkUploadSheet,
  useGetPlantMaterials,
  usePostMaterials,
  usePostMaterialsBulkUpload,
  usePutMaterial,
} from "../api/endpoints/materials";
import { BulkUploadResult, SupplierMaterial } from "../api/types";
import { showErrorToast } from "../util/toasts";
import { useActivePlant } from "./plants";

export const useMaterial = (id?: string) => {
  const activePlant = useActivePlant();
  const getMaterial = useGetMaterial();

  const [data, setData] = useState<SupplierMaterial | null>(null);

  useEffect(() => {
    if (!id) return;

    getMaterial({
      plantId: activePlant.id,
      materialId: id,
    }).then(setData);
  }, [activePlant, getMaterial, id]);

  return data;
};

export const useMaterials = () => {
  const activePlant = useActivePlant();

  const getMaterials = useGetPlantMaterials();

  const { data } = useSuspenseQuery({
    queryKey: [activePlant.id, "materials"],
    queryFn: () => {
      return Promise.all([
        getMaterials({
          plantId: activePlant.id || "",
          type: "raw_materials",
        }),
        getMaterials({
          plantId: activePlant.id || "",
          type: "packaging",
        }),
      ]);
    },
    select: ([rawMaterials, packagingMaterials]) => {
      return { rawMaterials, packagingMaterials };
    },
  });

  return data;
};

export const useAddMaterial = () => {
  const queryClient = useQueryClient();
  const activePlant = useActivePlant();
  const postMaterials = usePostMaterials();

  return useMutation({
    mutationFn: ({ material }: { material: Parameters<typeof postMaterials>[0]["material"] }) =>
      postMaterials({
        plantId: activePlant.id,
        material,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [activePlant.id, "materials"],
        exact: true,
      });
    },
  });
};

export const useUpdateMaterial = () => {
  const queryClient = useQueryClient();
  const activePlant = useActivePlant();
  const putMaterial = usePutMaterial();

  return useMutation({
    mutationFn: ({ material }: { material: Parameters<typeof putMaterial>[0]["material"] }) =>
      putMaterial({
        plantId: activePlant.id,
        material,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [activePlant.id, "materials"],
        exact: true,
      });
    },
  });
};

export const usePrechainProducts = () => {
  const { rawMaterials } = useMaterials();

  return useMemo(
    () => rawMaterials.filter((x) => x.prechain_product).map((x) => x.prechain_product),
    [rawMaterials],
  );
};

export const useDownloadMaterialsBulkUploadSheet = () => {
  const activePlant = useActivePlant();
  const getMaterialsBulkUploadSheet = useGetMaterialsBulkUploadSheet();

  return useMutation({
    mutationFn: async () => {
      const response = await getMaterialsBulkUploadSheet({ plantId: activePlant.id });
      saveAs(response, "Material-Bulk-Upload.xlsx");
    },
  });
};

export const useMaterialBulkUpload = ({
  onSuccess,
}: {
  onSuccess: (result: BulkUploadResult) => void;
}) => {
  const queryClient = useQueryClient();
  const activePlant = useActivePlant();
  const bulkUploadMaterials = usePostMaterialsBulkUpload();

  return useMutation({
    mutationFn: (file: File) => {
      return bulkUploadMaterials({
        plantId: activePlant.id,
        file,
      });
    },
    onSuccess: (result) => {
      queryClient.invalidateQueries({
        queryKey: [activePlant.id, "materials"],
        exact: true,
      });
      queryClient.invalidateQueries({
        queryKey: [activePlant.id, "suppliers"],
        exact: true,
      });
      onSuccess(result);
    },
    onError: () => {
      showErrorToast("Something went wrong. Make sure to download an up-to-date template.");
    },
  });
};
