import { ClearOutlined, WindowOutlined } from "@mui/icons-material";
import { Suspense, useMemo } from "react";
import { DialogTrigger } from "react-aria-components";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router";
import { ProductDetail } from "../../api/types";
import { Button } from "../../components/Button";
import { Link } from "../../components/Link";
import { Loading } from "../../components/Loading";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "../../components/Modal";
import { PageContainer } from "../../components/PageContainer";
import { ProgressBar } from "../../components/ProgressBar";
import { TopBar } from "../../components/TopBar";
import { Heading3, Label } from "../../components/TypographyOld";
import { isLink, useAllPages } from "../../config/useNavbarItems";
import { HARDCODED_ELEMENTARIES_WITHOUT_RECIPE } from "../../state/HARD_CODED";
import { useProduct, useRemoveProducts, useSelectedProductId } from "../../state/products";

// To be adjusted if there's a change to the pages or to the navigation architecture
const MAX_STEPS = 4;

const EditBase = ({ selectedProduct }: { selectedProduct?: ProductDetail }) => {
  const { t } = useTranslation();

  const activePath = useLocation().pathname;
  const navbarItems = Object.values(useAllPages());
  const activeItem = useMemo(
    () => navbarItems.filter(isLink).find((item) => item.path === activePath),
    [activePath, navbarItems],
  );
  const totalSteps = useMemo(
    // For some product categories, there is no "create recipe" step
    () =>
      selectedProduct &&
      HARDCODED_ELEMENTARIES_WITHOUT_RECIPE.includes(selectedProduct.elementary_id)
        ? MAX_STEPS - 1
        : MAX_STEPS,
    [selectedProduct],
  );

  const navigate = useNavigate();
  const { mutateAsync: removeProducts } = useRemoveProducts();

  const canDeleteDraft = selectedProduct && selectedProduct.status === "incomplete";

  const onDeleteDraft = () => {
    if (canDeleteDraft) {
      removeProducts([
        {
          plantId: selectedProduct.plant_id,
          productId: selectedProduct.id,
        },
      ]).then(() => navigate("/products"));
    } else {
      navigate("/products");
    }
  };

  return (
    <PageContainer>
      <TopBar
        icon={<WindowOutlined />}
        title={selectedProduct ? t("Edit Product") : t("New Product")}
        centerSection={
          activeItem?.step ? (
            <div className="w-[300px]">
              <ProgressBar step={activeItem.step} total={totalSteps} />
            </div>
          ) : undefined
        }
        input={
          canDeleteDraft ? (
            <DialogTrigger>
              <Button intent="tertiary">
                {t("Cancel process")}
                <ClearOutlined />
              </Button>
              <Modal>
                {({ close }) => (
                  <>
                    <ModalHeader title={t("Leave without saving?")} onClose={close} />
                    <ModalBody>
                      <p>{t("You're about to leave without saving this step.")}</p>
                      <p className="mt-2">
                        {t(
                          "A draft from your previous step has been saved. Would you like to keep the draft?",
                        )}
                      </p>
                    </ModalBody>
                    <ModalFooter>
                      <Button danger intent="secondary" onPress={onDeleteDraft}>
                        {t("Leave and delete draft")}
                      </Button>
                      <Button intent="secondary" onPress={() => navigate("/products")}>
                        {t("Leave, keep draft")}
                      </Button>
                    </ModalFooter>
                  </>
                )}
              </Modal>
            </DialogTrigger>
          ) : (
            <Link intent="tertiary" href="/products">
              {t("Cancel process")}
              <ClearOutlined />
            </Link>
          )
        }
      />
      <div
        className="grow flex flex-col gap-5 py-8 overflow-hidden"
        style={{ viewTransitionName: `product-${selectedProduct?.id}` }}
      >
        <div className="grow flex flex-col gap-8 overflow-hidden">
          <div className="space-y-1">
            {activeItem?.step && (
              <Label className="text-center">
                {t("Step {{ step }} / {{ total }}", {
                  step: Math.min(activeItem.step, totalSteps),
                  total: totalSteps,
                })}
              </Label>
            )}
            <Heading3 className="text-center">{activeItem?.name}</Heading3>
          </div>
          <Suspense fallback={<Loading lazy />}>
            <Outlet />
          </Suspense>
        </div>
      </div>
    </PageContainer>
  );
};

const EditExisting = ({ id }: { id: string }) => {
  const { data: selectedProduct } = useProduct(id);

  return <EditBase selectedProduct={selectedProduct} />;
};

export const Edit = () => {
  const id = useSelectedProductId();

  return id ? <EditExisting id={id} /> : <EditBase />;
};
