import { useTranslation } from "react-i18next";
import { EndOfScale, ProductDetail } from "../../api/types";
import { Loading } from "../../components/Loading";
import { Label16, Text14 } from "../../components/TypographyOld";
import { BreakdownPerStage } from "./BreakdownPerStage";
import { EnvironmentalPerformanceIndicators } from "./EnvironmentalPerformanceIndicators";
import { ProductImage } from "./ProductImage";
import { ProductStats } from "./ProductStats";
import { ProductSummary } from "./ProductSummary";

export const ProductDetailPagePassport = ({
  product,
  endsOfScale,
  isUpdating,
}: {
  product: ProductDetail;
  endsOfScale: EndOfScale[];
  isUpdating?: boolean;
}) => {
  const { t } = useTranslation();

  const isProductLocked = product.status === "completed" || product.status === "under-review";

  return (
    <div className="flex flex-col pb-8 gap-8 grow">
      <div className="flex flex-col gap-8 grow">
        <div className="-mx-6 px-6 pt-8 pb-4 bg-concrete bg-texture grid grid-cols-[auto_1fr] gap-6">
          <div className="w-[200px] h-[200px]">
            <ProductImage product={product} disableUpload={isProductLocked} disableCropping />
          </div>
          <div className="flex flex-col">
            <ProductSummary product={product} />

            <hr className="border-t mt-6 mb-4 border-neutral-300" />
            {product.lca_results && (
              <ProductStats lciaResults={product.lca_results} baseUnit={product.unit} />
            )}
            {!product.lca_results && isUpdating && (
              <div className="grid grid-cols-[auto_1fr] items-center gap-4 py-3">
                <div>
                  <Loading />
                </div>
                <div className="flex flex-col">
                  <Label16>{t("LCA-calculation")}</Label16>
                  <Text14>{t("This may take a moment.")}</Text14>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-8 grow">
          {product.lca_results && (
            <>
              <EnvironmentalPerformanceIndicators
                impactSummary={product.lca_results.impact_summary}
                endsOfScale={endsOfScale}
                product={product}
              />
              <BreakdownPerStage lcaResults={product.lca_results} endsOfScale={endsOfScale} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
