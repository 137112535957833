import tw from "tailwind-styled-components";

export const Heading1 = tw("h1")`
  text-7xl
  font-bold
  text-builtgreen
`;

export const Heading2 = tw("h2")`
  text-[40px] leading-[40px]
  font-bold
  text-builtgreen
`;

export const Heading3 = tw("h3")`
  text-[32px] leading-[40px]
  font-bold
  text-builtgreen
`;

export const Heading4 = tw("h4")`
  text-[24px] leading-[32px]
  font-bold
  text-builtgreen
`;

export const Heading5 = tw("h5")`
  text-[20px] leading-[28px]
  font-bold
  text-builtgreen
`;

export const C1 = tw("p")<{ $bold?: boolean; $muted?: boolean }>`
  text-base
  ${({ $muted }) => ($muted ? "text-neutral-500" : "text-builtgreen")}
  ${({ $bold }) => $bold && "font-bold"}
`;

export const C2 = tw("p")<{ $bold?: boolean; $muted?: boolean }>`
  text-sm
  ${({ $muted }) => ($muted ? "text-neutral-500" : "text-builtgreen")}
  ${({ $bold }) => $bold && "font-bold"}
`;

export const C3 = tw("p")<{ $bold?: boolean; $muted?: boolean }>`
  text-xs
  ${({ $muted }) => ($muted ? "text-neutral-500" : "text-builtgreen")}
  ${({ $bold }) => $bold && "font-bold"}
`;
