import { ResponsiveRadar } from "@nivo/radar";
import { useTranslation } from "react-i18next";
import { EndOfScale, Impact, Product } from "../../api/types";
import { tooltipStyles } from "../../components/Tooltip";
import { Text14 } from "../../components/TypographyOld";
import { useExtrasPerImpactName } from "../../lib/impact";
import { formatPrecision } from "../../util/format";

export const RadarChart = ({
  impacts,
  impacts2,
  product,
  product2,
  endsOfScale,
}: {
  impacts: Impact[];
  impacts2?: Impact[];
  product: Product;
  product2?: Product;
  endsOfScale: EndOfScale[];
}) => {
  const { t } = useTranslation();

  const styles = getComputedStyle(document.documentElement);
  const yellow300 = styles.getPropertyValue("--color-energyyellow");
  const neutral500 = styles.getPropertyValue("--color-neutral-500");
  const neutral300 = styles.getPropertyValue("--color-neutral-300");
  const co2brown = styles.getPropertyValue("--color-co2brown");

  const extrasPerImpactName = useExtrasPerImpactName();

  const bound = (value: number) => Math.max(Math.min(value, 1), 0);

  const normalizedImpacts = impacts.map((impact, index) => {
    const maxValue = endsOfScale.find(
      ({ indicator_name }) => impact.indicator_name === indicator_name,
    )?.value;

    const impact2 = impacts2?.[index];

    return {
      ...impact,
      niceName:
        extrasPerImpactName[impact.indicator_name as keyof typeof extrasPerImpactName].niceName,
      impact2: maxValue && impact2 ? bound(impact2.value / maxValue) : undefined,
      impact1: maxValue ? bound(impact.value / maxValue) : 0,
    };
  });

  // We're seeing console.errors when the animation runs.
  // A solution is to set animate={false}, but then we'd lose the animation
  // so since the chart isn't crashing, we're leaving this as is for now
  // TODO: double-check this issue and see if it's been resolved:
  // https://github.com/plouc/nivo/issues/2111

  return (
    <ResponsiveRadar
      data={normalizedImpacts}
      keys={[...(impacts2 ? ["impact2"] : []), "impact1"]}
      indexBy="indicator_name"
      gridShape="linear"
      fillOpacity={impacts2 ? 0.5 : 0.7}
      dotBorderWidth={1}
      dotBorderColor={neutral500}
      dotSize={8}
      dotColor={{ from: "color", modifiers: [["brighter", 0.2]] }}
      valueFormat=">-.3e"
      maxValue={1}
      sliceTooltip={({ index }) => {
        const impact = impacts.find((i) => i.indicator_name === index);
        const impact2 = impacts2?.find((i) => i.indicator_name === index);

        const indicatorExtras = extrasPerImpactName[index as keyof typeof extrasPerImpactName];

        return (
          <div className={tooltipStyles()}>
            <div className="space-y-2 [&_p]:text-white">
              {impact && (
                <div>
                  <Text14 className={`break-all`}>{!!impact2 && `${product.name}: `}</Text14>
                  <Text14>
                    <strong>
                      {formatPrecision(impact.value, 4)} {t(impact.unit)}
                    </strong>
                  </Text14>
                </div>
              )}
              {impact2 && product2 && (
                <div>
                  <Text14 className="break-all text-neutral-500">{product2?.name}: </Text14>
                  <Text14>
                    <strong>
                      {formatPrecision(impact2.value, 4)} {t(impact2.unit)}
                    </strong>
                  </Text14>
                </div>
              )}
            </div>
            <hr className="my-2 -mx-4 px-4" />
            <div className="space-y-2 [&_p]:text-white">
              <Text14>
                <strong>
                  {indicatorExtras.niceName} ({indicatorExtras.indicatorName})
                </strong>
              </Text14>
              <Text14>{indicatorExtras.description}</Text14>
            </div>
          </div>
        );
      }}
      margin={{ top: 40, right: 80, bottom: 40, left: 80 }}
      borderColor={{ from: "color", modifiers: [["darker", 0.1]] }}
      gridLabelOffset={20}
      colors={impacts2 ? [neutral300, co2brown] : [yellow300]}
      gridLabel={({ id, x, y }) => {
        const impact = normalizedImpacts.find((i) => i.indicator_name === id);
        return (
          <foreignObject x={x - 70} y={y - 30} width={140} height={60}>
            <span className="text-xs w-full text-center break-words flex items-center justify-center h-full">
              {impact?.niceName || id}
            </span>
          </foreignObject>
        );
      }}
    />
  );
};
