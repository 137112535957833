import { FileTrigger } from "react-aria-components";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAuthenticatedImgSrc, useUploadImage } from "../../api/endpoints/media";
import { Button } from "../../components/Button";
import { C2 } from "../../components/Typography";
import { EditPlantFields } from "../../pages/EditPlant";
import { showErrorToast } from "../../util/toasts";
import { useValidateFileSize } from "../../util/validateFileSize";
import { EPDPreviewSkeleton } from "./EPDPreviewSkeleton";

export const PlantLogoPicker = () => {
  const { t } = useTranslation();
  const methods = useFormContext<EditPlantFields>();
  const imageUrl = methods.watch("company_image_url");
  const logoSrc = useAuthenticatedImgSrc({
    imageUrl: imageUrl || undefined,
  });

  const uploadImage = useUploadImage();
  const validateFileSize = useValidateFileSize();

  const handleUpload = async (file: File) => {
    try {
      validateFileSize(file);

      const key = await uploadImage({ file });

      methods.setValue("company_image_url", key);
    } catch (_) {
      showErrorToast(t("Couldn't upload product image."));
    }
  };

  return (
    <div className="flex flex-col gap-6">
      <EPDPreviewSkeleton logoSrc={logoSrc} />
      <C2 $muted>
        {t(
          "Pick a logo to display on the header of your generated EPDs. Ideal size is 600px - 600px",
        )}
        <br />
        {t("(PNG / SVG / WEBP)")}
      </C2>
      <div>
        <FileTrigger
          onSelect={(files) => {
            if (!files?.[0]) return;
            handleUpload(files[0]);
          }}
        >
          <Button intent="secondary">{t("Update plant logo")}</Button>
        </FileTrigger>
      </div>
    </div>
  );
};
