import { GridColumnVisibilityModel } from "@mui/x-data-grid-pro";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useLocalStorage from "react-use/lib/useLocalStorage";
import { Elementary, PlantMaterial } from "../../api/types";
import { Checkbox, CheckboxGroupField } from "../../components/CheckboxGroupField";
import { Radio, RadioGroupField } from "../../components/RadioGroupField";
import { SidePanel } from "../../components/SidePanel";
import { useActivePlant } from "../../state/plants";
import { exists } from "../../util/commonUtil";

const LOCAL_STORAGE_OPTIONS_WITH_DATATYPE_SET = {
  raw: false as const,
  serializer: (value: Set<string>) => JSON.stringify(Array.from(value)),
  deserializer: (value: string) => new Set<string>(JSON.parse(value)),
};

/**
 * State management for the filters in the SidePanel:
 * This hook stores all filter state in local storage to persist across page changes.
 */
export const useFilterSidePanel = () => {
  const activePlant = useActivePlant();
  const [panelOpen, setPanelOpen] = useState(false);

  // Filter by material type
  const [_byMaterialType, setByMaterialType] = useLocalStorage<Set<string>>(
    `materials-filter-by-material-type-${activePlant.id}`,
    new Set(),
    LOCAL_STORAGE_OPTIONS_WITH_DATATYPE_SET,
  );
  const byMaterialType = _byMaterialType ?? new Set();

  // Filter by linked data
  const [_byOnlyEpdLinked, setByOnlyEpdLinked] = useLocalStorage<boolean | null>(
    `materials-filter-by-epd-linked-${activePlant.id}`,
    null,
  );
  const byOnlyEpdLinked = _byOnlyEpdLinked ?? null;

  // Filter by hazardous substances
  const [_byOnlyHazardousSubstances, setByOnlyHazardousSubstances] = useLocalStorage<
    boolean | null
  >(`materials-filter-by-hazardous-substances-${activePlant.id}`, null);
  const byOnlyHazardousSubstances = _byOnlyHazardousSubstances ?? null;

  // Filter by supplier
  const [_bySupplier, setBySupplier] = useLocalStorage<Set<string>>(
    `materials-filter-by-supplier-${activePlant.id}`,
    new Set(),
    LOCAL_STORAGE_OPTIONS_WITH_DATATYPE_SET,
  );
  const bySupplier = _bySupplier ?? new Set();

  const hasFilters =
    byMaterialType.size > 0 ||
    byOnlyEpdLinked !== null ||
    bySupplier.size > 0 ||
    byOnlyHazardousSubstances !== null;

  const onClearFilters = useCallback(() => {
    setByMaterialType(new Set());
    setByOnlyEpdLinked(null);
    setByOnlyHazardousSubstances(null);
    setBySupplier(new Set());
    setPanelOpen(false);
  }, [
    setByMaterialType,
    setPanelOpen,
    setByOnlyEpdLinked,
    setByOnlyHazardousSubstances,
    setBySupplier,
  ]);

  return {
    panelOpen,
    setPanelOpen,
    byMaterialType,
    setByMaterialType,
    byOnlyEpdLinked,
    setByOnlyEpdLinked,
    byOnlyHazardousSubstances,
    setByOnlyHazardousSubstances,
    bySupplier,
    setBySupplier,
    hasFilters,
    onClearFilters,
  };
};

export const FiltersSidePanel = ({
  filters,
  materials,
  elementaries,
  columnVisibilityModel,
}: {
  filters: ReturnType<typeof useFilterSidePanel>;
  materials: PlantMaterial[];
  elementaries: Elementary[];
  columnVisibilityModel: GridColumnVisibilityModel;
}) => {
  const { t } = useTranslation();

  const allMaterialCategories = useMemo(() => {
    return [
      ...new Set(
        materials
          .map((material) =>
            material.supplier_product
              ? elementaries.find((c) => c.id === material.supplier_product.elementary_id)?.name
              : material.prechain_product?.product.material,
          )
          .filter(exists),
      ),
    ];
  }, [materials, elementaries]);

  const allSuppliers = useMemo(() => {
    return [
      ...new Set(
        materials
          .map((material) => material.supplier_product?.supplier_plant?.supplier_company.name)
          .filter(exists),
      ),
    ];
  }, [materials]);

  return (
    <SidePanel
      title={t("Filter")}
      open={filters.panelOpen}
      onClose={() => filters.setPanelOpen(false)}
    >
      <div className="flex flex-col gap-4">
        <RadioGroupField
          label={t("Linked data")}
          value={filters.byOnlyEpdLinked?.toString() || null}
          onChange={(value) => filters.setByOnlyEpdLinked(value === "true")}
        >
          <Radio value="true">{t("Has linked data")}</Radio>
          <Radio value="false">{t("Has no linked data")}</Radio>
        </RadioGroupField>

        {columnVisibilityModel.hazardousSubstancesString && (
          <RadioGroupField
            label={t("Hazardous substances")}
            value={filters.byOnlyHazardousSubstances?.toString() || null}
            onChange={(value) => filters.setByOnlyHazardousSubstances(value === "true")}
          >
            <Radio value="true">{t("Has hazardous substances")}</Radio>
            <Radio value="false">{t("Has no hazardous substances")}</Radio>
          </RadioGroupField>
        )}

        <CheckboxGroupField
          label={t("Material")}
          value={[...filters.byMaterialType]}
          onChange={(value) => filters.setByMaterialType(new Set(value))}
        >
          {allMaterialCategories.map((category) => (
            <Checkbox key={category} value={category}>
              {t(category)}
            </Checkbox>
          ))}
        </CheckboxGroupField>
        <CheckboxGroupField
          label={t("Supplier")}
          value={[...filters.bySupplier]}
          onChange={(value) => filters.setBySupplier(new Set(value))}
        >
          {allSuppliers.map((supplier) => (
            <Checkbox key={supplier} value={supplier}>
              {supplier}
            </Checkbox>
          ))}
        </CheckboxGroupField>
      </div>
    </SidePanel>
  );
};
