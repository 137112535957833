import { ResponsiveBar } from "@nivo/bar";
import { useTranslation } from "react-i18next";
import { EndOfScale, Impact, LciaResults } from "../../api/types";
import { tooltipStyles } from "../../components/Tooltip";
import { Label12, Text14 } from "../../components/TypographyOld";
import { coreIndicators, filterImpact, mergeImpact } from "../../lib/impact";
import { exists } from "../../util/commonUtil";
import { formatPrecision } from "../../util/format";
import { useImpactColors } from "./useImpactColors";

const useStageMap = () => {
  const { t } = useTranslation();
  return {
    [t("Production Stage")]: "A1-A3",
    [t("Construction Stage")]: "A4-A5",
    [t("Use Stage")]: "B",
    [t("End of Life Stage")]: "C1-C4",
    [t("Beyond the Life Cycle")]: "D",
  };
};

export const LifeCycleStagesChart = ({
  lcaResults,
  endsOfScale,
}: {
  lcaResults: LciaResults;
  endsOfScale: EndOfScale[];
}) => {
  const colors = useImpactColors();
  const { t } = useTranslation();

  const scaleMap: Record<string, number> = Object.fromEntries(
    endsOfScale.map((scale) => [scale.indicator_name, scale.value]),
  );
  const stageMap = useStageMap();
  const stageFactory = (state: string, impact: Impact[]) =>
    impact.reduce(
      (acc, impact) => ({
        ...acc,
        [impact.indicator_name]: impact.value / scaleMap[impact.indicator_name],
        [impact.indicator_name + "_unit"]: t(impact.unit),
        [impact.indicator_name + "_actual"]: impact.value,
      }),
      {
        stage: state,
      },
    );

  const productionStageImpact = filterImpact(lcaResults.impact_summary.a1_a3, coreIndicators);
  const productionStage = stageFactory(t("Production Stage"), productionStageImpact);

  const stages = [productionStage];

  if (lcaResults.impact_summary.a4 !== null || lcaResults.impact_summary.a5 !== null) {
    const constructionStageImpact = filterImpact(
      mergeImpact([lcaResults.impact_summary.a4, lcaResults.impact_summary.a5].filter(exists)),
      coreIndicators,
    );
    const constructionStage = stageFactory(t("Construction Stage"), constructionStageImpact);
    stages.push(constructionStage);
  }

  const useStageImpactArray = [
    lcaResults.impact_summary.b1,
    lcaResults.impact_summary.b2,
    lcaResults.impact_summary.b3,
    lcaResults.impact_summary.b4,
    lcaResults.impact_summary.b5,
    lcaResults.impact_summary.b6,
    lcaResults.impact_summary.b7,
  ];

  if (useStageImpactArray.some(exists)) {
    const useStageImpact = filterImpact(
      mergeImpact(useStageImpactArray.filter(exists)),
      coreIndicators,
    );
    const useStage = stageFactory(t("Use Stage"), useStageImpact);
    stages.push(useStage);
  }

  if (
    lcaResults.impact_summary.c1 !== null ||
    lcaResults.impact_summary.c2 !== null ||
    lcaResults.impact_summary.c3 !== null ||
    lcaResults.impact_summary.c4 !== null
  ) {
    const eolStageImpact = filterImpact(
      mergeImpact(
        [
          lcaResults.impact_summary.c1,
          lcaResults.impact_summary.c2,
          lcaResults.impact_summary.c3,
          lcaResults.impact_summary.c4,
        ].filter(exists),
      ),
      coreIndicators,
    );
    const eolStage = stageFactory(t("End of Life Stage"), eolStageImpact);
    stages.push(eolStage);
  }

  if (lcaResults.impact_summary.d !== null) {
    const beyondStageImpact = filterImpact(lcaResults.impact_summary.d, coreIndicators);
    const beyondStage = stageFactory(t("Beyond the Life Cycle"), beyondStageImpact);
    stages.push(beyondStage);
  }

  return (
    <ResponsiveBar
      data={stages}
      keys={Array.from(coreIndicators)}
      indexBy="stage"
      margin={{ top: 10, right: 0, bottom: 50, left: 60 }}
      padding={0.2}
      innerPadding={4}
      groupMode="grouped"
      colors={(x) => colors[x.id]}
      axisTop={null}
      axisRight={null}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        tickValues: [-1, -0.5, 0, 0.5, 1],
      }}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        renderTick: ({ value, x, y }) => {
          return (
            <g transform={`translate(${x},${y})`}>
              <foreignObject x="-120" y="10" width="240" height="60">
                <div className="flex flex-col items-center">
                  <Label12>{value}</Label12>
                  <Label12>({stageMap[value as keyof typeof stageMap]})</Label12>
                </div>
              </foreignObject>
            </g>
          );
        },
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      gridYValues={[-1, -0.5, 0, 0.5, 1]}
      minValue={-1}
      maxValue={1}
      barAriaLabel={(e) => e.id + ": " + e.formattedValue + " in country: " + e.indexValue}
      enableLabel={false}
      tooltip={({ id, data }) => {
        const typedData = data as Record<string, string | number>;
        return (
          <div className={tooltipStyles()}>
            <Text14>
              {id}{" "}
              <strong>
                {formatPrecision(Number(typedData[`${id}_actual`]), 4)} {typedData[`${id}_unit`]}
              </strong>
            </Text14>
          </div>
        );
      }}
    />
  );
};
