import { useTranslation } from "react-i18next";
import { EndOfScale, LciaResults } from "../../api/types";
import { Label, Label12 } from "../../components/TypographyOld";
import { LifeCycleStagesChart } from "./LifeCycleStagesChart";

import { ExpandMore } from "@mui/icons-material";
import { useState } from "react";
import { MenuTrigger } from "react-aria-components";
import colors from "tailwindcss/colors";
import { Button } from "../../components/Button";
import { InfoTooltip } from "../../components/InfoTooltip";
import { Menu, MenuItem } from "../../components/Menu";
import { coreIndicators } from "../../lib/impact";
import { LifeCycleA1A3Chart } from "./LifeCycleA1A3Chart";
import { useImpactColors } from "./useImpactColors";
export const yellow300 = colors.yellow[300];
export const yellow200 = colors.yellow[200];
export const blue300 = colors.blue[300];
export const blue200 = colors.blue[200];
export const neutral100 = colors.neutral[100];
export const neutral200 = colors.neutral[200];
export const neutral300 = colors.neutral[300];

export const BreakdownPerStage = ({
  lcaResults,
  endsOfScale,
}: {
  lcaResults: LciaResults;
  endsOfScale: EndOfScale[];
}) => {
  const { t } = useTranslation();
  const [showStages, setShowStages] = useState<"a1_a3" | "all">("a1_a3");
  const stages = Object.values(lcaResults.impact_summary).filter((x) => x !== null);
  if (stages.length < 2) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between gap-2">
        <Label className="flex items-center gap-2">
          {t("Breakdown: Lifecycle Stages")}
          <InfoTooltip>
            <p>{t("The life cycle of a construction product encompasses the following stages:")}</p>
            <ol className="list-decimal pl-8">
              <li>
                <strong>{t("Production")}:</strong>{" "}
                {t(
                  "this stage includes all processes required to transform raw materials into a finished construction product.",
                )}
              </li>
              <li>
                <strong>{t("Installation")}:</strong>{" "}
                {t(
                  "this stage includes all activities related to the installation of the product at the construction site.",
                )}
              </li>
              <li>
                <strong>{t("Use")}:</strong>{" "}
                {t(
                  "this stage covers the operational life of the construction product, during which it performs its intended function.",
                )}
              </li>
              <li>
                <strong>{t("End-of-life")}:</strong>{" "}
                {t(
                  "this stage includes all activities related to the deconstruction, disposal, recycling, or repurposing of the construction product after its useful life.",
                )}
              </li>
              <li>
                <strong>{t("Benefits and loads beyond service life")}:</strong>{" "}
                {t(
                  "this stage considers the benefits and impacts of recycling, reusing, or recovering materials after the product's life cycle has ended.",
                )}
              </li>
            </ol>
          </InfoTooltip>
        </Label>
        <MenuTrigger>
          <Button intent="secondary" size="small">
            {t(showStages === "a1_a3" ? "Show stages A1-A3" : "Show all stages")}
            <ExpandMore />
          </Button>
          <Menu>
            <MenuItem
              size="sm"
              text={t("Show stages A1-A3")}
              onAction={() => setShowStages("a1_a3")}
            />
            <MenuItem size="sm" text={t("Show all stages")} onAction={() => setShowStages("all")} />
          </Menu>
        </MenuTrigger>
      </div>
      <div className="w-full h-[200px]">
        {showStages === "all" ? (
          <LifeCycleStagesChart lcaResults={lcaResults} endsOfScale={endsOfScale} />
        ) : (
          <LifeCycleA1A3Chart lcaResults={lcaResults} endsOfScale={endsOfScale} />
        )}
      </div>
      <CustomLegend />
    </div>
  );
};

const CustomLegend = () => {
  const { t } = useTranslation();
  const colors = useImpactColors();

  return (
    <div className="flex justify-center w-full">
      <div className="flex items-center justify-center">
        {Array.from(coreIndicators).map((indicator) => (
          <div key={indicator} className="flex items-center gap-1 px-3 py-2">
            <div className="w-2 h-2 rounded-full" style={{ backgroundColor: colors[indicator] }} />
            <Label12 className="text-neutral-500">{t(indicator)}</Label12>
          </div>
        ))}
      </div>
    </div>
  );
};
