export const ProgressBar = ({ step, total }: { step: number; total: number }) => {
  return (
    <div className="bg-neutral-100 w-full flex items-center relative h-3">
      <div
        className="bg-builtgreen absolute top-0 left-0 h-full"
        style={{
          width: `${(step * 100) / (total + 1)}%`,
        }}
      />
    </div>
  );
};
